<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="labelClass"
               :style="labelStyle">
            {{ __(label) }}
        </label>
        <div class="w-full"
             :class="className"
             :style="style"
             v-html="__(content)">
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    style: {
        type: [Object, String],
        required: false
    },
    class: {
        type: String,
        required: false
    },
    labelStyle: {
        type: [Object, String],
        required: false
    },
    labelClass: {
        type: String,
        required: false
    },
    content: {
        type: [String, Number],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const colSize = props?.size ? 'col-' + props.size : 'col';
const className = props?.class ? props.class : null;
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss"
       scoped></style>
