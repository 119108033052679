<template>
    <span v-if="log">
        <div v-for="(record, index) in log"
             :key="index"
             class="grid">
            <FormStaticText label="Event"
                            size="2"
                            labelClass="static-text-label"
                            class="static-text-content"
                            :content="record?.event" />

            <FormStaticText label="Operation date"
                            size="2"
                            labelClass="static-text-label"
                            class="static-text-content"
                            :content="record?.created_at" />

            <FormStaticText label="User"
                            size="2"
                            labelClass="static-text-label"
                            class="static-text-content"
                            :content="record?.causer" />

            <FormStaticText label="Changes"
                            size="6"
                            labelClass="static-text-label"
                            class="static-text-content"
                            :content="record?.properties" />

            <FormStaticText label=""
                            size="12"
                            content="<hr>" />
        </div>
        <div v-if="message"
             style="font-size:20px;color:red;">
            {{ message }}
        </div>
    </span>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import TabPanel from 'primevue/tabpanel';
import FormStaticText from '@crud/FormStaticText.vue'
import { __, getSessionValue } from '@helper/utils.js'

const props = defineProps({
    log: Object,
})
const elements = props.log?.length
const maxLogItems = getSessionValue('evinronment.listItemsInLog');
const message = ref(null)

if (maxLogItems == elements) {
    message.value = __('Max showed elements is :elements. Rest of log is accessible in administration menu', { 'elements': maxLogItems });
}
</script>

<style lang="scss"
       scoped></style>
