<template>
    <span>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :renderColumnValue="renderColumnValue"
                :initialSort="initialSort"
                :rowButtonAction="rowButtonAction">
            <template #buttons="formProps">
                <div>
                    <Button @click="actionAddToPricelistButton(formProps)"
                            style="background-color:cadetblue;margin:0 7px 0 25px"
                            severity="info">
                        <i class="fa-solid fa-coins"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Add to pricelist') }}
                    </Button>
                    <Button @click="actionSetDutyCodeButton(formProps)"
                            style="background-color:royalblue;margin:0 7px 0 25px"
                            severity="info">
                        <i class="fa-solid fa-barcode"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Set duty code') }}
                    </Button>
                    <Button @click="actionSetProductGroupButton(formProps)"
                            style="background-color:CornflowerBlue;margin:0 7px 0 25px"
                            severity="info">
                        <i class="fa-solid fa-object-group"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Set product group') }}
                    </Button>
                </div>
            </template>

            <template #crud="formProps">
                <ProductForm :formProps="formProps">
                </ProductForm>
            </template>
            <template #search="searchProps">
                <ProductSearch :searchProps="searchProps">
                </ProductSearch>
            </template>
        </v-view>

        <Dialog modal
                :visible="isPricelistModalOpen"
                v-model:visible="isPricelistModalOpen"
                :header="__('Add to pricelist')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Products will be added to selected pricelist') }}</p>

            <AddToPricelistForm :formProps="pricelistFormProps"
                                v-model:isPricelistModalOpen="isPricelistModalOpen">
            </AddToPricelistForm>
        </Dialog>

        <Dialog modal
                :visible="isDutyCodeModalOpen"
                v-model:visible="isDutyCodeModalOpen"
                :header="__('Set duty code')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Entered duty code will be set for selected products') }}</p>

            <SetDutyCodeForm :formProps="dutyCodeFormProps"
                             v-model:isDutyCodeModalOpen="isDutyCodeModalOpen">
            </SetDutyCodeForm>
        </Dialog>

        <Dialog modal
                :visible="isProductGroupModalOpen"
                v-model:visible="isProductGroupModalOpen"
                :header="__('Set product group')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Entered product group will be set for selected products') }}</p>

            <SetProductGroupForm :formProps="productGroupFormProps"
                                 v-model:isProductGroupModalOpen="isProductGroupModalOpen">
            </SetProductGroupForm>
        </Dialog>

    </span>
</template>

<script setup>
import Button from 'primevue/button'
import ProductForm from './ProductForm.vue'
import Dialog from 'primevue/dialog';
import AddToPricelistForm from './AddToPricelistForm.vue'
import SetDutyCodeForm from './SetDutyCodeForm.vue'
import SetProductGroupForm from './SetProductGroupForm.vue'
import ProductSearch from './ProductSearch.vue'
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
})

const isPricelistModalOpen = ref(false)
const isDutyCodeModalOpen = ref(false)
const isProductGroupModalOpen = ref(false)
const pricelistFormProps = ref({})
const dutyCodeFormProps = ref({})
const productGroupFormProps = ref({})

//------------------------------------------------------------------------------

const pricelistModalControl = {
    open: () => isPricelistModalOpen.value = true,
    close: () => isPricelistModalOpen.value = false,
}

//------------------------------------------------------------------------------

const dutyCodeModalControl = {
    open: () => isDutyCodeModalOpen.value = true,
    close: () => isDutyCodeModalOpen.value = false,
}

//------------------------------------------------------------------------------

const productGroupModalControl = {
    open: () => isProductGroupModalOpen.value = true,
    close: () => isProductGroupModalOpen.value = false,
}

//------------------------------------------------------------------------------

const actionAddToPricelistButton = (formProps) => {
    if (!Array.isArray(formProps.selectedRows) || formProps.selectedRows.length === 0) {
        const message = {
            message: "No product has been selected",
            title: "Product selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    pricelistFormProps.value = formProps
    pricelistModalControl.open()
}

//------------------------------------------------------------------------------

const actionSetDutyCodeButton = (formProps) => {
    if (!Array.isArray(formProps.selectedRows) || formProps.selectedRows.length === 0) {
        const message = {
            message: "No product has been selected",
            title: "Product selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    dutyCodeFormProps.value = formProps
    dutyCodeModalControl.open()
}

//------------------------------------------------------------------------------

const actionSetProductGroupButton = (formProps) => {
    if (!Array.isArray(formProps.selectedRows) || formProps.selectedRows.length === 0) {
        const message = {
            message: "No product has been selected",
            title: "Product selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    productGroupFormProps.value = formProps
    productGroupModalControl.open()
}

//------------------------------------------------------------------------------

const renderColumnValue = (field, value, rowData, column) => {
    if (rowData['product_identity'] !== null && rowData['variant_identity'] === null) {
        if (!value) {
            value = '';
        }
        value = `<span style='font-weight:bold !important'>${value}</span>`
    }

    switch (field) {
        case "product_name":
            let modifiedValue = value;
            if (rowData['product_count'] > 0) {
                modifiedValue = `<span style='color:royalblue;font-weight:bold !important'>${value}</span>`
            }
            return modifiedValue;

        case "length":
            return value + " cm";
        case "width":
            return value + " cm";
        case "height":
            return value + " cm";
        case "real_weight":
            return value + " kg";

        default:
            return value;
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
