import { defineAsyncComponent } from "vue";

export const loadDynamicComponent = async (component) => {
    let dynamicComponent = null
    switch (component) {
        case 'ProductForm':
            dynamicComponent = await defineAsyncComponent(() => import('@pages/App/Database/Product/ProductForm.vue'));
            break;
        case 'PricelistForm':
            dynamicComponent = await defineAsyncComponent(() => import('@pages/App/Database/Pricelist/PricelistForm.vue'));
            break;
        case 'ProductpriceForm':
            dynamicComponent = await defineAsyncComponent(() => import('@pages/App/Sale/Productprice/ProductpriceForm.vue'));
            break;

    }
    return dynamicComponent
}
