<template>
    <template v-if="filterType === 'text'">
        <InputText v-model="filterModel.value"
                   type="text"
                   @keydown.enter="filterCallback()"
                   :pt="{
                    root: {
                        style: { 'height': '34px', 'font-size': '12px', 'color': 'black', ...filterStyle },
                        class: filterClass
                    },
                }" />
    </template>

    <template v-else-if="filterType === 'range'">
        <Calendar v-model="filterModel.value"
                  selectionMode="range"
                  dateFormat="yy-mm-dd"
                  :manualInput="false"
                  @filter="filterCallback()"
                  :pt="{
                    input: {
                        style: { 'font-size': '12px', 'color': 'green', ...filterStyle },
                        class: filterClass
                    }
                }" />
    </template>

    <template v-else-if="filterType === 'date'">
        <Calendar v-model="filterModel.value"
                  selectionMode="single"
                  dateFormat="yy-mm-dd"
                  :manualInput="false"
                  @filter="filterCallback()"
                  :pt="{
                    input: {
                        style: { 'font-size': '12px', 'color': 'green', ...filterStyle },
                        class: filterClass
                    }
                }" />
    </template>

    <template v-else-if="filterType === 'value'">
        <InputNumber v-model="filterModel.value"
                     :format="false"
                     @filter="filterCallback()"
                     :pt="{
                        root: {
                            style: filterStyle,
                            class: filterClass
                        },
                    }" />
    </template>

    <template v-else-if="filterType === 'checkbox'">
        <Checkbox v-model="filterModel.value"
                  binary
                  @filter="filterCallback()"
                  :pt="{
                    root: {
                        style: { 'padding': '0', 'margin': '0', filterStyle },
                        class: filterClass
                    },
                    filterinput: {
                        style: {
                            'padding': '0', 'margin': '0'
                        },
                    }
                }" />
    </template>

    <template v-else-if="filterType === 'autocomplete'">
        <AutoComplete v-model="filterModel.value"
                      @filter="filterCallback()"
                      :suggestions="selectOptions"
                      @complete="searchItems"
                      :virtualScrollerOptions="{ itemSize: 38 }"
                      multiple
                      optionLabel="label"
                      dataKey="value"
                      dropdown
                      dropdownMode="current"
                      forceSelection
                      :delay="200"
                      :emptySelectionMessage="__('No data')"
                      :pt="{
                        emptyMessage: {
                            style: { 'padding-left': '15px', 'padding-top': '5px' }
                        },
                        root: {
                            style: filterStyle,
                            class: filterClass
                        },
                        input: {
                            style: filterStyle,
                            class: filterClass
                        },
                        token: {
                            style: { 'font-size': '12px', ...filterStyle },
                            class: filterClass

                        },
                        list: {
                            style: filterStyle,
                            class: filterClass
                        },
                        item: {
                            style: { 'padding': '3px 5px', 'margin': '0', 'height': 'auto', 'font-size': '12px' },
                        },
                        panel: {
                            style: { 'margin-top': '1px', 'padding-top': '0' },
                            //class: 'filter-autocomplete-panel'
                        },
                        container: {
                            style: { 'align-items': 'flex-start' }
                        },
                    }
                        " />
    </template>

    <template v-else-if="filterType === 'dropdown'">
        <Dropdown v-model="filterModel.value"
                  @filter="filterCallback()"
                  :options="selectOptions"
                  :virtualScrollerOptions="{ itemSize: 38 }"
                  multiple
                  optionLabel="label"
                  optionValue="value"
                  style="height:36px"
                  :style="filterStyle"
                  :class="filterClass"
                  :emptySelectionMessage="__('No data')"
                  :pt="{
                    emptyMessage: {
                        style: { 'padding-left': '15px', 'padding-top': '15px' },
                    },
                    input: {
                        style: { ...filterStyle, 'padding-top': '1px' },
                        class: filterClass
                    },
                    filterInput: {
                        style: filterStyle,
                        class: filterClass
                    },
                    list: {
                        style: filterStyle,
                        class: filterClass
                    },
                    item: {
                        style: { 'padding': '5px 5px', 'margin': '0', 'height': 'auto', 'font-size': '12px' },
                    },
                }
                    " />
    </template>

</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import Dropdown from 'primevue/dropdown'
import { __ } from '@helper/utils.js'

const props = defineProps({
    filterModel: Object,
    filterCallback: Function,
    filterOptions: String,
    filterProps: Object,
    filterStyle: Object,
    filterClass: String,
    filterType: {
        type: String,
        default: 'text',
    }
})

const selectOptions = ref([{}])
const searchItems = async (event) => {
    await axios.post(route("helper-select", { select: [props.filterOptions], filter: event?.query, props: props.filterProps }))
        .then((response) => {
            selectOptions.value = response.data[props.filterOptions];
        });
}

onMounted(() => {
    if (props.filterType === 'dropdown') {
        searchItems({ query: '' })
    }
})

</script>

<style>
.p-column-filter .p-column-filter-menu-button.p-link {
    margin-left: 2px !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 20px;
    color: green !important;
}

.p-column-filter .p-column-filter-menu-button.p-column-filter-menu-button-active {
    margin-left: 2px !important;
    border-radius: 5px !important;
    background-color: firebrick;
    color: white !important;
}

.p-column-filter .p-column-filter-clear-button.p-link {
    padding: 0 !important;
    margin: 0 !important;
    width: 20px;
    color: #FEC107 !important;
}

.p-column-filter .p-column-filter-element .p-inputnumber .p-inputtext {
    font-size: 12px;
}
</style>
