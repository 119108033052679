<template>

    <Head :title="__('Password expired')" />
    <div class="auth-container">
        <v-form name="formExpired"
                class="auth-form"
                @keyup.enter="update">
            <div class="auth-top">
                <v-col class="auth-logo">
                    <Image :src="systemLogo" />
                </v-col>
            </div>

            <div class="auth-main">
                <v-col class="auth-title">
                    {{ __('Password expired. Please change password') }}
                </v-col>

                <v-col class="auth-section">
                    <ul v-if="props.rules"
                        class="password-rules">
                        <li v-for="(rule, index) in props.rules"
                            :key="index">
                            {{ rule }}
                        </li>
                    </ul>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="password">{{ __('Current password') }}</label>
                    <inputText type="password"
                               id="current_password"
                               size="small"
                               v-model="form.current_password"
                               :class="{ 'invalid-auth-input': form.errors.current_password }"
                               @input="form.errors.current_password = null" />
                    <div v-if="form.errors.current_password"
                         class="invalid-auth-form">{{ __(form.errors.current_password) }}</div>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="password">{{ __('New password') }}</label>
                    <inputText type="password"
                               id="password"
                               size="small"
                               v-model="form.password" />
                    <div v-if="form.errors.password"
                         class="invalid-auth-form">{{ __(form.errors.password) }}</div>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="password">{{ __('Confirm new password') }}</label>
                    <inputText type="password"
                               id="password_confirmation"
                               size="small"
                               v-model="form.password_confirmation" />
                </v-col>

                <v-col>
                    <v-row class="justify-content-between">
                        <Button @click="update"
                                class="auth-button"
                                style="width:50%"
                                :label="__('Set new password')"
                                icon="pi pi-sign-in" />

                    </v-row>
                </v-col>
                <input type="hidden"
                       id="token"
                       name="token"
                       v-model="form.token" />
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'
import { Link } from '@inertiajs/vue3'

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import Layout from '@layouts/LayoutNoMenuNoFooter.vue'
import Image from '@helper/Image.vue'
import { __ } from '@helper/utils.js'
import { systemLogo, startSplash } from '@helper/constants.js'

defineOptions({ layout: Layout })

const props = defineProps({
    user_id: String,
    email: String,
    rules: Object,
})
// -----------------------------------------------------

const form = useForm({ current_password: '', password: '', password_confirmation: '' })

// -----------------------------------------------------

const update = () => {
    form.post(route('password-expired-update', { 'user_id': props.user_id, 'email': props.email }))
}

// -----------------------------------------------------
</script>

<style lang="scss"
       scoped></style>
