<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
        <FileUploader :category="category"
                      :disabled="isFormDisabled"
                      :disallowedExtensions="disallowedExtensions"
                      :allowedExtensions="allowedExtensions"
                      :maxFiles="maxFilesAsNumber"
                      v-model:files="field" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import FileUploader from '../Crud/FileUploader.vue'
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    category: {
        type: String,
        default: null
    },
    disallowedExtensions: {
        type: Array,
        default: []
    },
    allowedExtensions: {
        type: Array,
        default: []
    },
    maxFiles: {
        type: [Number, String],
        default: null
    },
    labelClass: {
        type: String,
        default: null,
    },
    labelStyle: {
        type: String,
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';
const maxFilesAsNumber = Number(props.maxFiles);
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss"
       scoped></style>
