<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="name"
                                   label="Permission name"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.name" />
                </div>
                <div class="grid">
                    <FormTextarea fieldName="description"
                                  label="Description"
                                  row="3"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.description" />
                </div>
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormTextarea from '@crud/FormTextarea.vue'
import FormButtons from '@crud/FormButtons.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(true)
const crud = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, null, null)
})

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
