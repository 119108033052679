<template>
    <div class="grid w-full justify-content-evenly align-items-center gap-4"
         style="height:92vh">
        <div v-for="(module, symbol) in  modules "
             :key="symbol">
            <Card style="width: 25rem; overflow: hidden">
                <template #header>
                    <Image :src="getBgTexture()" />
                </template>
                <template #title>{{ module.title }}</template>
                <template #subtitle>{{ module.subtitle }}</template>
                <template #content>
                    <p class="m-0">
                        {{ module.description }} </p>
                </template>
                <template #footer>
                    <span class="select-module-button flex justify-content-center">
                        <Link v-ripple
                              :href="module.route ? checkRoute(module.route, null, __('Selected module not exists')) : checkRoute('set-module', symbol, __('Selected module not exists'))"
                              :class="[symbol == currentModule ? 'current-module-button' : '', 'href-text w-full p-2']">
                        <span>
                            {{ __('Select') }}
                        </span>
                        </Link>
                    </span>
                </template>
                <div>

                </div>
            </Card>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import { Link } from '@inertiajs/vue3'
import { checkRoute, __ } from '@helper/utils.js'
import Layout from '@layouts/LayoutNoMenuWithFooter.vue'
import Card from 'primevue/card'
import Image from '@helper/Image.vue'
import { bgTextureName, bgTextureExt } from '@helper/constants.js'
import axios from 'axios'

defineOptions({ layout: Layout })

const props = defineProps({
    currentModule: String,
    warning: { type: Boolean, default: false },
})
const modules = ref([])

//------------------------------------------------------------------

onBeforeMount(async () => {
    if (props.warning) {
        console.warn('Brak uprawnień do modułu lub wybrany moduł nie istnieje!')
    }
    try {
        const response = await axios.get(route('user-modules'))
        modules.value = await response.data
    } catch (error) {
        console.error(error)
    }
})

//------------------------------------------------------------------

const getBgTexture = () => {
    let randomNumber = Math.floor(Math.random() * 10) + 1;
    let pictureNumber = String(randomNumber).padStart(2, '0');
    return `${bgTextureName}${pictureNumber}.${bgTextureExt}`
}

</script>

<style scoped>
.outer-box {
    width: 100%;
    height: calc(100vh - 127px);
}

.main-page-box {
    width: 850px;
    height: 350px;
    font-size: 36px;
    background-color: rgba(255, 255, 255, 1);
    margin: 15px;
    padding: 10px 15px;
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 29px 52px 0px rgba(0, 0, 0, 0.4),
        0px 25px 16px 0px rgba(0, 0, 0, 0.2);
}

.page-name {
    font-size: 36px;
    color: #154A9A
}
</style>
