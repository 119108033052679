<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="carton_size"
                                   label="Carton size"
                                   size="4"
                                   :isFocus="true"
                                   v-model:field="search.carton_size" />

                    <FormMultiselect fieldName="wave_id"
                                     label="Wave type"
                                     size="4"
                                     showClear
                                     :options="select?.waves"
                                     v-model:field="search.wave_id" />

                    <FormInputText fieldName="symbol"
                                   label="Symbol"
                                   size="4"
                                   v-model:field="search.symbol" />

                    <FormInputText fieldName="type"
                                   label="Kind"
                                   size="6"
                                   v-model:field="search.type" />

                    <FormInputNumber fieldName="value_from"
                                     label="Value from"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.value_from" />

                    <FormInputNumber fieldName="value_to"
                                     label="Value to"
                                     size="3"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.value_to" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ["waves"];
const select = ref(null);

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
