<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="pricelist_name"
                                   label="Pricelist name"
                                   validation="required"
                                   size="4"
                                   :isFocus="true"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.pricelist_name" />

                    <FormDropdown fieldName="country_id"
                                  label="Country"
                                  size="4"
                                  showClear
                                  validation="required"
                                  :change="onCountryChange"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.countries"
                                  :filter="(event) => onFilter(event, 'countries')"
                                  v-model:field="crud.country_id" />

                    <FormDropdown fieldName="sellchannel_id"
                                  label="Sell channel"
                                  size="4"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.sellchannels"
                                  :filter="(event) => onFilter(event, 'sellchannels')"
                                  v-model:field="crud.sellchannel_id" />

                    <FormInputNumber fieldName="fixed_charge"
                                     label="Fixed charge"
                                     size="3"
                                     :suffix="currencySymbol"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.fixed_charge" />

                    <FormInputNumber fieldName="baselinker_pricegroup_id"
                                     label="Baselinker price group ID"
                                     size="3"
                                     :useGrouping="false"
                                     :allowEmpty="true"
                                     :default="null"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.baselinker_pricegroup_id" />
                </div>
                <div v-if="crud.country_id"
                     class="grid">
                    <FormStaticText label="Accounting procedure"
                                    size="3"
                                    :style="{ 'font-weight': 'bold', color: 'blue' }"
                                    :labelStyle="{ 'font-weight': 'bold' }"
                                    :content="accountingProcedure" />

                    <FormStaticText label="VAT rates"
                                    size="3"
                                    :style="{ 'font-weight': 'bold', color: 'blue' }"
                                    :labelStyle="{ 'font-weight': 'bold' }"
                                    :content="vatRate" />

                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />
                </div>
            </TabPanel>

            <TabPanel v-if="!tabsDeny('changelog')"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormStaticText from '@crud/FormStaticText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, getFromRoute } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)

const options = ['countries', 'sellchannels']
const select = ref(null)
const crud = ref({})

const accountingProcedure = ref('')
const vatRate = ref('')

const currencySymbol = ref('')

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)
    onCountryChange({ value: crud.value.country_id })
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, params)
}

// -----------------------------------------------------

const onCountryChange = async (event) => {
    const countryData = await getFromRoute('country-data', { 'country_id': crud.value.country_id });
    accountingProcedure.value = countryData.accounting_procedure
    vatRate.value = countryData.vat_rates
    currencySymbol.value = " " + countryData.currency_symbol
}

// -----------------------------------------------------

const tabsDeny = (tabName) => {
    return props.formProps?.tabsDeny?.length == 0 || props.formProps?.tabsDeny?.includes(tabName)
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
