<template>
    <div>
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form :name="props.formProps.formName">
            <TabView scrollable
                     style="overflow: visible"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormDropdown fieldName="vendor_id"
                                      label="Vendor"
                                      size="3"
                                      showClear
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.vendors"
                                      :filter="(event) => onFilter(event, 'vendors')"
                                      :change="() => getProducts()"
                                      v-model:field="crud.vendor_id" />

                        <FormDropdown fieldName="product_id"
                                      label="Product"
                                      size="5"
                                      showClear
                                      validation="required"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.products"
                                      :filter="(event) => onFilter(event, 'products')"
                                      v-model:field="crud.product_id" />
                        <FormDropdown fieldName="pricelist_id"
                                      label="Sell channel (pricelist)"
                                      size="4"
                                      showClear
                                      validation="required"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.pricelists"
                                      :filter="(event) => onFilter(event, 'pricelists')"
                                      :change="() => getVatRatesAndMarketplaces()"
                                      v-model:field="crud.pricelist_id" />
                    </div>
                    <div class="grid">
                        <FormDropdown fieldName="vat_rate"
                                      label="VAT rate"
                                      size="2"
                                      showClear
                                      validation="required"
                                      :filtering="false"
                                      :isFormDisabled="isFormDisabled"
                                      :options="vat_rates"
                                      v-model:field="crud.vat_rate" />

                        <FormDropdown fieldName="marketplace_id"
                                      label="Marketplace provision"
                                      size="3"
                                      showClear
                                      :wrapperStyle="{ 'width': '800px' }"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.marketplaces"
                                      :filter="(event) => onFilter(event, 'marketplaces')"
                                      v-model:field="crud.marketplace_id" />

                        <FormDropdown fieldName="courier_id"
                                      label="Courier"
                                      size="3"
                                      showClear
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.couriers"
                                      :filter="(event) => onFilter(event, 'couriers')"
                                      :change="() => getParcels()"
                                      v-model:field="crud.courier_id" />

                        <FormDropdown fieldName="parcel_id"
                                      label="Courier nomenclature"
                                      size="4"
                                      showClear
                                      :filtering="false"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.parcels"
                                      v-model:field="crud.parcel_id" />
                    </div>
                    <div class="grid">

                        <FormRadioButton fieldName="charge_source"
                                         label="Charge source"
                                         size="4"
                                         default="P"
                                         :isFormDisabled="isFormDisabled"
                                         :data="select?.charge_sources"
                                         @change="switchManualChargeVisibility"
                                         v-model:field="crud.charge_source" />

                        <FormInputNumber v-if="isManualChargeVisible"
                                         fieldName="manual_charge"
                                         label="Manual charge"
                                         suffix=" %"
                                         size="4"
                                         :isFormDisabled="isFormDisabled"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="crud.manual_charge" />
                        <div v-else
                             class="col-4"></div>

                        <FormInputNumber fieldName="marketing_cost"
                                         label="Marketing cost"
                                         size="4"
                                         suffix=" %"
                                         :isFormDisabled="isFormDisabled"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="crud.marketing_cost" />
                    </div>
                    <div style="margin:10px 0 20px">
                        <hr>
                    </div>
                    <div class="grid">

                        <FormInputNumber fieldName="net_buy_price"
                                         label="Net buy price"
                                         size="4"
                                         suffix=" PLN"
                                         labelClass="productprice-prices-label"
                                         inputClass="productprice-prices-input"
                                         :inputStyle="{ color: 'red' }"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="plnUnitPrice" />

                        <FormInputNumber fieldName="gross_sell_price_curr"
                                         label="Gross sell in currency"
                                         size="4"
                                         labelClass="productprice-prices-label"
                                         inputClass="productprice-prices-input"
                                         :inputStyle="{ color: 'yellow', backgroundColor: 'cornflowerblue' }"
                                         :suffix="currencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="grossSellPriceCurr" />

                        <FormInputNumber fieldName="net_charge_value"
                                         label="Net charge value"
                                         size="4"
                                         labelClass="productprice-prices-label"
                                         inputClass="productprice-prices-input"
                                         :inputStyle="{ color: 'yellow', backgroundColor: 'cornflowerblue' }"
                                         suffix=" PLN"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="netChargeValue" />
                    </div>
                    <div class="grid">
                        <FormInputNumber fieldName="net_sell_price_curr"
                                         label="Net sell in currency"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="currencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="netSellPriceCurr" />

                        <FormInputNumber fieldName="charge_percent"
                                         label="Charge overhead percent"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix=" %"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="chargePercent" />

                        <FormInputNumber fieldName="margin_profit_percent"
                                         label="Net margin profit percent"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix=" %"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="marginProfitPercent" />

                        <FormInputNumber fieldName="marketplace_provision_percent"
                                         label="Marketplace provision percent"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix=" %"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="marketplaceProvisionPercent" />
                        <!--
                    <FormInputNumber fieldName="net_marketplace_provision_value_curr"
                                     label="Net Marketplace provision value"
                                     size="2"
                                     labelClass="productprice-charges-label"
                                     inputClass="productprice-charges-input"
                                     :suffix="currencySymbol"
                                     :isFormDisabled="true"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="netMarketplaceProvisionValueCurr" />
-->
                        <FormInputNumber fieldName="gross_marketplace_provision_value_curr"
                                         label="Gross Marketplace provision value"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="currencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="grossMarketplaceProvisionValueCurr" />

                        <FormInputNumber fieldName="marketing_cost_percent"
                                         label="Marketing cost percent"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix="%"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="marketingCostPercent" />

                        <FormInputNumber fieldName="net_marketing_cost_value_curr"
                                         label="Net marketing cost value"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="currencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="netMarketingCostValueCurr" />

                        <FormInputNumber fieldName="gross_marketing_cost_value_curr"
                                         label="Gross marketing cost value"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="currencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="grossMarketingCostValueCurr" />

                        <FormInputNumber fieldName="carton_cost"
                                         label="Carton cost"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix=" PLN"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="cartonCost" />

                        <FormInputNumber fieldName="courier_net_logistic_charge"
                                         label="Net logistic charge"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="parcelCurrencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="courierNetLogisticCharge" />

                        <FormInputNumber fieldName="courier_net_total_curr"
                                         label="Net delivery cost"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="parcelCurrencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="courierNetTotalCurr" />

                        <FormInputNumber fieldName="courier_gross_total_curr"
                                         label="Gross delivery cost"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         :suffix="parcelCurrencySymbol"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="courierGrossTotalCurr" />

                        <FormInputNumber fieldName="import_cost"
                                         label="Net import costs"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix=" PLN"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="importCost" />

                        <FormInputNumber fieldName="additional_cost"
                                         label="Net additional costs"
                                         size="2"
                                         labelClass="productprice-charges-label"
                                         inputClass="productprice-charges-input"
                                         suffix=" PLN"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="additionalCost" />
                    </div>
                    <div class="grid">
                        <FormInputText fieldName="ean_code"
                                       label="EAN code"
                                       size="2"
                                       labelClass="productprice-materials-label"
                                       inputClass="productprice-materials-input"
                                       :isFormDisabled="true"
                                       v-model:field="eanCode" />

                        <FormInputText fieldName="additional_ean_code"
                                       label="EAN additional code"
                                       size="2"
                                       labelClass="productprice-materials-label"
                                       inputClass="productprice-materials-input"
                                       :isFormDisabled="true"
                                       v-model:field="eanAdditionalCode" />

                        <FormDropdown fieldName="dutycode_id"
                                      label="Duty code"
                                      size="2"
                                      showClear
                                      :isFormDisabled="true"
                                      :options="select?.dutycodes"
                                      v-model:field="dutycode_id" />

                        <FormInputNumber fieldName="productHeight"
                                         label="Height"
                                         size="2"
                                         labelClass="productprice-materials-label"
                                         inputClass="productprice-materials-input"
                                         suffix=" cm"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="productHeight" />

                        <FormInputNumber fieldName="productWidth"
                                         label="Width"
                                         size="2"
                                         labelClass="productprice-materials-label"
                                         inputClass="productprice-materials-input"
                                         suffix=" cm"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="productWidth" />

                        <FormInputNumber fieldName="productLength"
                                         label="Length"
                                         size="2"
                                         labelClass="productprice-materials-label"
                                         inputClass="productprice-materials-input"
                                         suffix=" cm"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="productLength" />

                        <FormInputNumber fieldName="literCapacity"
                                         label="Liter capacity"
                                         size="2"
                                         labelClass="productprice-materials-label"
                                         inputClass="productprice-materials-input"
                                         suffix=" l"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="literCapacity" />

                        <FormInputNumber fieldName="volumetricWeight"
                                         label="Volumetric weight"
                                         size="2"
                                         labelClass="productprice-materials-label"
                                         inputClass="productprice-materials-input"
                                         suffix=" kg"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="volumetricWeight" />

                        <FormInputNumber fieldName="realWeight"
                                         label="Real weight"
                                         size="2"
                                         labelClass="productprice-materials-label"
                                         inputClass="productprice-materials-input"
                                         suffix=" kg"
                                         :isFormDisabled="true"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         v-model:field="realWeight" />

                        <FormInputText fieldName="last_update_local_price"
                                       label="Last update local price"
                                       size="2"
                                       labelClass="productprice-materials-label"
                                       inputClass="productprice-materials-input"
                                       :isFormDisabled="true"
                                       v-model:field="crud.last_update_local_price" />

                        <FormInputText fieldName="last_update_baselinker_price"
                                       label="Last update Baselinker price"
                                       size="2"
                                       labelClass="productprice-materials-label"
                                       inputClass="productprice-materials-input"
                                       :isFormDisabled="true"
                                       v-model:field="crud.last_update_baselinker_price" />

                        <FormInputToggleButton fieldName="isCustom"
                                               label="Is custom?"
                                               size="2"
                                               labelClass="productprice-materials-label"
                                               inputClass="productprice-materials-input"
                                               :isFormDisabled="true"
                                               v-model:field="isCustom" />

                    </div>
                    <div class="grid">
                    </div>
                    <div class="grid">
                        <FormEditor fieldName="description"
                                    label="Description"
                                    :editorStyle="{ height: '120px' }"
                                    :isFormDisabled="isFormDisabled"
                                    v-model:field="crud.description" />
                    </div>
                </TabPanel>

                <TabPanel v-if="!tabsDeny('changelog')"
                          :header="__('Changelog')"
                          headerClass="form-tab-header">
                    <ChangeLog :log="crud?.log" />
                </TabPanel>
            </TabView>

            <div class="grid">
                <FormButtons :formProps="props.formProps"
                             :sourceForm="crud"
                             :validation="validation"
                             v-model:isFormDisabled="isFormDisabled">
                </FormButtons>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, onFilterService, onChangeService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormRadioButton from '@crud/FormRadioButton.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormInputToggleButton from '@crud/FormInputToggleButton.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, getRecord, getFromRoute } from '@helper/utils.js'
import axios from 'axios'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)

const options = ['pricelists', 'products', 'charge_sources', 'currencies', 'couriers', 'parcels', 'marketplaces', 'vendors', 'dutycodes']
const select = ref(null)
const crud = ref({})

const eanCode = ref(null)
const eanAdditionalCode = ref(null)
const dutycode_id = ref(null)
const plnUnitPrice = ref(null)
const netChargeValue = ref(null)
const chargePercent = ref(null)
const marketplaceProvisionPercent = ref(null)
const isManualChargeVisible = ref(false)
const currencySymbol = ref('')
const parcelCurrencySymbol = ref('')
const marginProfitPercent = ref(null)
const grossSellPriceCurr = ref(null) //gross_sell_price_curr

const cartonCost = ref(null)
const productWidth = ref(null)
const productHeight = ref(null)
const productLength = ref(null)
const volumetricWeight = ref(null)
const realWeight = ref(null)
const literCapacity = ref(null)
const isCustom = ref(false)
const courierNetTotalCurr = ref(null)
const vat_rates = ref([])

const courierNetLogisticCharge = ref(null)
const courierGrossTotalCurr = ref(null)
const netMarketplaceProvisionValueCurr = ref(null)
const grossMarketplaceProvisionValueCurr = ref(null)
const marketingCostPercent = ref(null)
const netMarketingCostValueCurr = ref(null)
const grossMarketingCostValueCurr = ref(null)
const netSellPriceCurr = ref(null)
const importCost = ref(null)
const additionalCost = ref(null)

// -----------------------------------------------------

const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

// -----------------------------------------------------

watch(() => ({
    id: crud.value.id, product_id: crud.value.product_id, vendor_id: crud.value.vendor_id, pricelist_id: crud.value.pricelist_id, pricelist_country_id: crud.value.pricelist_country_id, courier_id: crud.value.courier_id, parcel_id: crud.value.parcel_id, marketplace_id: crud.value.marketplace_id,
    charge_source: crud.value.charge_source, manual_charge: crud.value.manual_charge, marketing_cost: crud.value.marketing_cost, vat_rate: crud.value.vat_rate
}),
    debounce(async (newValue, oldValue) => {
        const promises = [];
        if (newValue.product_id !== oldValue.product_id || newValue.vendor_id !== oldValue.vendor_id || newValue.pricelist_id !== oldValue.pricelist_id || newValue.pricelist_country_id !== oldValue.pricelist_country_id || newValue.courier_id !== oldValue.courier_id || newValue.parcel_id !== oldValue.parcel_id || newValue.marketplace_id !== oldValue.marketplace_id ||
            newValue.charge_source !== oldValue.charge_source || newValue.manual_charge !== oldValue.manual_charge || newValue.marketing_cost !== oldValue.marketing_cost || newValue.vat_rate !== oldValue.vat_rate) {

            promises.push(getRecord('products', ['ean_code', 'ean_additional_code', 'dutycode_id', 'net_buy_price', 'length', 'height', 'width', 'is_custom', 'vendor_id'], `id=${newValue?.product_id ?? null}`));
            promises.push(axios.post(route('get-prices'), {
                productprice_id: newValue.id,
                product_id: newValue.product_id,
                pricelist_id: newValue.pricelist_id,
                courier_id: newValue.courier_id,
                parcel_id: newValue.parcel_id,
                marketplace_id: newValue.marketplace_id,
                charge_source: newValue.charge_source,
                manual_charge: newValue.manual_charge,
                marketing_cost: newValue.marketing_cost,
                vat_rate: newValue.vat_rate
            }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                }
            }))

            const response = await Promise.all(promises);
            const [product, pricesResponse] = response;
            const prices = await pricesResponse.data;

            if (product) {
                eanCode.value = product.ean_code;
                eanAdditionalCode.value = product.ean_additional_code;
                dutycode_id.value = product.dutycode_id;
                plnUnitPrice.value = product.net_buy_price;
                crud.value.vendor_id = product.vendor_id
            }

            if (prices) {
                currencySymbol.value = prices?.currency_symbol ? " " + prices.currency_symbol : '';
                parcelCurrencySymbol.value = prices?.parcel_currency_symbol ? " " + prices.parcel_currency_symbol : '';
                marginProfitPercent.value = prices?.margin_profit_percent;
                netSellPriceCurr.value = prices.net_sell_price_curr
                grossSellPriceCurr.value = prices?.gross_sell_price_curr;

                netChargeValue.value = prices?.net_charge_value;
                marketplaceProvisionPercent.value = prices?.marketplace_provision_percent;
                netMarketplaceProvisionValueCurr.value = prices.net_marketplace_provision_value_curr
                grossMarketplaceProvisionValueCurr.value = prices.gross_marketplace_provision_value_curr

                marketingCostPercent.value = prices.marketing_cost_percent
                netMarketingCostValueCurr.value = prices.net_marketing_cost_value_curr
                grossMarketingCostValueCurr.value = prices.gross_marketing_cost_value_curr

                chargePercent.value = prices.charge_percent
                cartonCost.value = prices.carton_cost
                productWidth.value = prices.product_width
                productHeight.value = prices.product_height
                productLength.value = prices.product_length
                volumetricWeight.value = prices.volumetric_weight
                realWeight.value = prices.real_weight
                literCapacity.value = prices.liter_capacity
                isCustom.value = prices.product_is_custom ? true : false

                courierNetLogisticCharge.value = prices.courier_net_logistic_charge_curr
                courierNetTotalCurr.value = prices.courier_net_total_curr
                courierGrossTotalCurr.value = prices.courier_gross_total_curr

                importCost.value = prices.import_costs
                additionalCost.value = prices.additional_costs
                crud.value.pricelist_country_id = prices.pricelist_country_id
            }
        }
    }, 500), { deep: true })

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)
    await getVatRatesAndMarketplaces(crud.value.vat_rate)
    if (crud.value.charge_source === 'M') {
        isManualChargeVisible.value = true
    }
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null)
}

// -----------------------------------------------------

const getParcels = async () => {
    let dependences = [
        {
            select: { 'parcels': 'parcel_id' },
            conditions: 'parcels.courier_id=#courier_id# and parcels.country_id=#pricelist_country_id#',
            variables: { courier_id: crud.value.courier_id, pricelist_country_id: crud.value.pricelist_country_id }
        },
    ]

    await onChangeService(dependences, select, crud, isLoading, null, true)
}

// -----------------------------------------------------

const tabsDeny = (tabName) => {
    return props.formProps?.tabsDeny?.length == 0 || props.formProps?.tabsDeny?.includes(tabName)
}

// -----------------------------------------------------

const getProducts = async () => {
    let dependences = [
        {
            select: { 'products': 'product_id' },
            conditions: 'products.vendor_id=#vendor_id#',
            variables: { vendor_id: crud.value.vendor_id }
        },
    ]

    await onChangeService(dependences, select, crud, isLoading, null, true)
}

// -----------------------------------------------------

const getVatRatesAndMarketplaces = async (currentVatRates = null) => {
    let vatRates = []
    let marketplaces = []
    let parcels = []

    const data = await getFromRoute('pricelist-vat-rates-marketplaces', { 'pricelist_id': crud?.value?.pricelist_id, 'courier_id': crud?.value?.courier_id, 'in_plain_array': true });

    //const country = await getRecord('pricelists', 'country_id', `id=${crud.value.pricelist_id}`);
    //const countryData = await getFromRoute('country-data', { 'country_id': country?.country_id ?? null, 'in_plain_array': true });
    //const marketplaceData = await getFromRoute('pricelist-marketplaces', { 'pricelist_id': crud?.value?.pricelist_id ?? null });
    //const parcelData = await getFromRoute('pricelist-parcels', { 'pricelist_id': crud?.value?.pricelist_id ?? null, courier_id: crud?.value?.courier_id ?? null });
    const { countryData, marketplaceData, parcelData } = data

    countryData.vat_rates.forEach(rate => {
        vatRates.push({ label: rate + '%', value: rate })
    })

    vat_rates.value = vatRates
    if (!currentVatRates) {
        crud.value.vat_rate = vatRates[0]?.value ?? null
    }

    marketplaceData?.marketplaces_data?.forEach(element => {
        marketplaces.push({ label: element.label + '%', value: element.value })
    })
    parcelData?.parcels_data?.forEach(element => {
        parcels.push({ label: element.label, value: element.value })
    })

    select.value.marketplaces = marketplaces
    select.value.parcels = parcels
}

// -----------------------------------------------------

const getVatRatesAndMarketplaces2 = async (currentVatRates = null) => {
    let vatRates = []
    let marketplaces = []
    let parcels = []

    const country = await getRecord('pricelists', 'country_id', `id=${crud.value.pricelist_id}`);
    const countryData = await getFromRoute('country-data', { 'country_id': country?.country_id ?? null, 'in_plain_array': true });
    const marketplaceData = await getFromRoute('pricelist-marketplaces', { 'pricelist_id': crud?.value?.pricelist_id ?? null });
    const parcelData = await getFromRoute('pricelist-parcels', { 'pricelist_id': crud?.value?.pricelist_id ?? null, courier_id: crud?.value?.courier_id ?? null });

    countryData.vat_rates.forEach(rate => {
        vatRates.push({ label: rate + '%', value: rate })
    })

    vat_rates.value = vatRates
    if (!currentVatRates) {
        crud.value.vat_rate = vatRates[0]?.value ?? null
    }

    marketplaceData?.marketplaces_data?.forEach(element => {
        marketplaces.push({ label: element.label + '%', value: element.value })
    })
    parcelData?.parcels_data?.forEach(element => {
        parcels.push({ label: element.label, value: element.value })
    })

    select.value.marketplaces = marketplaces
    select.value.parcels = parcels
}

// -----------------------------------------------------

const switchManualChargeVisibility = (event) => {
    isManualChargeVisible.value = event.target.value === 'M'
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
