<template>
    <v-form>
        <div class="grid">
            <FormInputNumber fieldName="marketing_cost"
                             label="Marketing cost"
                             suffix=" %"
                             :minFractionDigits="2"
                             :maxFractionDigits="2"
                             v-model:field="marketing_cost" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="setMarketingCost">
                <i class="fa-solid fa-hand-holding-dollar"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Set marketing costs') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormInputNumber from '@crud/FormInputNumber.vue'
import { prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isSetMarketingCostModalOpen = defineModel('isSetMarketingCostModalOpen')

// -----------------------------------------------------

const marketing_cost = ref(0)
let select = ref(null)

// -----------------------------------------------------

const cancel = () => {
    isSetMarketingCostModalOpen.value = false
}
// -----------------------------------------------------

const setMarketingCost = () => {
    const formData = {
        'marketing_cost': marketing_cost.value, selectedRows: props.formProps.selectedRows.map(row => {
            return { 'id': row.id }
        })
    }
    useForm(formData).post(route('set-marketing-costs'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            const message = {
                message: "Marketing cost of selected products was set",
                title: "Information",
                type: "info",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
        },
        onFinish: () => {
            props.formProps.clearSelection()
            props.formProps.getLazyData()
        }
    })

    isSetMarketingCostModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
