<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormMultiselect fieldName="pricelist_id"
                                     label="Pricelist"
                                     size="6"
                                     showClear
                                     :options="select?.pricelists"
                                     :filter="(event) => onFilter(event, 'pricelists')"
                                     v-model:field="search.pricelist_id" />

                    <FormMultiselect fieldName="product_id"
                                     label="Product"
                                     size="6"
                                     showClear
                                     :options="select?.products"
                                     :filter="(event) => onFilter(event, 'products')"
                                     v-model:field="search.product_id" />

                    <FormMultiselect fieldName="vendor_id"
                                     label="Vendor"
                                     size="6"
                                     showClear
                                     :options="select?.vendors"
                                     :filter="(event) => onFilter(event, 'vendors')"
                                     v-model:field="search.vendor_id" />
                    <FormInputText fieldName="product_identity"
                                   label="Product ID"
                                   size="3"
                                   v-model:field="search.product_identity" />

                    <FormInputText fieldName="variant_identity"
                                   label="Variant ID"
                                   size="3"
                                   v-model:field="search.variant_identity" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormMultiselect from '@crud/FormMultiselect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ['pricelists', 'products', 'vendors']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
