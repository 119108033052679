<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form>
        <div class="grid">
            <FormCalendar fieldName="sell_date_from"
                          label="Sell date from"
                          validation="required"
                          :isFocus="true"
                          v-model:field="sell_date_from" />

            <FormCalendar fieldName="sell_date_to"
                          label="Sell date to"
                          validation="required"
                          v-model:field="sell_date_to" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="danger"
                    style="margin:0 8px"
                    @click="removeRange">
                <i class="fa-solid fa-trash-can"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Remove data from range') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormCalendar from '@crud/FormCalendar.vue'
import { validation } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isRemoveRangeModalOpen = defineModel('isRemoveRangeModalOpen')
const sell_date_from = ref(null)
const sell_date_to = ref(null)
const isLoading = ref(false);

// -----------------------------------------------------

const cancel = () => {
    isRemoveRangeModalOpen.value = false
}
// -----------------------------------------------------

const removeRange = async () => {
    const formData = { sell_date_from: sell_date_from.value, sell_date_to: sell_date_to.value }
    if (!validation(formData)) {
        return false
    }
    isLoading.value = true;
    const xlsxResponse = await getFromRoute('soldposition-remove-range', formData, 'post');

    props.formProps.clearSelection()
    props.formProps.getLazyData()

    isLoading.value = false;

    // useForm(formData).post(route('soldposition-remove-range'), {
    //     preserveState: true,
    //     preserveScroll: true,
    //     forceFormData: true,
    //     onError: (errors) => {
    //         console.error(errors)
    //     },
    //     onSuccess: () => {
    //         const message = {
    //             message: "Sold positions from given range was removed",
    //             title: "Information",
    //             type: "info",
    //             position: "center",
    //             timeout: 5,
    //         };
    //         screenMessage(message);
    //     },
    //     onFinish: () => {
    //         props.formProps.clearSelection()
    //         props.formProps.getLazyData()
    //     }
    // })

    isRemoveRangeModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
