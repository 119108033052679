<template>
    <div class="card flex justify-content-center">
        <Toast :position="position">
        </Toast>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3'
import { ref, watch } from 'vue';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { __ } from '@pages/Helper/utils.js'

const toast = useToast();
const page = usePage();

const severity = ['success', 'info', 'warn', 'error', 'secondary', 'contrast'];

let message = ref(null);
let title = ref(null);
let type = ref(null);
let position = ref(null);
let timeout = ref(null);

watch(() => page, (newPage) => {
    if (newPage?.props?.flash?.message) {
        message.value = newPage?.props?.flash?.message?.message;
        title.value = newPage?.props?.flash?.message?.title ?? '';
        type.value = newPage?.props?.flash?.message?.type;
        position.value = newPage?.props?.flash?.message?.position;
        timeout.value = newPage?.props?.flash?.message?.timeout;
        if (!severity.includes(type.value)) {
            type.value = 'info';
        }
        if (message.value) {
            toast.add({ severity: type.value, summary: __(title.value), detail: __(message.value), life: timeout.value * 1000 });
        }
    }

    // if (newPage?.props?.flash?.message?.type === 'remove-message') {
    //     toast.remove(newPage?.props?.flash?.message?.id)
    // }
}, { deep: true });

</script>

<style scoped>
.p-toast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: center;
}

.p-toast-message-success {
    background-color: #dff0d8;
    color: #3c763d;
}

.p-toast-message-info {
    background-color: #d9edf7;
    color: #31708f;
}

.p-toast-message-warn {
    background-color: #fcf8e3;
    color: #8a6d3b;
}

.p-toast-message-error {
    background-color: #f2dede;
    color: #a94442;
}

.p-toast-icon-close {
    background: none;
    border: none;
    cursor: pointer;
}

.p-toast-icon {
    margin-right: 0.5rem;
}
</style>
