<template>
    <div v-if="recordExists">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form :name="props.formProps.formName">
            <TabView scrollable
                     style="overflow: visible"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormDropdown fieldName="seller_id"
                                      label="Seller"
                                      size="4"
                                      showClear
                                      validation="required"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.contractors"
                                      :filtering="false"
                                      v-model:field="crud.seller_id" />

                        <FormDropdown fieldName="buyer_id"
                                      label="Buyer"
                                      size="4"
                                      showClear
                                      validation="required"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.contractors"
                                      :filtering="false"
                                      v-model:field="crud.buyer_id" />

                        <FormInputText fieldName="wdt_number"
                                       label="WDT number"
                                       size="2"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       :isFocus="true"
                                       v-model:field="crud.wdt_number" />

                        <FormInputText fieldName="issue_place"
                                       label="Issue place"
                                       size="2"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       v-model:field="crud.issue_place" />

                        <FormDropdown fieldName="exchange_id"
                                      label="Exchange"
                                      size="3"
                                      showClear
                                      validation="required"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.exchanges"
                                      :filter="(event) => onFilter(event, 'exchanges')"
                                      v-model:field="crud.exchange_id" />

                        <FormDropdown fieldName="payment_id"
                                      label="Payment method"
                                      size="1"
                                      showClear
                                      validation="required"
                                      :filtering="false"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.payments"
                                      v-model:field="crud.payment_id" />

                        <FormDropdown fieldName="warehouse_id"
                                      label="Warehouse"
                                      size="2"
                                      showClear
                                      validation="required"
                                      :filtering="false"
                                      :isFormDisabled="isFormDisabled"
                                      :options="select?.warehouses"
                                      v-model:field="crud.warehouse_id" />

                        <FormInputText fieldName="issue_date"
                                       label="Issue date"
                                       size="2"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       v-model:field="crud.issue_date" />

                        <FormInputText fieldName="sell_date"
                                       label="Sell date"
                                       size="2"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       v-model:field="crud.sell_date" />

                        <FormInputText fieldName="payment_date"
                                       label="Payment date"
                                       size="2"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       v-model:field="crud.payment_date" />

                        <FormFileUploader fieldName="files"
                                          label="Attachments"
                                          category="wdt"
                                          :allowedExtensions="['pdf', 'csv']"
                                          :style="{ minWidth: '200px' }"
                                          :isFormDisabled="isFormDisabled"
                                          v-model:field="crud.files" />

                    </div>
                    <div class="grid">
                        <FormEditor fieldName="description"
                                    label="Description"
                                    :isFormDisabled="isFormDisabled"
                                    v-model:field="crud.description" />
                    </div>
                </TabPanel>

                <TabPanel :header="__('Changelog')"
                          headerClass="form-tab-header">
                    <ChangeLog :log="crud?.log" />
                </TabPanel>
            </TabView>

            <div class="grid">
                <FormButtons :formProps="props.formProps"
                             :sourceForm="crud"
                             :uploadedFiles="uploadedFiles"
                             :validation="validation"
                             v-model:isFormDisabled="isFormDisabled">
                </FormButtons>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import FormFileUploader from '@crud/FormFileUploader.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, screenMessage } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(true)
const uploadedFiles = ref(null);

const options = ["contractors", "payments", "exchanges", "warehouses"];
const select = ref(null);
const crud = ref({})
const recordExists = ref(true)

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)

    if (!crud.value?.id) {
        const message = {
            message: "Record was removed. Refresh table and select other record",
            title: "Information",
            type: "info",
            position: "center",
            timeout: 10,
        };
        screenMessage(message);
        recordExists.value = false
    } else {
        recordExists.value = true
    }
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, params)
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
