<template>
    <span>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :rowButtonAction="rowButtonAction"
                :buttonsVisibility="buttonsVisibility"
                :renderColumnValue="renderColumnValue"
                v-model:selectedRows="selectedRows">

            <template #buttons="formProps">
                <div class="grid">
                    <div class="col-4 flex justify-content-start">
                        <div>
                            <Button v-if="pricelistId && warehouseId"
                                    @click="checkIfSelectedRecordsExists(formProps)"
                                    style="background-color:brown;margin:0 7px 0 25px"
                                    severity="info">
                                <i class="fa-solid fa-coins"
                                   style="margin-right:5px;font-size:18px"></i>
                                {{ __('Update prices') }}
                            </Button>
                            <div v-else
                                 style="font-size:13px;color:burlywood">Ustaw filtr, aby móc globalnie zmieniać ceny</div>
                        </div>
                    </div>
                    <div class="col-8 flex">
                        <div style="font-size:13px;color:cadetblue;">{{ __('Sell channel') }}:</div>
                        <div>
                            <FormDropdown fieldName="pricelist_id"
                                          label=""
                                          showClear
                                          style="width:300px;padding-top:0"
                                          :filtering="false"
                                          :isFormDisabled="false"
                                          :options="select?.pricelists"
                                          v-model:field="localPricelistId" />
                        </div>
                        <div style="font-size:13px;margin-left:50px;color:cadetblue;">{{ __('Warehouse') }}:</div>
                        <div>
                            <FormDropdown fieldName="warehouse_id"
                                          label=""
                                          showClear
                                          style="width:300px;padding-top:0"
                                          :filtering="false"
                                          :isFormDisabled="false"
                                          :options="select?.warehouses"
                                          v-model:field="localWarehouseId" />
                        </div>
                        <div>
                            <Button @click="setPricelistFilter(formProps)"
                                    id="filter-button"
                                    style="background-color:white;margin:0 7px 0 25px;color:brown;"
                                    severity="info">
                                <i class="fa-solid fa-filter-circle-dollar"
                                   style="margin-right:5px;font-size:18px;color:brown;"></i>
                                {{ __('Set filter') }}
                            </Button>
                        </div>
                    </div>
                </div>
            </template>

            <template #crud="formProps">
                <PriceupdateForm :formProps="formProps">
                </PriceupdateForm>
            </template>
            <template #search="searchProps">
                <PriceupdateSearch :searchProps="searchProps">
                </PriceupdateSearch>
            </template>
        </v-view>

        <Dialog modal
                :visible="isUpdatePriceModalOpen"
                v-model:visible="isUpdatePriceModalOpen"
                :header="__('Set prices in Baselinker')"
                :style="{ width: '500px' }">
            <SetPriceForm :formProps="updatepriceFormProps"
                          v-model:isUpdatePriceModalOpen="isUpdatePriceModalOpen">
            </SetPriceForm>
        </Dialog>
    </span>
</template>

<script setup>
import PriceupdateForm from './PriceupdateForm.vue'
import PriceupdateSearch from './PriceupdateSearch.vue'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import { ref, onMounted, nextTick } from "vue";
import { rowButtonAction, prepareSelect } from '@pages/Helper/crud_utils.js'
import { screenMessage } from '@pages/Helper/utils.js'
import SetPriceForm from './SetPriceForm.vue'
import { __ } from '@pages/Helper/utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    pricelistId: Number,
    warehouseId: Number,
    buttonsVisibility: Object,
})

const isUpdatePriceModalOpen = ref(false)
const updatepriceFormProps = ref({})
const localPricelistId = ref(props.pricelistId)
const localWarehouseId = ref(props.warehouseId)

const options = ['pricelists', 'warehouses']
const select = ref(null)

//------------------------------------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(options)
})

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    switch (field) {
        case "charge_source":
            return props.charge_sources.find(item => item.value === value)?.label;
        default:
            return value;
    }
};

//------------------------------------------------------------------------------

const updatepriceModalControl = {
    open: () => isUpdatePriceModalOpen.value = true
}

//------------------------------------------------------------------------------

const checkIfSelectedRecordsExists = (formProps) => {
    if (!Array.isArray(formProps.selectedRows) || formProps.selectedRows.length === 0) {
        const message = {
            message: "No product has been selected",
            title: "Product selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    updatepriceFormProps.value = { ...formProps, 'pricelistId': props.pricelistId, 'warehouseId': props.warehouseId }
    updatepriceModalControl.open()
}

//------------------------------------------------------------------------------

const setPricelistFilter = (formProps) => {
    formProps.clearSelection()
    formProps.getLazyData({ 'pricelist_id': localPricelistId.value, 'warehouse_id': localWarehouseId.value })
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
