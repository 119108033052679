<template>
    <span>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :renderColumnValue="renderColumnValue"
                :initialSort="initialSort"
                :buttonsVisibility="{ deny: ['add', 'delete', 'deleterow'] }"
                :rowButtonAction="rowButtonAction">
            <template #buttons>
                <BackButton tooltip="Back to pricelist"
                            routeName="pricelist-index" />
            </template>

            <template #crud="formProps">
                <ProductForm :formProps="formProps"
                             :buttonsVisibility="{ deny: 'delete' }">
                </ProductForm>
            </template>
            <template #search="searchProps">
                <ProductSearch :searchProps="searchProps">
                </ProductSearch>
            </template>
        </v-view>
    </span>
</template>

<script setup>
import ProductForm from './ProductForm.vue'
import ProductSearch from './ProductSearch.vue'
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import BackButton from '@crud/BackButton.vue'
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
})

//------------------------------------------------------------------------------

const renderColumnValue = (field, value, rowData) => {
    switch (field) {
        case "product_name":
            if (rowData['product_count'] > 0) {
                return "<span style='color:royalblue;font-weight:bold !important'>" + value + "</span>"
            }
            return value;
        default:
            return value;
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
