<template>
    <div class="grid"
         style="padding-left:7px;"
         :style="repeaterStyle">
        <ConfirmDialog header="Confirm delete"
                       question="Do you want to choose to delete selected row?"
                       v-model="isConfirmationOpen"
                       :operation="deleteRow"
                       :parameter="recordSelectedToDelete" />

        <Button v-if="isAddButton"
                @click="addRow"
                :disabled="disabled"
                style="margin-left:3px;"
                :label="__('Add')" />

        <Button v-if="isCopyButton && currentRecord?.length > 0"
                @click="copyLastRow"
                :disabled="disabled"
                style="margin-left:3px;"
                :label="__('Copy last')" />
        <table>
            <tbody>
                <tr>
                    <th :style="headerStyle"
                        :class="headerClass"
                        v-for="(label, index) in title"
                        :key="index">{{ __(label) }}</th>
                    <th v-if="isDeleteButton && !disabled"
                        :style="headerStyle"
                        :class="headerClass">X</th>
                </tr>
                <tr v-for="(record, index) in currentRecord"
                    :key="record?.id ?? index"
                    :name="'repeater-' + index + '-' + name"
                    :forceLoop="forceLoop">
                    <slot name="repeat"
                          :record="record"
                          :cellStyle="cellStyle"
                          :cellClass="cellClass"
                          :index="renameElements(index)" />

                    <td v-if="isDeleteButton && !disabled"
                        @click.stop="openConfirmationWindow(record)"
                        :style="cellStyle"
                        :class="cellClass"
                        style="font-weight:900;color:red;cursor:pointer;font-size:20px;vertical-align:top;padding-top:10px;">
                        <span>
                            <i v-tooltip.top="__('Delete') + (record?.id ? ' ' + record?.id : '')"
                               class="fa-solid fa-trash-can"></i>
                        </span>
                    </td>
                </tr>
                <tr>
                    <slot name="footer"
                          :cellStyle="cellStyle"
                          :cellClass="cellClass"
                          :record="record" />
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>

import { ref, watch, nextTick } from 'vue';
import Button from 'primevue/button';
import ConfirmDialog from '@pages/Crud/ConfirmDialog.vue'
import { __ } from '@pages/Helper/utils.js'


const props = defineProps({
    keys: { type: Object, default: {} },
    name: { type: String, required: true },
    isFirstRowEmpty: { type: Boolean, default: false },
    isCopyButton: { type: Boolean, default: false },
    isAddButton: { type: Boolean, default: true },
    isDeleteButton: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    repeaterStyle: String,
    title: Array,
    customFunction: [Function, null],
    headerStyle: {
        type: [Object, String],
        default: () => ({
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: '8px',
            verticalAlign: 'top',
        })
    },
    headerClass: {
        type: String,
        default: null
    },
    cellStyle: {
        type: [Object, String],
        default: () => ({
            border: '1px solid #dddddd',
            textAlign: 'left',
            padding: '8px',
            verticalAlign: 'top',
        })
    },
    cellClass: {
        type: String,
        default: null
    },
})

const forceLoop = ref(false)
const isConfirmationOpen = ref(false)
const recordSelectedToDelete = ref(null)

const currentRecord = defineModel('record', { default: [] })

// ------------------------------------------------------------------------------

watch(() => currentRecord.value, (newValue, oldValue) => {
    if (currentRecord.value?.length == 0 && props.isFirstRowEmpty) {
        currentRecord.value = [{}]
    }

    forceLoop.value = !forceLoop.value
}, { deep: true, immediate: true })

// ------------------------------------------------------------------------------

const deleteRow = async (record) => {
    let newRecord = currentRecord.value.filter((elem) => {
        if (elem?.id && record?.id) {
            return elem.id !== record.id
        } else {
            return elem?.__index !== record?.__index
        }
    });

    currentRecord.value = [...newRecord]
    await resetElements()
}

// ------------------------------------------------------------------------------

const addRow = () => {
    let newRecord = { id: null, __index: createRepeaterIndex(), files: [], ...props.keys }
    if (props.customFunction) {
        newRecord = props.customFunction(currentRecord.value)
    }

    currentRecord.value = [...currentRecord?.value ?? [], newRecord]
}

// ------------------------------------------------------------------------------

const copyLastRow = () => {
    if (currentRecord.value?.length > 0) {
        let newRecord = { files: [], ...currentRecord.value[currentRecord.value.length - 1] }
        newRecord.id = null
        newRecord.__index = createRepeaterIndex()
        currentRecord.value = [...currentRecord?.value ?? [], newRecord]
    } else {
        addRow()
    }
}

// ------------------------------------------------------------------------------

const renameElements = async (index) => {
    await nextTick();
    let repeaterTr = document?.querySelector("tr[name='repeater-" + index + '-' + props.name + "']");
    let elementsWithName = repeaterTr?.querySelectorAll('[name]');
    let currentName = null

    elementsWithName?.forEach(element => {
        currentName = element.getAttribute('name');
        if (!currentName.startsWith(props.name + '-')) {
            element.setAttribute('name', props.name + '-' + index + '-' + currentName);
            element.setAttribute('data-before', currentName)
        }
    });

    return index
}

// ------------------------------------------------------------------------------

const resetElements = async () => {
    await nextTick();
    let start = 'repeater-';
    let end = '-' + props.name;
    let repeaterTr = document?.querySelector(`tr[name^="${start}"][name$="${end}"]`);
    let elementsWithName = repeaterTr?.querySelectorAll('[name]');

    elementsWithName?.forEach(element => {
        if (element.hasAttribute('data-before')) {
            element.setAttribute('name', element.getAttribute('data-before'));
            element.removeAttribute('data-before');
        }
    });
}

// ------------------------------------------------------------------------------

const createRepeaterIndex = () => {
    return Date.now().toString() + Math.random().toString();
}

// ------------------------------------------------------------------------------

const openConfirmationWindow = (record) => {
    recordSelectedToDelete.value = record
    isConfirmationOpen.value = true
}

//-----------------------------------------------------------------------------------------------

</script>

<style lang="scss" scoped>
table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
}
</style>
