<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormMultiselect fieldName="currency_id"
                                     label="Currency"
                                     size="4"
                                     showClear
                                     :isFocus="true"
                                     :options="select?.currencies"
                                     v-model:field="search.currency_id" />

                    <FormInputNumber fieldName="rate_from"
                                     label="Rate from"
                                     size="4"
                                     validation="required"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="4"
                                     :maxFractionDigits="4"
                                     v-model:field="search.rate_from" />

                    <FormInputNumber fieldName="rate_to"
                                     label="Rate to"
                                     size="4"
                                     validation="required"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="4"
                                     :maxFractionDigits="4"
                                     v-model:field="search.rate_to" />

                    <FormCalendar fieldName="date_from"
                                  label="Date from"
                                  size="4"
                                  v-model:field="search.date_from" />

                    <FormCalendar fieldName="date_to"
                                  label="Date to"
                                  size="4"
                                  v-model:field="search.date_to" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ['currencies']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
