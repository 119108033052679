<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :routes="routes"
            :initialSort="initialSort"
            :rowButtonAction="rowButtonAction"
            v-model:selectedRows="selectedRows">

        <template #crud="formProps">
            <PositionForm :formProps="formProps">
            </PositionForm>
        </template>
        <template #search="searchProps">
            <PositionSearch :searchProps="searchProps">
            </PositionSearch>
        </template>

    </v-view>
</template>

<script setup>
import PositionForm from './PositionForm.vue'
import PositionSearch from './PositionSearch.vue'
// import Button from 'primevue/button';
// import { router } from '@inertiajs/vue3'
// import Dialog from 'primevue/dialog';
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
})

//const foreignFormData = ref({})

//const isModalOpen = ref(false)
// const modalControl = {
//     open: () => isModalOpen.value = true,
//     close: () => isModalOpen.value = false,
// }

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
