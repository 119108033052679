<template>
    <div>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :rowButtonAction="rowButtonAction"
                v-model:selectedRows="selectedRows">

            <template #crud="formProps">
                <UnitForm :formProps="formProps">
                </UnitForm>
            </template>
            <template #search="searchProps">
                <UnitSearch :searchProps="searchProps">
                </UnitSearch>
            </template>

        </v-view>
    </div>
</template>

<script setup>
import UnitForm from './UnitForm.vue'
import UnitSearch from './UnitSearch.vue'
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { ref } from "vue";

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
})

const selectedRows = ref([])

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
