<template>
    <div>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :tooltipColumns="tooltipColumns"
                :routes="routes"
                :initialSort="initialSort"
                :rowButtonAction="rowButtonAction"
                :afterLoadData="afterLoadData"
                :buttonsVisibility="buttonsVisibility"
                v-model:selectedRows="selectedRows">

            <template #buttons="formProps">
                <div>
                    <Button @click="actionImportButton(formProps)"
                            style=""
                            severity="warning">
                        <i class="fa-solid fa-file-arrow-up"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Data import') }}
                    </Button>

                    <Button @click="actionGenerateOrderButton(formProps)"
                            style="margin:0 7px 0 25px"
                            severity="primary">
                        <i class="fa-solid fa-rectangle-list"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Generate order') }}
                    </Button>

                    <Button @click="actionRemoveFromRangeButton(formProps)"
                            style="margin:0 7px 0 25px"
                            severity="danger">
                        <i class="fa-regular fa-trash-can"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Remove range') }}
                    </Button>
                </div>
            </template>

            <template #crud="formProps">
                <SoldpositionForm :formProps="formProps">
                </SoldpositionForm>
            </template>
            <template #search="searchProps">
                <SoldpositionSearch :searchProps="searchProps">
                </SoldpositionSearch>
            </template>
        </v-view>

        <Dialog modal
                :visible="isCustomImportModalOpen"
                v-model:visible="isCustomImportModalOpen"
                :header="__('Data import')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Data will be imported from Excel file') }}</p>

            <CustomImportForm :formProps="customImportFormProps"
                              v-model:isCustomImportModalOpen="isCustomImportModalOpen">
            </CustomImportForm>
        </Dialog>

        <Dialog modal
                :visible="isGenerateOrderModalOpen"
                v-model:visible="isGenerateOrderModalOpen"
                :header="__('Generate order based on sell data')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Working orders based on sell data will be generated') }}</p>

            <GenerateOrderForm :formProps="generateOrderFormProps"
                               v-model:isGenerateOrderModalOpen="isGenerateOrderModalOpen">
            </GenerateOrderForm>
        </Dialog>

        <Dialog modal
                :visible="isRemoveRangeModalOpen"
                v-model:visible="isRemoveRangeModalOpen"
                :header="__('Remove data from range')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Sold positions from given range will be removed') }}</p>

            <RemoveRangeForm :formProps="removeRangeFormProps"
                             v-model:isRemoveRangeModalOpen="isRemoveRangeModalOpen">
            </RemoveRangeForm>
        </Dialog>
    </div>
</template>

<script setup>
import { ref, nextTick } from "vue";
import SoldpositionForm from './SoldpositionForm.vue'
import SoldpositionSearch from './SoldpositionSearch.vue'
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import RemoveRangeForm from './RemoveRangeForm.vue'
import GenerateOrderForm from './GenerateOrderForm.vue'
import CustomImportForm from "./CustomImportForm.vue";
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { __, screenMessage } from '@pages/Helper/utils.js'

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    tooltipColumns: Object,
    routes: Object,
    initialSort: Object,
    buttonsVisibility: Object,
    customData: Object
})

const selectedRows = ref([])
const isRemoveRangeModalOpen = ref(false)
const isGenerateOrderModalOpen = ref(false)
const isCustomImportModalOpen = ref(false)
const removeRangeFormProps = ref({})
const generateOrderFormProps = ref({})
const customImportFormProps = ref({})

//------------------------------------------------------------------------------

const afterLoadData = (data) => {
    nextTick().then(() => {
        const elements = document.querySelectorAll('.copy-on-click')
        if (elements) {
            elements.forEach((element) => {
                element.addEventListener('click', () => {
                    const ean = element.getAttribute('data-clipboard-text')
                    navigator.clipboard.writeText(ean)
                    const message = {
                        message: __(`EAN :ean was copied to clipboard`, { ean: ean }),
                        type: "info",
                        position: "top-right",
                        timeout: 1
                    }
                    screenMessage(message);
                })
            })
        }
    })
}

//------------------------------------------------------------------------------

const actionRemoveFromRangeButton = (formProps) => {
    removeRangeFormProps.value = formProps
    isRemoveRangeModalOpen.value = true
}

//------------------------------------------------------------------------------

const actionGenerateOrderButton = (formProps) => {
    generateOrderFormProps.value = { ...formProps, 'vendor_filters': props.customData.vendor_filters }
    isGenerateOrderModalOpen.value = true
}

//------------------------------------------------------------------------------

const actionImportButton = (formProps) => {
    customImportFormProps.value = formProps
    isCustomImportModalOpen.value = true
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
