<template>
    <v-form>
        <div class="grid">
            <FormDropdown fieldName="productgroup_id"
                          label="Product group"
                          showClear
                          validation="required"
                          :options="select?.productgroups"
                          :filter="(event) => onFilter(event, 'productgroups')"
                          v-model:field="productgroup_id" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="setProductGroup">
                <i class="fa-solid fa-layer-group"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Set product group') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import { validation, onFilterService, initForm, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isProductGroupModalOpen = defineModel('isProductGroupModalOpen')

// -----------------------------------------------------

const isLoading = ref(true);
const productgroup_id = ref(null)
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['productgroups']);
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading)
}

// -----------------------------------------------------

const cancel = () => {
    isProductGroupModalOpen.value = false
}
// -----------------------------------------------------

const setProductGroup = () => {
    const formData = { productgroup_id: productgroup_id.value, selectedRows: props.formProps.selectedRows.map(row => { return { 'id': row.id } }) }
    if (!validation(formData)) {
        return false
    }
    useForm(formData).post(route('product-set-product-group'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            const message = {
                message: "Product group for selected products was set successfully",
                title: "Information",
                type: "info",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
        },
        onFinish: () => {
            props.formProps.clearSelection()
            props.formProps.getLazyData()
        }
    })

    isProductGroupModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
