<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Chips class="w-full"
               autocomplete="off"
               :max="max"
               :allowDuplicate="allowDuplicate"
               :disabled="isFormDisabled"
               :name="fieldName"
               :validation="validation"
               :separator="separator"
               :inputClass="{ 'required-field': hasRequiredAttr, [inputClass]: true }"
               :inputStyle="{ ...inputStyle, 'min-width': '10px' }"
               @add="onAdd"
               v-focus="isFocus"
               v-model="field"
               :pt="{
        container: {
            class: 'w-full',
        }
    }
        ">
            <template #chip="slotProps">
                <div>
                    <span>{{ prefix }}{{ slotProps.value }}{{ suffix }}</span>
                </div>
            </template>
        </Chips>
        <small v-if="helperText">{{ __(helperText) }}</small>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Chips from 'primevue/chips';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    helperText: {
        type: String,
        default: null
    },
    disabled: {
        type: Boolean,
        default: false
    },
    inputStyle: {
        type: Object,
        default: null
    },
    inputClass: {
        type: String,
        default: null
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: Object,
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
    separator: {
        type: String,
        default: ','
    },
    prefix: {
        type: String,
        default: ''
    },
    suffix: {
        type: String,
        default: ''
    },
    allowDuplicate: {
        type: Boolean,
        default: false
    },
    max: {
        type: Number,
        default: null
    },
    shouldBeNumber: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

const onAdd = (event) => {
    const newChip = event.value[event.value.length - 1];
    if (props.shouldBeNumber) {
        if (isNaN(parseFloat(newChip)) || !isFinite(newChip)) {
            field.value.pop();
        }
    }
}

</script>

<style lang="scss"
       scoped></style>
