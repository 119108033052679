<template>
    <Dialog modal
            :closeOnEscape="true"
            :maximizable="false"
            :visible="isConfirmationOpen"
            :header="__(header)"
            v-model:visible="isConfirmationOpen"
            :style="modalStyle"
            :class="modalClass"
            :pt="{
                header: {
                    class: headerClass,
                    style: headerStyle
                },
            }">
        <div :class="questionClass"
             :style="questionStyle">
            {{ __(question) }}
        </div>
        <div style="margin-top:20px;">
            <span style="padding:5px 10px;">
                <Button @click="executeOperation">{{ __('Yes') }}</Button>
            </span>
            <span style="padding:5px 10px;">
                <Button @click="closeConfirmationWindow"
                        :default="true">{{ __('No') }}</Button>
            </span>
        </div>
    </Dialog>
</template>

<script setup>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    operation: Function,
    parameter: {
        type: [Object, String],
        default: null
    },
    header: String,
    question: String,
    modalStyle: {
        type: Object,
        default: { width: '400px', borderColor: 'red' }

    },
    modalClass: {
        type: String,
        default: null
    },
    headerClass: {
        type: String,
        default: 'delete-confirmation-header'
    },
    headerStyle: {
        type: Object,
        default: null
    },
    questionClass: {
        type: String,
        default: 'delete-confirmation-field'
    },
    questionStyle: {
        type: Object,
        default: null
    }
});

const isConfirmationOpen = defineModel();

// --------------------------------------------------------------------------------

const executeOperation = () => {
    props.operation(props.parameter);
    closeConfirmationWindow();
};

// --------------------------------------------------------------------------------

const closeConfirmationWindow = () => {
    isConfirmationOpen.value = false
};

// --------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
