<template>
    <div :class="[outerClass, colSize]">
        <label class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
                __(label)
            }}</label>
        <InputNumber :mode="mode"
                     :currency="currency"
                     :locale="locale"
                     :disabled="isFormDisabled"
                     :name="fieldName"
                     :validation="validation"
                     :min="min"
                     :max="max"
                     :minFractionDigits="minFractionDigits"
                     :maxFractionDigits="maxFractionDigits"
                     :prefix="prefix"
                     :suffix="suffix"
                     :allowEmpty="allowEmpty"
                     :useGrouping="useGrouping"
                     :inputStyle="{ 'text-align': 'right', ...inputStyle, 'min-width': '10px' }"
                     :inputClass="inputClass"
                     autocomplete="off"
                     class="w-full"
                     ref="inputNumberRef"
                     :class="{ 'required-field': hasRequiredAttr }"
                     v-focus="isFocus"
                     v-model="parseField" />
        <small v-if="helperText">{{ __(helperText) }}</small>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick } from 'vue';
import InputNumber from 'primevue/inputnumber';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    mode: {
        type: String,
        required: false
    },
    min: {
        type: Number,
        default: null
    },
    max: {
        type: Number,
        default: null
    },
    minFractionDigits: {
        type: Number,
        default: null
    },
    maxFractionDigits: {
        type: Number,
        default: null
    },

    currency: {
        type: String,
        required: false
    },
    locale: {
        type: String,
        required: false
    },
    inputStyle: {
        type: [Object, String],
        default: null
    },
    inputClass: {
        type: String,
        default: null
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    helperText: {
        type: String,
        default: null
    },
    prefix: {
        type: String,
        default: null
    },
    suffix: {
        type: String,
        default: null
    },
    useGrouping: {
        type: Boolean,
        default: true
    },
    allowEmpty: {
        type: Boolean,
        default: true
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
    default: {
        type: Number,
        default: 0
    }
});

const field = defineModel('field');

const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const inputNumberRef = ref(null);
const outerClass = ref('field');

const parseField = computed({
    //get: () => field.value ? Number(field.value) : props.default,
    get: () => {
        if (field.value !== null && field.value !== undefined && field.value !== '') {
            return Number(field.value);
        } else {
            field.value = props.default
            return props.default
        }
    },
    set: newValue => field.value = newValue
});

onMounted(async () => {
    if (props.isFocus) {
        await nextTick()
        inputNumberRef.value.$el.children[0]?.focus();
    }
});

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss" scoped></style>
