<template>
    <span class="flex justify-content-center"
          :class="className"
          style="width:100%"
          :style="style">
        <img :src="image"
             :width="width"
             :height="height"
             style="max-width: 100%;" />
    </span>
</template>

<script setup>
import { computed } from 'vue'


const props = defineProps({
    src: String,
    width: String,
    height: String,
    style: [Object, String],
    className: String,
})

const images = import.meta.globEager('/resources/images/**')
let image = null;

if (props.src.startsWith('https://')) {
    image = computed(() => props.src)
} else {
    image = computed(() => {
        const key = `/resources/images/${props.src}`
        return images[key]?.default
    })
}

</script>

<style lang="scss"
       scoped></style>
