<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form>
        <div class="grid">
            <FormCalendar fieldName="sell_date_from"
                          label="Sell date from"
                          validation="required"
                          :isFocus="true"
                          v-model:field="sell_date_from" />

            <FormCalendar fieldName="sell_date_to"
                          label="Sell date to"
                          validation="required"
                          v-model:field="sell_date_to" />
        </div>
        <div class="grid">
            <FormCheckbox fieldName="is_sum_by_ean"
                          size="6"
                          label="Is sum by EAN?"
                          v-model:field="is_sum_by_ean" />

            <FormRadioButton fieldName="ean_code_type"
                             label="EAN code types"
                             size="6"
                             :data="eanCodeTypes"
                             v-model:field="ean_code_type" />
        </div>
        <div class="grid">
            <FormMultiselect fieldName="vendor_id"
                             label="For vendors"
                             showClear
                             :filtering="false"
                             :options="select?.vendors"
                             v-model:field="vendor_id" />

        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="primary"
                    style="margin:0 8px"
                    @click="generateOrder">
                <i class="fa-solid fa-rectangle-list"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Generate order (working)') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Button from 'primevue/button';
import FormCalendar from '@crud/FormCalendar.vue'
import FormCheckbox from '@crud/FormCheckbox.vue'
import FormRadioButton from '@crud/FormRadioButton.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import { validation, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, printReport, getFromRoute } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isGenerateOrderModalOpen = defineModel('isGenerateOrderModalOpen')
const sell_date_from = ref(null)
const sell_date_to = ref(null)
const isLoading = ref(false);
const is_sum_by_ean = ref(false)
const ean_code_type = ref('P')
const vendor_id = ref(null)
const options = ["vendors"];
const select = ref(null);

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(options)
    vendor_id.value = props.formProps?.vendor_filters
})

// -----------------------------------------------------

const cancel = () => {
    isGenerateOrderModalOpen.value = false
}
// -----------------------------------------------------

const generateOrder = async () => {
    const formData = { sell_date_from: sell_date_from.value, sell_date_to: sell_date_to.value, is_sum_by_ean: is_sum_by_ean.value, ean_code_type: ean_code_type.value, vendor_id: vendor_id.value }
    if (!validation(formData)) {
        return false
    }

    isLoading.value = true;
    const xlsxResponse = await getFromRoute('soldposition-generate-order', formData, 'post');

    props.formProps.clearSelection()
    props.formProps.getLazyData()

    printReport(xlsxResponse, null, false)

    isLoading.value = false;
    isGenerateOrderModalOpen.value = false
}

// -----------------------------------------------------

const eanCodeTypes = [
    { label: 'producenta', value: 'P' },
    { label: 'własne', value: 'W' },
]

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
