<template>
    <div class="field"
         :class="colSize">
        <label class="block"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
        <SelectButton optionLabel="label"
                      dataKey="value"
                      :disabled="isFormDisabled"
                      :name="fieldName"
                      :options="options"
                      :multiple="multiple"
                      v-focus="isFocus"
                      v-model="field">
            <template #option="slotProps">
                <div :style="slotProps.option?.style">
                    {{ slotProps.option.label }}
                </div>
            </template>
        </SelectButton>
    </div>
</template>

<script setup>
import SelectButton from 'primevue/selectbutton';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    multiple: {
        type: Boolean,
        default: false
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    options: {
        type: Array,
        default: () => ([{ label: __('Yes'), value: true, style: "color:red;background-color:blue" }, { label: __('No'), value: false, style: "color:blue;background-color:red" }]),
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';

</script>

<style lang="scss"
       scoped></style>
