export const appName = 'viatrade'
export const appCaption = 'Via Trade'
export const systemLogo = "logos/system_logo.webp";
export const menuLogo = "logos/menu_logo.webp";
export const developerLogo = "logos/developer_logo.webp";
export const startSplash = "logos/start_splash.webp";
export const underConstructionLogo = "logos/under_construction_logo.webp";
export const bgTextureName = "textures/bg_texture_";
export const bgTextureExt = "webp";

export const nbpUrl = 'https://api.nbp.pl/api/exchangerates/rates/A';
