<template>
    <div v-if="recordExists">
        <Transition v-if="isLoading"
                    name="spinner">
            <div id="cover-spin"></div>
        </Transition>

        <v-form :name="props.formProps.formName">
            <TabView scrollable
                     style="overflow: visible"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">

                        <FormCalendar fieldName="sell_date"
                                      label="Sell date"
                                      size="2"
                                      validation="required"
                                      :isFormDisabled="isFormDisabled"
                                      :isFocus="true"
                                      v-model:field="crud.sell_date" />

                        <FormInputText fieldName="imported_product_name"
                                       label="Product name"
                                       size="6"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       v-model:field="crud.imported_product_name" />

                        <FormInputText fieldName="imported_ean_code"
                                       label="EAN"
                                       size="2"
                                       validation="required"
                                       :isFormDisabled="isFormDisabled"
                                       v-model:field="crud.imported_ean_code" />

                        <FormInputNumber fieldName="quantity"
                                         label="Quantity"
                                         size="2"
                                         validation="required"
                                         :minFractionDigits="2"
                                         :maxFractionDigits="2"
                                         :isFormDisabled="isFormDisabled"
                                         v-model:field="crud.quantity" />

                        <FormDropdown fieldName="product_id"
                                      label="Product"
                                      size="6"
                                      showClear
                                      :isFormDisabled="true"
                                      :options="select?.products"
                                      v-model:field="crud.product_id" />

                        <FormDropdown fieldName="warehouse_id"
                                      label="Warehouse"
                                      size="6"
                                      showClear
                                      :isFormDisabled="true"
                                      :options="select?.warehouses"
                                      v-model:field="crud.warehouse_id" />
                    </div>
                </TabPanel>

                <TabPanel :header="__('Changelog')"
                          headerClass="form-tab-header">
                    <ChangeLog :log="crud?.log" />
                </TabPanel>
            </TabView>

            <div class="grid">
                <FormButtons :formProps="props.formProps"
                             :sourceForm="crud"
                             :validation="validation"
                             :buttonsVisibility="buttonsVisibility"
                             v-model:isFormDisabled="isFormDisabled">
                </FormButtons>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, screenMessage } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(true)

const options = ["products", "warehouses"];
const select = ref(null);
const crud = ref({})
const recordExists = ref(true)

const buttonsVisibility = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, buttonsVisibility)

    if (!crud.value?.id) {
        const message = {
            message: "Record was removed. Refresh table and select other record",
            title: "Information",
            type: "info",
            position: "center",
            timeout: 10,
        };
        screenMessage(message);
        recordExists.value = false
    } else {
        recordExists.value = true
    }
})


</script>

<style lang="scss" scoped></style>
