<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Calendar class="w-full"
                  autocomplete="off"
                  hourFormat="24"
                  locale="pl"
                  :name="fieldName"
                  :validation="validation"
                  :class="{ 'required-field': hasRequiredAttr }"
                  :showIcon="showIcon"
                  :showButtonBar="showButtonBar"
                  :showTime="showTime"
                  :showOnFocus="showOnFocus"
                  :dateFormat="dateFormat"
                  :manualInput="manualInput"
                  :disabled="isFormDisabled"
                  v-focus="isFocus"
                  v-model="field" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Calendar from 'primevue/calendar';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    showIcon: {
        type: Boolean,
        default: true
    },
    showButtonBar: {
        type: Boolean,
        default: true
    },
    showTime: {
        type: Boolean,
        default: false
    },
    showOnFocus: {
        type: Boolean,
        default: false
    },
    dateFormat: {
        type: String,
        default: 'yy-mm-dd'
    },
    manualInput: {
        type: Boolean,
        default: true
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

// watch(() => field.value, (newValue, oldValue) => {
//     if (newValue && props.dateFormat === 'yy-mm-dd' && field.value?.length > 10 && field.value?.includes('T')) {
//         field.value = convertDate(field.value)
//     }
// }, { deep: true, immediate: true })

</script>

<style lang="scss"
       scoped></style>
