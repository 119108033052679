<template>
    <v-form>
        <div class="grid">
            <FormDropdown fieldName="dutycode_id"
                          label="Duty code"
                          showClear
                          :options="select?.dutycodes"
                          :filter="(event) => onFilter(event, 'dutycodes')"
                          v-model:field="dutycode_id" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="setDutyCode">
                <i class="fa-solid fa-list-check"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Set duty code') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import { validation, onFilterService, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isDutyCodeModalOpen = defineModel('isDutyCodeModalOpen')

// -----------------------------------------------------

const isLoading = ref(true);
const dutycode_id = ref(null)
const options = ["dutycodes"];
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    isLoading.value = true
    select.value = await prepareSelect(options)
    isLoading.value = false
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading)
}

// -----------------------------------------------------

const cancel = () => {
    isDutyCodeModalOpen.value = false
}
// -----------------------------------------------------

const setDutyCode = () => {
    const formData = { dutycode_id: dutycode_id.value, selectedRows: props.formProps.selectedRows.map(row => { return { 'id': row.id } }) }
    if (!validation(formData)) {
        return false
    }
    useForm(formData).post(route('product-set-duty-code'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            const message = {
                message: "Duty code for selected products was set successfully",
                title: "Information",
                type: "info",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
        },
        onFinish: () => {
            props.formProps.clearSelection()
            props.formProps.getLazyData()
        }
    })

    isDutyCodeModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
