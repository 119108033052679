<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormDropdown fieldName="vendor_id"
                                  label="Vendor"
                                  size="6"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.vendors"
                                  :filter="(event) => onFilter(event, 'vendors')"
                                  v-model:field="crud.vendor_id" />

                    <FormInputNumber fieldName="vendor_charge"
                                     label="Vendor charge"
                                     size="6"
                                     suffix=" %"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.vendor_charge" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />

                </div>
            </TabPanel>

            <TabPanel :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import ChangeLog from '@crud/ChangeLog.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)

const options = ['vendors']
const select = ref(null)
const crud = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, params)
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
