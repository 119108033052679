<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :routes="routes"
            :initialSort="initialSort"
            :rowButtonAction="rowButtonAction"
            :renderColumnValue="renderColumnValue"
            v-model:selectedRows="selectedRows">

        <template #crud="formProps">
            <MarketplaceForm :formProps="formProps">
            </MarketplaceForm>
        </template>
        <template #search="searchProps">
            <MarketplaceSearch :searchProps="searchProps">
            </MarketplaceSearch>
        </template>

    </v-view>
</template>

<script setup>
import MarketplaceForm from './MarketplaceForm.vue'
import MarketplaceSearch from './MarketplaceSearch.vue'
import { ref } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
})

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    switch (field) {
        case "marketplace_provision":
            return value + ' %';
        default:
            return value;
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
