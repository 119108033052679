<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form>
        <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Based on sales data from period') + ': ' }}</p>
        <div class="grid">
            <FormCalendar fieldName="sell_date_from"
                          label="Sell date from"
                          validation="required"
                          :isFocus="true"
                          v-model:field="sell_date_from" />

            <FormCalendar fieldName="sell_date_to"
                          label="Sell date to"
                          validation="required"
                          v-model:field="sell_date_to" />
        </div>
        <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('WDT document will be prepared for period') + ': ' }}</p>
        <div class="grid">
            <FormInputMask fieldName="year"
                           label="Year"
                           validation="required"
                           size="3"
                           mask="9999"
                           placeholder="9999"
                           v-model:field="year" />

            <FormDropdown fieldName="month"
                          label="Month"
                          validation="required"
                          :filtering="false"
                          :options="select?.months"
                          v-model:field="month" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="info"
                    style="margin:0 8px"
                    @click="createWdt">
                <i class="fa-solid fa-file-word"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Create WDT document') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Button from 'primevue/button';
import FormCalendar from '@crud/FormCalendar.vue'
import FormInputMask from '@crud/FormInputMask.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import { validation, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, getFromRoute, screenMessage, printReport, firstDayOfPrevMonth, lastDayOfPrevMonth } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isCreateWdtModalOpen = defineModel('isCreateWdtModalOpen')
const isLoading = ref(false);

// -----------------------------------------------------

const sell_date_from = ref(firstDayOfPrevMonth())
const sell_date_to = ref(lastDayOfPrevMonth())


const year = ref(new Date().getFullYear())
const month = ref(((new Date().getMonth()).toString()).padStart(2, '0'))

const select = ref(null);

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['months']);
})

// -----------------------------------------------------

const cancel = () => {
    isCreateWdtModalOpen.value = false
}
// -----------------------------------------------------

const createWdt = async () => {
    const formData = { sell_date_from: sell_date_from.value, sell_date_to: sell_date_to.value, year: year.value, month: month.value }
    if (!validation(formData)) {
        return false
    }
    isLoading.value = true
    const wdtResponse = await getFromRoute('wdt-create-document', formData, 'post');

    props.formProps.clearSelection()
    props.formProps.getLazyData()

    printReport(wdtResponse, null, false)

    isLoading.value = false
    isCreateWdtModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
