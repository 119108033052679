<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
        <Slider :step="stepAsNumber"
                :disabled="isFormDisabled"
                :name="fieldName"
                v-focus="isFocus"
                v-model="field" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Slider from 'primevue/slider';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    step: {
        type: [Number, String],
        default: 1
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';
const stepAsNumber = Number(props.step);
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss"
       scoped></style>
