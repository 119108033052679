<template>
    <div>
        Changelog
    </div>
</template>

<script setup>

</script>

<style lang="scss"
       scoped></style>
