<template>
    <div :class="['flex', 'flex-row', 'gap-' + gap, className]"
         style="width:100%;margin-top:15px"
         :style="['height:' + height, style]">
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    gap: { type: [Number, String], default: 2 },
    style: Object,
    height: { type: String, default: 'auto' },
    class: { type: String, default: null },
})
const className = ref(props.class);

</script>

<style lang="scss"
       scoped></style>
