<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">

                    <FormDropdown fieldName="wdt_id"
                                  label="WDT document"
                                  size="3"
                                  showClear
                                  :isFormDisabled="true"
                                  :options="select?.wdts"
                                  :filter="(event) => onFilter(event, 'wdts')"
                                  v-model:field="crud.wdt_id" />

                    <FormDropdown fieldName="product_id"
                                  label="Product"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.products"
                                  :filter="(event) => onFilter(event, 'products')"
                                  v-model:field="crud.product_id" />

                    <FormInputNumber fieldName="quantity"
                                     label="Quantity"
                                     size="2"
                                     validation="required"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     v-model:field="crud.quantity" />

                    <FormInputNumber fieldName="net_price"
                                     label="Net price"
                                     size="2"
                                     validation="required"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.net_price" />

                    <FormInputNumber fieldName="vat_rate"
                                     label="VAT rate"
                                     size="2"
                                     validation="required"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.vat_rate" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />
                </div>
            </TabPanel>

            <TabPanel :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, initForm } from '@pages/Helper/crud_utils.js'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(true)

const options = ['wdts', 'products']
const select = ref(null)
const crud = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)
})

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
