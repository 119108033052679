<template>
    <v-form>
        <div class="grid">
            <FormRadioButton fieldName="charge_source"
                             label="Charge source"
                             :data="select?.charge_sources"
                             v-model:field="charge_source"
                             :change="switchManualChargeVisibility" />

            <FormInputNumber v-if="isManualChargeVisible"
                             fieldName="manual_charge"
                             label="Manual charge"
                             suffix=" %"
                             :minFractionDigits="2"
                             :maxFractionDigits="2"
                             v-model:field="manual_charge" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="setCharges">
                <i class="fa-solid fa-hand-holding-dollar"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Set charge') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormRadioButton from '@crud/FormRadioButton.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import { prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isSetChargeModalOpen = defineModel('isSetChargeModalOpen')

// -----------------------------------------------------

const charge_source = ref('P')
const manual_charge = ref(0)
const isManualChargeVisible = ref(false)
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['charge_sources']);
})

// -----------------------------------------------------

const switchManualChargeVisibility = (event) => {
    isManualChargeVisible.value = event.target.value === 'M'
}

// -----------------------------------------------------

const cancel = () => {
    isSetChargeModalOpen.value = false
}
// -----------------------------------------------------

const setCharges = () => {
    const formData = {
        'charge_source': charge_source.value, 'manual_charge': manual_charge.value, selectedRows: props.formProps.selectedRows.map(row => {
            return { 'id': row.id }
        })
    }
    useForm(formData).post(route('set-charges'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            const message = {
                message: "Charges of selected products was set",
                title: "Information",
                type: "info",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
        },
        onFinish: () => {
            props.formProps.clearSelection()
            props.formProps.getLazyData()
        }
    })

    isSetChargeModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
