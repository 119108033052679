<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{ __(label) }}</label>
        <InputMask style="width:100%"
                   :disabled="isFormDisabled"
                   :name="fieldName"
                   :mask="mask"
                   :validation="validation"
                   :class="{ 'required-field': hasRequiredAttr, [inputClass]: true }"
                   :style="inputStyle"
                   :placeholder="placeholder"
                   v-focus="isFocus"
                   v-model="field" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import InputMask from 'primevue/inputmask';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    mask: {
        type: String,
        required: true
    },
    placeholder: {
        type: String,
        default: null
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inputStyle: {
        type: Object,
        default: null
    },
    inputClass: {
        type: String,
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';
const outerClass = ref('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const hasRequiredAttr = validation.value.includes('required');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss" scoped></style>
