<template>
    <div class="field"
         :class="colSize">
        <label class="block"
               :class="labelClass"
               :style="labelStyle">{{ __(label) }}</label>
        <ColorPicker style="width:100%"
                     :name="fieldName"
                     :inline="inline"
                     :format="format"
                     :defaultColor="defaultColor"
                     :disabled="isFormDisabled"
                     v-focus="isFocus"
                     v-model="field" />
    </div>
</template>

<script setup>
import ColorPicker from 'primevue/colorpicker';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    inline: {
        type: Boolean,
        default: false
    },
    format: {
        type: String,
        default: 'hex'
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    defaultColor: {
        type: String,
        default: '#ffffff'
    },
});

const field = defineModel('field');
const colSize = props?.size ? 'col-' + props.size : 'col';

</script>

<style lang="scss"
       scoped></style>
