<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="carton_size"
                                   label="Carton size"
                                   size="4"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   :isFocus="true"
                                   v-model:field="crud.carton_size" />

                    <FormDropdown fieldName="wave_id"
                                  label="Wave type"
                                  size="4"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.waves"
                                  v-model:field="crud.wave_id" />

                    <FormInputText fieldName="symbol"
                                   label="Symbol"
                                   size="4"
                                   validation="required"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.symbol" />

                    <FormInputText fieldName="type"
                                   label="Kind"
                                   size="6"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.type" />

                    <FormInputNumber fieldName="carton_value"
                                     label="Value"
                                     size="3"
                                     validation="required"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.carton_value" />
                    <FormDropdown fieldName="currency_id"
                                  label="Currency"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.currencies"
                                  v-model:field="crud.currency_id" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />
                </div>
            </TabPanel>

            <TabPanel :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { validation, initForm } from '@pages/Helper/crud_utils.js'
import FormInputText from '@crud/FormInputText.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormButtons from '@crud/FormButtons.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { __, getField } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(true)

const options = ["waves", "currencies"];
const select = ref(null);
const crud = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, null, null, null, { 'currency_id': await getField('currencies', 'id', "symbol='PLN'") })
})

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
