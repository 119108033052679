<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form>
        <p style="font-size:16px; font-weight:bold;color:brown">{{ __('CSV file will be created for') + ': ' }}</p>
        <div class="grid">
            <FormInputMask fieldName="year"
                           label="Year"
                           validation="required"
                           size="3"
                           mask="9999"
                           placeholder="9999"
                           v-model:field="year" />

            <FormDropdown fieldName="month"
                          label="Month"
                          validation="required"
                          :filtering="false"
                          :options="select?.months"
                          v-model:field="month" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="createCsv">
                <i class="fa-solid fa-file-csv"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Create CSV for Intrastat') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Button from 'primevue/button';
import FormInputMask from '@crud/FormInputMask.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import { validation, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, getFromRoute, printReport } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isCreateCsvModalOpen = defineModel('isCreateCsvModalOpen')
const isLoading = ref(false);

// -----------------------------------------------------

const year = ref(new Date().getFullYear())
const month = ref((new Date().getMonth() + 1).toString())

const select = ref(null);

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['months']);
})

// -----------------------------------------------------

const cancel = () => {
    isCreateCsvModalOpen.value = false
}
// -----------------------------------------------------

const createCsv = async () => {
    const formData = { year: year.value, month: month.value }
    if (!validation(formData)) {
        return false
    }
    isLoading.value = true
    const csvResponse = await getFromRoute('csv-create-document', formData, 'post');

    props.formProps.clearSelection()
    props.formProps.getLazyData()

    printReport(csvResponse, null, false)

    isLoading.value = false
    isCreateCsvModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
