<template>
    <div>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :rowButtonAction="rowButtonAction"
                :buttonsVisibility="buttonsVisibility"
                v-model:selectedRows="selectedRows">

            <template #buttons="formProps">
                <div>
                    <Button @click="actionCreateWdtButton(formProps)"
                            style="margin:0 7px 0 25px"
                            severity="info">
                        <i class="fa-solid fa-file-word"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Create WDT document') }}
                    </Button>

                    <Button @click="actionCreateCsvButton(formProps)"
                            style="margin:0 7px 0 25px"
                            severity="success">
                        <i class="fa-solid fa-file-csv"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Create CSV for Intrastat') }}
                    </Button>
                </div>
            </template>

            <template #crud="formProps">
                <WdtForm :formProps="formProps">
                </WdtForm>
            </template>
            <template #search="searchProps">
                <WdtSearch :searchProps="searchProps">
                </WdtSearch>
            </template>
        </v-view>

        <Dialog modal
                :visible="isCreateWdtModalOpen"
                v-model:visible="isCreateWdtModalOpen"
                :header="__('Create WDT document')"
                :style="{ width: '500px' }">
            <createWdtForm :formProps="createWdtFormProps"
                           v-model:isCreateWdtModalOpen="isCreateWdtModalOpen">
            </createWdtForm>
        </Dialog>

        <Dialog modal
                :visible="isCreateCsvModalOpen"
                v-model:visible="isCreateCsvModalOpen"
                :header="__('Create CSV for Intrastat')"
                :style="{ width: '500px' }">
            <createCsvForm :formProps="createCsvFormProps"
                           v-model:isCreateCsvModalOpen="isCreateCsvModalOpen">
            </createCsvForm>
        </Dialog>

    </div>
</template>

<script setup>
import { ref } from "vue";
import WdtForm from './WdtForm.vue'
import WdtSearch from './WdtSearch.vue'
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import { __, numberFormat } from '@helper/utils.js'
import createWdtForm from './CreateWdtForm.vue'
import createCsvForm from './CreateCsvForm.vue'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    buttonsVisibility: Object,
    initialSort: Object,
})

const selectedRows = ref([])
const isCreateWdtModalOpen = ref(false)
const createWdtFormProps = ref({})

const isCreateCsvModalOpen = ref(false)
const createCsvFormProps = ref({})

//------------------------------------------------------------------------------

const actionCreateWdtButton = (formProps) => {
    createWdtFormProps.value = formProps
    isCreateWdtModalOpen.value = true
}

//------------------------------------------------------------------------------

const actionCreateCsvButton = (formProps) => {
    createCsvFormProps.value = formProps
    isCreateCsvModalOpen.value = true
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
