<template>
    <div>

        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :renderColumnValue="renderColumnValue"
                :rowButtonAction="rowButtonAction"
                v-model:selectedRows="selectedRows">

            <template #buttons>
                <div>

                    <Button @click="getNbpExchanges">
                        <i class="fa-solid fa-sack-dollar button-icon"></i>
                        Pobierz kursy</Button>
                </div>
            </template>

            <template #crud="formProps">
                <ExchangeForm :formProps="formProps">
                </ExchangeForm>
            </template>
            <template #search="searchProps">
                <ExchangeSearch :searchProps="searchProps">
                </ExchangeSearch>
            </template>

        </v-view>

        <Dialog :visible="isVisible"
                modal
                :header="currentDate"
                :style="{ width: '40rem' }"
                v-model:visible="isVisible">

            <div class="grid">
                <FormCalendar fieldName="date_from"
                              label="Od dnia"
                              size="6"
                              validation="required"
                              v-model:field="exchange.date_from" />

                <FormCalendar fieldName="
                              date_to"
                              label="Do dnia"
                              size="6"
                              validation="required"
                              v-model:field="exchange.date_to" />
            </div>

            <div class="grid">
                <div class="col-2">
                    <Button @click="getNbp"
                            severity="success">Pobierz</Button>
                </div>
                <div class="col-2">
                    <Button @click="cancelNbp"
                            severity="secondary">Anuluj</Button>
                </div>
            </div>
        </Dialog>

    </div>
</template>

<script setup>
import ExchangeForm from './ExchangeForm.vue'
import ExchangeSearch from './ExchangeSearch.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { ref } from "vue";
import axios from 'axios';
import { router } from '@inertiajs/vue3'
import { rowButtonAction, convertDate, prepareSelect } from '@pages/Helper/crud_utils.js'
import { getField } from '@pages/Helper/utils.js'
import { nbpUrl } from '@pages/Helper/constants.js'

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
})

const selectedRows = ref([])
const isVisible = ref(false)
const currentDate = new Date().toISOString().split('T')[0]
const exchange = ref({ date_from: currentDate, date_to: currentDate })

//------------------------------------------------------------------------------

const getNbpExchanges = () => {
    isVisible.value = true
}

//------------------------------------------------------------------------------

const cancelNbp = async () => {
    isVisible.value = false
}

//------------------------------------------------------------------------------

const getNbp = async () => {
    isVisible.value = false
    exchange.value.date_from = convertDate(exchange.value.date_from)
    exchange.value.date_to = convertDate(exchange.value.date_to)

    const currencyArray = await prepareSelect(['currencies']);
    let currencyValues = {}

    if (Array.isArray(currencyArray.currencies)) {
        const updatedCurrencies = await getField('settings', 'currency_list', "id='1'")
        const updatedCurrenciesArray = updatedCurrencies.split(',').map(item => item.trim())

        await Promise.all(currencyArray.currencies.map(async (currencyObj) => {
            try {
                if (updatedCurrenciesArray.includes(currencyObj.label)) {
                    const response = await axios.get(`${nbpUrl}/${currencyObj.label}/${exchange.value.date_from}/${exchange.value.date_to}?format=json`);
                    const data = response.data;
                    currencyValues = { ...currencyValues, [currencyObj.value]: data.rates };
                }
            } catch (error) {
                console.error(error);
            }
        }));
        if (Object.keys(currencyValues).length > 0) {
            router.post(route('exchange-update-nbp'), { currency: currencyValues }, { preserveState: false, preserveScroll: false });
        }
    }
}

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    switch (field) {
        case "is_update":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-pen-to-square" style="font-size:20px;"></i></span>' : "";
        default:
            return value;
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
