<template>
    <v-form :name="searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormMultiselect fieldName="events"
                                     label="Operation"
                                     size="4"
                                     showClear
                                     :isFilter="false"
                                     :isFocus="true"
                                     :options="select?.events"
                                     v-model:field="search.events" />

                    <FormMultiselect fieldName="tables"
                                     label="Area"
                                     size="4"
                                     showClear
                                     :isFocus="true"
                                     :options="select?.tables"
                                     v-model:field="search.tables" />

                    <FormInputText fieldName="causer"
                                   label="User"
                                   size="4"
                                   v-model:field="search.causer" />

                    <FormCalendar fieldName="date_from"
                                  label="Date from"
                                  size="6"
                                  v-model:field="search.created_at_from" />

                    <FormCalendar fieldName="date_to"
                                  label="Date to"
                                  size="6"
                                  v-model:field="search.created_at_to" />

                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import FormCalendar from '@crud/FormCalendar.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})

const autoSearch = ref(false)
const options = ['events', 'tables']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
