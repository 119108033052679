<template>

    <Head :title="__('Password reset')" />

    <div class="auth-container">
        <v-form name="formForget"
                class="auth-form"
                @keyup.enter="send">
            <div class="auth-top">
                <v-col class="auth-logo">
                    <Image :src="systemLogo" />
                </v-col>
            </div>

            <div class="auth-main">
                <v-col v-if="success"
                       class="auth-title">
                    {{ __('Link was send') }}
                </v-col>
                <v-col class="auth-section">
                    <label class="auth-label"
                           for="email">{{ __('E-Mail Address') }}</label>
                    <InputText type="text"
                               id="email"
                               size="small"
                               style="display:flex;width:100%"
                               @input="inputNewData"
                               :class="{ 'invalid-auth-input': form.errors.email }"
                               v-model="form.email" />
                    <div v-if="form.errors.email"
                         class="invalid-auth-form">{{ __(form.errors.email) }}</div>
                </v-col>

                <v-col class="auth-section">
                    <v-row class="justify-content-between"
                           style="margin-top:0">
                        <Button @click="send"
                                class="auth-button"
                                style="width:60%"
                                :label="__('Send Password Reset Link')"
                                icon="pi pi-sign-in" />

                        <div class="auth-link-outer">
                            <i class="pi pi-star"
                               style="font-size: 1rem; padding:10px;"></i>
                            <Link class="auth-link-inner"
                                  :href="route('app-login')"
                                  v-if="route().has('app-login')">{{ __('Login') }}</Link>
                        </div>
                    </v-row>
                </v-col>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'
import { Link } from '@inertiajs/vue3'

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import Layout from '@layouts/LayoutNoMenuNoFooter.vue'
import Image from '@helper/Image.vue'
import { __ } from '@helper/utils.js'
import { systemLogo, startSplash } from '@helper/constants.js'

defineOptions({ layout: Layout })

// -----------------------------------------------------

const success = ref(false)
const form = useForm({ email: '' })

// -----------------------------------------------------

const inputNewData = () => {
    form.errors.email = null
    success.value = false
}

// -----------------------------------------------------

const send = () => {
    form.post(route('password.email'), {
        onSuccess: () => {
            success.value = true
            form.email = ''
        },
        onError: (errors) => { console.error(errors) }
    })
}

// -----------------------------------------------------
</script>

<style lang="scss"
       scoped></style>
