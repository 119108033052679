<template>
    <div style="overflow-x: auto">
        <Dialog modal
                class="form-modal"
                :closeOnEscape="closeOnEscape"
                :maximizable="modalProps.formMaximize"
                :visible="isModalOpen"
                v-model:visible="isModalOpen"
                :contentStyle="formBodyStyle"
                :contentClass="formBodyClass"
                :style="{ width: formSize }"
                :breakpoints="breakpoints"
                :pt="{
                    header: {
                        style: formHeaderStyle,
                        class: formHeaderClass,
                    },
                    footer: {
                        style: formFooterStyle,
                        class: formFooterClass,
                    },
                }">
            <template #header>
                <span v-html="formHeader"> </span>
            </template>
            <slot v-if="!searchMode"
                  name="crud"
                  :data="formData"
                  :formName="modalProps.formName"
                  :unprotected="modalProps.unprotected"
                  :tabs="modalProps.tabs"
                  :closeModal="closeModal"
                  :editMode="editMode"
                  :addMode="addMode"
                  :routes="routes"
                  :getLazyData="getLazyData"></slot>
            <slot v-else
                  name="search"
                  :data="searchData"
                  :formName="modalProps.searchName"
                  :unprotected="modalProps.unprotected"
                  :clearSelection="clearSelection"
                  :closeModal="closeModal"
                  :getLazyData="getLazyData"></slot>

            <template #footer>
                <span v-html="formFooter"></span>
                <span v-if="!searchMode"
                      class="flex w-full justify-content-end">
                    <span>{{ formData.id }}</span>
                </span>
            </template>
        </Dialog>

        <Dialog :header="__('Settings visibility and order columns')"
                modal
                :visible="isSelectColumnsOpen"
                v-model:visible="isSelectColumnsOpen"
                :pt="{
                    footer: {
                        class: 'flex justify-content-start',
                    },
                }">
            <PickList v-model="pickColumns"
                      listStyle="height:350px;width:200px"
                      dataKey="field">
                <template #sourceheader> {{ __("Visible columns") }} </template>

                <template #targetheader> {{ __("Hidden columns") }} </template>

                <template #item="slotProps">
                    <div class="flex flex-wrap px-2 py-1 align-items-center gap-3">
                        <div class="flex align-items-center gap-2">
                            <i class="pi pi-tag text-sm"></i>
                            <span class="font-bold">{{ columnNameInConfigColumnBox(slotProps.item) }}</span>
                        </div>
                    </div>
                </template>
            </PickList>

            <template #footer>
                <div class="flex flex-wrap p-2 gap-3">
                    <Button @click="setColumnOrderVisibility"
                            severity="info">
                        {{ __("Save") }}
                    </Button>
                    <Button @click="isSelectColumnsOpen = false"
                            severity="secondary">
                        {{ __("Close") }}
                    </Button>
                </div>
            </template>
        </Dialog>

        <Dialog :header="foreignModalProps.formHeader"
                modal
                :closeOnEscape="true"
                :maximizable="foreignModalProps.formMaximize"
                :visible="isForeignDataOpen"
                v-model:visible="isForeignDataOpen"
                :style="{ width: foreignModalProps.formSize }"
                :contentStyle="foreignModalProps.formBodyStyle"
                :contentClass="foreignModalProps.formBodyClass"
                :breakpoints="foreignModalProps.formBreakpoints"
                :pt="{
                    header: {
                        style: foreignModalProps.formHeaderStyle,
                        class: foreignModalProps.formHeaderClass,
                    },
                    footer: {
                        style: foreignModalProps.formFooterStyle,
                        class: foreignModalProps.formFooterClass,
                    },
                }">

            <component :is="foreignComponent"
                       v-bind="foreignComponentProps"></component>

            <template #footer>
                <span v-html="foreignModalProps.formFooter"></span>
                <span class="flex w-full justify-content-end">
                    <span>{{ foreignComponentProps?.formProps?.data?.id }}</span>
                </span>
            </template>
        </Dialog>

        <Dialog modal
                :visible="isImportModalOpen"
                v-model:visible="isImportModalOpen"
                :header="__('Data import')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Data will be imported from Excel file') }}</p>

            <ImportForm :afterImportFunction="getLazyData"
                        :importRoute="routes.import"
                        v-model:isImportModalOpen="isImportModalOpen">
            </ImportForm>
        </Dialog>

        <ConfirmDialog header="Confirm delete"
                       question="Do you want to delete selected record?"
                       v-model="isConfirmationOpen"
                       :operation="deleteSelectedRecords"
                       :parameter="recordsToDelete" />

        <Transition name="spinner">
            <div id="cover-spin"
                 v-if="isLoading"></div>
        </Transition>

        <DataTable :value="displayData?.data"
                   :dataKey="tableProps?.dataKey"
                   :size="tableProps?.size"
                   :resizableColumns="tableProps?.resizableColumns ?? true"
                   :columnResizeMode="tableProps?.resizeMode ?? 'fit'"
                   reorderableColumns
                   showGridlines
                   stripedRows
                   lazy
                   ref="dt"
                   editMode="row"
                   @row-edit-save="onRowEditSave"
                   @row-edit-init="onRowEditInit"
                   v-model:editingRows="editingRows"
                   :style="tableProps?.tableStyle"
                   :class="tableProps?.tableClass"
                   :scrollable="tableProps?.scrollable"
                   :scrollHeight="tableProps?.scrollHeight"
                   :metaKeySelection="displayData?.data?.id"
                   @sort="onSort($event)"
                   :sortField="initialSort?.sortField ?? initialSort?.field ?? null"
                   :sortOrder="sortOrder"
                   removableSort
                   sortMode="single"
                   paginator
                   :first="first"
                   :rows="rows"
                   :totalRecords="totalRecords"
                   :rowsPerPageOptions="tableProps?.paginatorOptions?.rowsPerPageOptions"
                   :pageLinkSize="tableProps?.paginatorOptions?.pageLinkSize"
                   :alwaysShowPaginator="false"
                   @page="onPage($event)"
                   :pt="{
                    paginator: {
                        root: {
                            style: tableProps?.paginatorWrapperStyle,
                            class: tableProps?.paginatorWrapperClass,
                        },
                        pageButton: {
                            style: tableProps?.paginatorButtonStyle,
                            class: tableProps?.paginatorButtonClass,
                        },
                        firstPageButton: {
                            style: tableProps?.paginatorButtonStyle,
                            class: tableProps?.paginatorButtonClass,
                        },
                        lastPageButton: {
                            style: tableProps?.paginatorButtonStyle,
                            class: tableProps?.paginatorButtonClass,
                        },
                        nextPageButton: {
                            style: tableProps?.paginatorButtonStyle,
                            class: tableProps?.paginatorButtonClass,
                        },
                        previousPageButton: {
                            style: tableProps?.paginatorButtonStyle,
                            class: tableProps?.paginatorButtonClass,
                        },
                    },
                    footer: {
                        style: tableProps?.footerStyle,
                        class: tableProps?.footerClass,
                    },
                    header: {
                        style: tableProps?.headerStyle,
                        class: tableProps?.headerClass,
                    },
                }"
                   v-model:filters="filters"
                   @filter="onFilter($event)"
                   :selection="selectedRows"
                   @update:selection="updateSelection"
                   filterDisplay="row"
                   stateStorage="session"
                   :stateKey="stateKey"
                   :key="tableKey"
                   :rowStyle="setRowStyle"
                   :rowClass="setRowClass">
            <template #header>
                <div class="flex justify-content-between">
                    <div class="flex flex-column gap-2">
                        <div v-html="tableProps?.header"
                             :class="tableProps?.headerTitleClass"
                             :style="tableProps?.headerTitleStyle"></div>
                        <div class="flex gap-2">
                            <div v-if="!buttonsVisibility?.deny?.includes('add') && (buttonsVisibility?.allow?.includes('add') || unprotected.includes(viewName + '.add') || hasPermission([viewName, 'add']))">
                                <Button @click="headerButtonFunctions?.addForm"
                                        id="add-button"
                                        severity="primary"
                                        raised>
                                    <i class="fa-regular fa-plus button-icon"></i>
                                    {{ __("Add") }}
                                </Button>
                            </div>
                            <div v-if="!buttonsVisibility?.deny?.includes('search') && (buttonsVisibility?.allow?.includes('search') || unprotected.includes(viewName + '.search') || hasPermission([viewName, 'search']))">
                                <Button @click="headerButtonFunctions?.searchForm"
                                        id="search-button"
                                        severity="info"
                                        raised
                                        :class="{
                                            'active-search-button': isActiveSearch,
                                        }">
                                    <i :class="[
                                        {
                                            'fa-regular fa-eye': isActiveSearch,
                                            'fa-solid fa-search': !isActiveSearch,
                                        },
                                        'button-icon',
                                    ]"></i>
                                    {{ __("Search") }}
                                </Button>
                            </div>
                            <div v-if="!buttonsVisibility?.deny?.includes('delete') && (buttonsVisibility?.allow?.includes('delete') || unprotected.includes(viewName + '.delete') || hasPermission([viewName, 'delete']))">
                                <Button @click="collectRecordsToDelete"
                                        id="delete-button"
                                        severity="danger"
                                        raised>
                                    <i class="fa-regular fa-trash-can button-icon"></i>
                                    {{ __("Delete") }}
                                </Button>
                            </div>
                            <div v-if="route().has(routes.import) && !buttonsVisibility?.deny?.includes('import') && (buttonsVisibility?.allow?.includes('import') || unprotected.includes(viewName + '.import') || hasPermission([viewName, 'import']))">
                                <Button @click="actionImportButton(formProps)"
                                        style=""
                                        severity="warning">
                                    <i class="fa-solid fa-file-arrow-up"
                                       style="margin-right:5px;font-size:18px"></i>
                                    {{ __('Data import') }}
                                </Button>
                            </div>

                            <slot name="buttons"
                                  :buttonProps="displayData"
                                  :buttonsVisibility="buttonsVisibility"
                                  :getLazyData="getLazyData"
                                  :selectedRows="selectedRows"
                                  :clearSelection="clearSelection"></slot>
                        </div>
                    </div>

                    <div class="flex align-items-center">
                        <div class="pr-2">
                            <Button v-if="isActiveSearch || Object.values(filters).some((filter) => filter.value !== null)"
                                    icon="pi pi-filter-slash"
                                    severity="warning"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Remove filters')"
                                    @click="clearSearch" />
                        </div>

                        <div class="pr-2">
                            <Button v-if="!buttonsVisibility?.deny?.includes('exportdata') && (buttonsVisibility?.allow?.includes('exportdata') || exportRouteExists && (unprotected.includes(viewName + '.exportdata') || hasPermission([viewName, 'exportdata'])))"
                                    icon="pi pi-file-export"
                                    severity="info"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Data export')"
                                    @click="exportData" />
                        </div>

                        <div class="pr-2">
                            <Button icon="pi pi-refresh"
                                    severity="info"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Refresh data')"
                                    @click="refreshData" />
                        </div>

                        <div class="pr-2">
                            <Button v-if="!buttonsVisibility?.deny?.includes('columnsconfig') && (buttonsVisibility?.allow?.includes('columnsconfig') || unprotected.includes(viewName + '.columnsconfig') || hasPermission([viewName, 'columnsconfig']))"
                                    icon="pi pi-wrench"
                                    severity="info"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Columns configuration')"
                                    @click="isSelectColumnsOpen = true" />
                        </div>

                        <div class="pr-2">
                            <Button icon="pi pi-table"
                                    severity="info"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Restore table settings')"
                                    @click="resetViewSettings" />
                        </div>

                        <span v-if="tableProps?.globalFilter && !buttonsVisibility?.deny?.includes('globalsearch') && (buttonsVisibility?.allow?.includes('globalsearch') || unprotected.includes(viewName + '.globalsearch') || hasPermission([viewName, 'globalsearch']))">
                            <IconField iconPosition="left">
                                <InputIcon class="pi pi-search"> </InputIcon>
                                <InputText v-model="filters['global'].value"
                                           @keydown.enter="onFilter($event)"
                                           :placeholder="__('Search')"
                                           icon="pi pi-search"
                                           style="width: 300px" />
                            </IconField>
                        </span>
                    </div>
                </div>
            </template>

            <template>
                <Column v-if="isRowEditing && (tableProps.editColumnPlace === 'start' || tableProps.editColumnPlace === 'both')"
                        :rowEditor="true"
                        style="width:50px;min-width:50px;max-width:50px"
                        bodyStyle="text-align:center"
                        headerStyle="color:royalblue;font-size:20px;"
                        headerClass="column-action-header"
                        :frozen="tableProps.frozenControlColumn"
                        :pt="{ headerContent: { style: { 'justify-content': 'center' } } }">

                    <template #header>
                        <span class="fa-solid fa-pen-to-square"></span>
                    </template>
                </Column>

                <Column v-if="selectedColumns.length && (tableProps.selectColumnPlace === 'start' || tableProps.selectColumnPlace === 'both')"
                        selectionMode="multiple"
                        style="width: 1px !important; text-align: center"
                        :headerStyle="selectedColumns[0].headerStyle"
                        :headerClass="selectedColumns[0].headerClass"
                        :footerStyle="selectedColumns[0].footerStyle"
                        :footerClass="selectedColumns[0].footerClass"
                        :frozen="tableProps.frozenControlColumn"
                        columnKey="_selection_start">
                    <template #header>
                        <i class="fa-regular fa-square-check"
                           style="width: 1%; text-align: center; font-size: 24px"></i>
                    </template>
                </Column>

                <Column v-for="column in selectedColumns"
                        :key="column.field ?? column.name"
                        :columnKey="column.field ?? column.name"
                        :field="column.field"
                        :sortField="column.sortField"
                        :sortable="column.sortable"
                        :style="column?.field != '_actions' && column?.field != '_actions_start' ? (column.field == 'description' || column.field == 'remarks' ? { 'text-wrap': 'wrap', ...column?.columnStyle } : column?.columnStyle) : column?.columnActionStyle"
                        :class="column?.field != '_actions' && column?.field != '_actions_start' ? column.columnClass : column.columnActionClass"
                        :headerStyle="column?.field != '_actions' && column?.field != '_actions_start' ? column?.headerStyle : column?.headerActionStyle"
                        :headerClass="column?.field != '_actions' && column?.field != '_actions_start' ? column?.headerClass : column?.headerActionClass"
                        :footerStyle="column?.field != '_actions' && column?.field != '_actions_start' ? column?.footerStyle : column?.footerActionStyle"
                        :footerClass="column?.field != '_actions' && column?.field != '_actions_start' ? column?.footerClass : column?.footerActionClass"
                        :filterField="column.filter"
                        :frozen="column.frozen"
                        :filterMatchModeOptions="refreshFilterMessages ? matchModes(column?.filter_type) : null"
                        :showFilterOperator="true"
                        :pt="{
                            root: {
                                id: column?.field ?? column.name,
                            },
                            headerContent: {
                                style: column?.field != '_actions' && column?.field != '_actions_start' ? column.headerContentStyle : column.headerContentActionStyle,
                                class: column?.field != '_actions' && column?.field != '_actions_start' ? column.headerContentClass : column.headerContentActionClass,
                            },
                            bodyCell: {
                                style: column?.field != '_actions' && column?.field != '_actions_start' ? column.bodyCellStyle : column.bodyCellActionStyle,
                                class: column?.field != '_actions' && column?.field != '_actions_start' ? column.bodyCellClass : column.bodyCellActionClass,
                            },
                            columnFilter: {},
                        }
                            ">
                    <template #header>
                        <span v-html="column?.name"
                              v-tooltip.top="__(column?.tooltip)"></span>
                    </template>

                    <template v-if="column.editor"
                              #editor="{ data, field }">

                        <span v-if="column.editor_type == 'checkbox'"
                              style="display:none">
                            {{ data[field] = data[field] == 1 }}
                        </span>
                        <span v-if="column.editor_type == 'numeric'"
                              style="display:none">
                            {{ data[field] = Number(data[field]) }}
                        </span>

                        <span v-if="column.editor_type == 'dropdown'">
                            <Dropdown optionLabel="label"
                                      optionValue="value"
                                      class="w-full"
                                      :id="column.editor_field + '-' + data.id"
                                      :filter="true"
                                      :showClear="true"
                                      :options="selectOptions[column.editor_options]"
                                      v-model="data[column.editor_field]" />
                        </span>
                        <span v-else-if="column.editor_type == 'multiselect'">
                            <Multiselect optionLabel="label"
                                         optionValue="value"
                                         display="chip"
                                         class="w-full"
                                         :id="column.editor_field + '-' + data.id"
                                         :filter="true"
                                         :showToggleAll="true"
                                         :selectionLimit="column?.editor_limit ?? null"
                                         :virtualScrollerOptions="{ itemSize: 38 }"
                                         :options="selectOptions[column.editor_options]"
                                         v-model="data[column.editor_field]" />
                        </span>
                        <span v-else-if="column.editor_type == 'checkbox'">
                            <Checkbox class="w-full justify-content-around"
                                      :binary="true"
                                      v-model="data[field]" />
                        </span>
                        <span v-else-if="column.editor_type == 'numeric'">
                            <InputNumber autocomplete="off"
                                         inputClass="w-full"
                                         :inputStyle="{ 'text-align': 'right', 'min-width': '10px' }"
                                         :min="column.editor_min"
                                         :max="column.editor_max"
                                         :minFractionDigits="column.editor_min_fraction_digits ?? 2"
                                         :maxFractionDigits="column.editor_max_fraction_digits ?? 2"
                                         v-model="data[field]" />
                        </span>
                        <span v-else-if="column.editor_type == 'textarea'">
                            <Textarea :rows="column.editor_rows ?? 2"
                                      :cols="column.editor_cols ?? 30"
                                      :autoresize="true"
                                      class="w-full"
                                      v-model="data[field]" />
                        </span>
                        <span v-else-if="column.editor_type == 'editor'">
                            <Editor ref="editorRef"
                                    class="w-full"
                                    :modules="column.editor_toolbar ? configureEditorToolbar(column) : null"
                                    @load="onEditorLoad"
                                    v-model="data[field]">
                                <template #toolbar>
                                    <span class="ql-formats">
                                    </span>
                                </template>
                            </Editor>
                        </span>
                        <span v-else-if="column.editor_type == 'calendar'">
                            <Calendar class="w-full"
                                      autocomplete="off"
                                      hourFormat="24"
                                      :showIcon="column.editor_show_icon ?? true"
                                      :showTime="column.editor_time ?? false"
                                      :showOnFocus="column.editor_show_on_focus ?? true"
                                      :dateFormat="column.editor_date_format ?? 'yy-mm-dd'"
                                      :manualInput="column.editor_manual_input ?? false"
                                      v-model="data[field]" />
                        </span>
                        <span v-else>
                            <InputText class="w-full"
                                       :id="field + '-' + data.id"
                                       :name="field"
                                       v-model="data[field]" />
                        </span>
                    </template>

                    <template #body="slotProps">
                        <div :id="(slotProps?.field ?? column.type) + '-' + slotProps.index"
                             :style="setCellStyle(props, column.field, slotProps.data[column.field], slotProps.field + '-' + slotProps.index, slotProps.data)">
                            <span v-if="column?.field != '_actions' && column?.field != '_actions_start'">
                                <span v-html="renderColumnValue(column.field, slotProps.data[column.field], slotProps.data, column)"
                                      v-tooltip.top="column?.tooltip">
                                </span>

                                <span v-if="column?.foreign && column.foreign_field && !Array.isArray(column.foreign)"
                                      style="cursor: pointer; padding-left: 10px;">
                                    <a v-tooltip.top="{ value: (column && column.foreign_tooltip) ? column.foreign_tooltip : '', escape: false }"
                                       href="#"
                                       @click="event => linkToForeignData(event, column.foreign, column.foreign_component, column.foreign_props, { id: slotProps.data[column.foreign_field] })">
                                        <i :class="column.foreign_icon ?? 'fa-solid fa-external-link'"></i>
                                    </a>
                                </span>
                                <span v-if="column?.foreign && column.foreign_field && Array.isArray(column.foreign)"
                                      style="cursor: pointer; padding-left: 10px;">
                                    <span v-for="(foreign, index) in column.foreign"
                                          :key="index">
                                        <a v-tooltip="{ value: (column && column.foreign_tooltip && column.foreign_tooltip[index]) ? column.foreign_tooltip[index] : '', escape: false }"
                                           href="#"
                                           @click="event => linkToForeignData(event, foreign, column.foreign_component[index], column.foreign_props[index], { id: slotProps.data[column.foreign_field[index]] })">
                                            <i :class="column.foreign_icon[index] ?? 'fa-solid fa-external-link'"></i>
                                        </a>
                                    </span>
                                </span>

                                <span v-if="tooltipColumns && tooltipColumns[slotProps.data.id] && tooltipColumns[slotProps.data.id][column.field]"
                                      style="cursor: help; padding-left: 10px;">
                                    <i v-tooltip.top="{ value: tooltipColumns[slotProps.data.id][column.field], escape: false }"
                                       class="fa-regular fa-circle-question"
                                       style="font-size:18px; color:royalblue;"></i>
                                </span>

                            </span>
                            <span v-else>
                                <span v-if="column?.type == 'control'">
                                    <span v-for="( item, index ) in column.content"
                                          :key="index">
                                        <i v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                           v-tooltip.top="item.tooltip + ' ' + (item.event == 'showForm' ? slotProps.data['id'] : '')"
                                           :class="item.class"
                                           :style="item.style"
                                           style="cursor: pointer"
                                           @click="makeButtonAction(item, slotProps.data)">
                                        </i>
                                    </span>
                                </span>

                                <span v-else-if="column?.type == 'button'">
                                    <span v-for="( item, index ) in column.content "
                                          :key="index">
                                        <Button v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                                @click="makeButtonAction(item, slotProps.data)"
                                                :style="item.style">{{ item.name }}</Button>
                                    </span>
                                </span>

                                <span v-else-if="column?.type == 'icon'">
                                    <span v-for="( item, index ) in column.content"
                                          :key="index">
                                        <i v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                           v-tooltip.top="item.tooltip + ' ' + (item.event == 'showForm' ? slotProps.data['id'] : '')"
                                           :class="item.class"
                                           :style="item.style"
                                           style="cursor: pointer"
                                           @click="makeButtonAction(item, slotProps.data)">
                                        </i>
                                    </span>
                                </span>

                                <span v-else-if="column?.type == 'image'"
                                      class="flex">
                                    <span v-for="( item, index ) in column.content "
                                          :key="index">
                                        <Image v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                               :src="item.src"
                                               :alt="item.alt"
                                               :style="item.style"
                                               :class="item.class"
                                               v-tooltip.top="item.tooltip"
                                               style="cursor: pointer"
                                               @click="makeButtonAction(item, slotProps.data)" />
                                    </span>
                                </span>

                                <span v-else-if="column?.type == 'link'">
                                    <span v-for="( item, index ) in column.content "
                                          :key="index">
                                        <Link v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                              :href="item.href"
                                              :method="item.method"
                                              :style="item.style"
                                              :data="slotProps.data"
                                              v-tooltip.top="item.tooltip"
                                              as="button">{{ item.name }} </Link>
                                    </span>
                                </span>

                                <span v-else-if="column?.type == 'text'">
                                    <span v-for="( item, index ) in column.content "
                                          :key="index">
                                        <span v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                              :style="item.style"
                                              v-tooltip.top="item.tooltip"
                                              @click="makeButtonAction(item, slotProps.data)"
                                              style="cursor: pointer">
                                            {{ item.name }}
                                        </span>
                                    </span>
                                </span>

                                <span v-else-if="column?.type == 'html'">
                                    <span v-for="( item, index ) in column.content "
                                          :key="index">
                                        <span v-if="hasButtonPermission(item, slotProps) && !buttonsVisibility?.deny?.includes(item?.id) && (buttonsVisibility?.allow?.includes(item?.id) || unprotected.includes(viewName + '.' + item?.id) || hasPermission([viewName, item?.id]))"
                                              :style="item.style"
                                              @click="makeButtonAction(item, slotProps.data)"
                                              v-html="item.name"
                                              v-tooltip.top="item.tooltip"
                                              style="cursor: pointer">
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </template>
                    <template v-if="column.filter ?? null"
                              #filter="{ filterModel, filterCallback }">
                        <FilterType :filterModel="filterModel"
                                    :filterCallback="filterCallback"
                                    :filterType="column?.filter_type"
                                    :filterOptions="column?.filter_options"
                                    :filterMode="column?.filter_mode"
                                    :filterProps="column?.filter_props"
                                    :filterStyle="column?.filter_style"
                                    :filterClass="column?.filter_class"
                                    style="padding: 5px 2px" />
                    </template>

                    <template v-if="column?.footer != null"
                              #footer>
                        <span v-if="column?.field != '_actions' && column?.field != '_actions_start'"
                              v-html="column?.footer"></span>
                    </template>
                </Column>
                <Column v-if="isRowEditing && (tableProps.editColumnPlace === 'end' || tableProps.editColumnPlace === 'both')"
                        :rowEditor="true"
                        style="width:50px;min-width:50px;max-width:50px"
                        bodyStyle="text-align:center"
                        headerStyle="color:royalblue;font-size:20px;"
                        headerClass="column-action-header"
                        :pt="{ headerContent: { style: { 'justify-content': 'center' } } }">

                    <template #header>
                        <span class="fa-solid fa-pen-to-square"></span>
                    </template>
                </Column>

                <Column v-if="selectedColumns.length && (tableProps.selectColumnPlace === 'end' || tableProps.selectColumnPlace === 'both')"
                        selectionMode="multiple"
                        style="width: 1px !important; text-align: center"
                        :headerStyle="selectedColumns[0].headerStyle"
                        :headerClass="selectedColumns[0].headerClass"
                        :footerStyle="selectedColumns[0].footerStyle"
                        :footerClass="selectedColumns[0].footerClass"
                        columnKey="_selection">
                    <template #header>
                        <i class="fa-regular fa-square-check"
                           style="width: 1%; text-align: center; font-size: 24px"></i>
                    </template>
                </Column>
            </template>

            <template #footer>
                <div v-html="tableProps?.footer"
                     :class="tableProps?.footerTitleClass"
                     :style="tableProps?.footerTitleStyle"></div>
            </template>
        </DataTable>
    </div>
    <!-- <flash-message /> -->

</template>

<script setup>
import { ref, onMounted, onBeforeMount, shallowRef, nextTick, toRaw } from "vue";
import { router } from "@inertiajs/vue3";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { Link } from "@inertiajs/inertia-vue3";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Multiselect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import Editor from "primevue/editor";
import Calendar from "primevue/calendar";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import FilterType from "@pages/Helper/FilterType.vue";
import { matchModes, setRowAttrib, setCellStyle } from "@pages/Helper/crud_utils.js";
import Dialog from "primevue/dialog";
import PickList from "primevue/picklist";
import ConfirmDialog from "@pages/Crud/ConfirmDialog.vue";
import ImportForm from '@pages/Crud/ImportForm.vue'
import { __, translatePrimeVueMsgs, hasPermission, currentDate, currentTime, getAppName } from "@helper/utils.js";
import Image from "@helper/Image.vue";
import axios from "axios";
import interact from "interactjs";
import { loadDynamicComponent } from "@helper/dynamic.js";

translatePrimeVueMsgs();

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    tooltipColumns: Object,
    routes: Object,
    initialSort: Object,
    renderColumnValue: Function,
    rowButtonAction: Function,
    afterLoadData: Function,
    rowButtonFunctions: Object,
    buttonsVisibility: {
        type: Object,
        default: () => ({
            'allow': [],
            'deny': [],
        }),
    },
});

const selectedRows = defineModel("selectedRows");

const updateSelection = (event) => {
    selectedRows.value = event;
};

const viewName = props.tableProps.viewName;
const unprotected = props.tableProps.unprotected ?? [];
const displayData = ref(null);
const dt = ref(null);
const isModalOpen = ref(false);
const isConfirmationOpen = ref(false);
const editMode = ref(false);
const addMode = ref(false);
const searchMode = ref(false);
const formData = ref({});
const searchData = ref({});
const lazyParams = ref({});
const totalRecords = ref(props.tableData?.total);
const recordsToDelete = ref([]);
const first = ref(props.tableData?.from);
const rows = ref(props.tableData?.per_page);
const filters = ref({ global: { value: null } });
const isLoading = ref(true);
const stateKey = ref(getAppName() + "-" + props.routes.index);
const tableKey = ref(0);
const sortOrder = ref(1);
const isActiveSearch = ref(false);
const closeOnEscape = ref(false);
const formSize = ref(props.modalProps?.formSize ?? "50%");
const formHeader = ref(props.modalProps?.formHeader ?? null);
const formFooter = ref(props.modalProps?.formFooter ?? null);
const formHeaderStyle = ref(props.modalProps?.modalHeaderStyle ?? null);
const formHeaderClass = ref(props.modalProps?.modalHeaderClass ?? null);
const formFooterStyle = ref(props.modalProps?.modalFooterStyle ?? null);
const formFooterClass = ref(props.modalProps?.modalFooterClass ?? null);
const formBodyStyle = ref(props.modalProps?.modalBodyStyle ?? null);
const formBodyClass = ref(props.modalProps?.modalBodyClass ?? null);
const breakpoints = ref(props.modalProps?.breakpoints ?? null);

const appName = getAppName();
const isSelectColumnsOpen = ref(false);
const selectedColumns = ref(null);
const pickColumns = ref(null);
const exportRouteExists = ref(false)

const isForeignDataOpen = ref(false);
const foreignComponent = shallowRef(null);
const foreignComponentProps = ref(null);
const foreignModalProps = ref({});
const refreshFilterMessages = ref(false);

const isRowEditing = ref(false);
const editingRows = ref(null);
const selectOptions = ref([{}])
const editorRef = ref(null);

const isImportModalOpen = ref(false)

//------------------------------------------------------------------------------

onBeforeMount(() => {
    onBeforeMountView()
});

//------------------------------------------------------------------------------

onMounted(() => {
    onMountedView();
    refreshFilterMessages.value = true;
    interactService()
});

//------------------------------------------------------------------------------

const onBeforeMountView = () => {
    const alreadyHiddenColumns = JSON.parse(sessionStorage.getItem(appName + "-" + props.routes.index + "-hidden-columns")) ?? [];
    const savedTableSetting = JSON.parse(sessionStorage.getItem(appName + "-" + props.routes.index)) ?? null;

    if (props.initialSort) {
        sortOrder.value = props.initialSort?.order == "asc" ? 1 : -1;
    } else {
        sortOrder.value = null;
    }

    props.columns?.forEach((item) => {
        filters.value[item.field] = { value: null };
    });

    selectedColumns.value = props.columns.filter((item) => {
        return !alreadyHiddenColumns.some((column) => column.field === item.field);
    });

    selectedColumns.value.some((column) => {
        if (column?.editor == true) {
            isRowEditing.value = true
        }
    })

    if (savedTableSetting) {
        selectedColumns.value.sort((a, b) => {
            const indexA = savedTableSetting?.columnOrder?.indexOf(a.field) ?? null;
            const indexB = savedTableSetting?.columnOrder?.indexOf(b.field) ?? null;
            return indexA - indexB;
        });
    }

    pickColumns.value = [selectedColumns.value, alreadyHiddenColumns];
    exportRouteExists.value = route().has(props.routes.export)
}

//------------------------------------------------------------------------------

const onMountedView = () => {
    const fromSession = JSON.parse(sessionStorage.getItem(appName + "-" + props.routes.index)) ?? null;
    //const customLinkData = JSON.parse(sessionStorage.getItem(appName + "-" + props.routes.index + '-custom-link-data')) ?? null;

    isLoading.value = true;
    lazyParams.value.filters = filters.value;
    lazyParams.value = {
        ...lazyParams.value,
        first: props.tableData?.per_page,
        page: 1,
        rows: fromSession?.rows ?? rows.value,
        sortField: fromSession?.sortField ?? null,
        sortOrder: fromSession?.sortOrder ?? null,
        //customLinkData: customLinkData ?? null,
    };
    getLazyData();
}
//------------------------------------------------------------------------------

const onPage = (event) => {
    lazyParams.value = {
        ...lazyParams.value,
        ...event,
        first: event.first + event.rows,
        page: event.page + 1,
        rows: event.rows,
    };
    getLazyData();
};

//------------------------------------------------------------------------------

const onSort = (event) => {
    lazyParams.value = {
        ...lazyParams.value,
        ...event,
        first: props.tableData?.per_page,
        page: 1,
    };
    getLazyData();
};

//------------------------------------------------------------------------------

const onFilter = (event) => {
    clearSelection();
    lazyParams.value = {
        ...lazyParams.value,
        ...event,
        filters: filters.value,
        page: 1,
    };
    getLazyData();

    for (const field in filters.value) {
        const button = document.querySelector('#' + field + " button.p-column-filter-menu-button")
        if (button) {
            if (filters.value[field].matchMode) {
                button.style.backgroundColor = "firebrick";
            } else {
                button.style.backgroundColor = "transparent";
            }
        }
    }
};

//------------------------------------------------------------------------------

const getLazyData = (event = {}) => {
    isLoading.value = true;
    isActiveSearch.value = false;
    router.post(
        route(props.routes.index),
        { ...lazyParams.value, ...event },
        {
            preserveState: true,
            preserveScroll: true,
            onSuccess: (data) => {
                if (Array.isArray(data.props?.tableData?.data) && data.props.tableData.data.length > 0 && data.props.tableData.data[0].id == undefined) {
                    console.warn('Nie przekazano ID rekordów z funkcji dataSource. Nie będzie można pobrać rekordu do formularza')
                }
                totalRecords.value = data.props.tableData?.total;
                first.value = data.props.tableData?.from;
                rows.value = data.props.tableData?.per_page;
                displayData.value = data.props.tableData;
                isLoading.value = false;
                if (data.props?.tableProps?.searchFields) {
                    isActiveSearch.value = true;
                }
                if (props.afterLoadData) {
                    props.afterLoadData(data);
                }
            },
            onError: (error) => {
                isLoading.value = false;
                console.error(error);
            },
        }
    );
};

//------------------------------------------------------------------------------

const setRowStyle = (data) => {
    return setRowAttrib(props.tableProps.rowConditions, data, "style");
};

//------------------------------------------------------------------------------

const setRowClass = (data) => {
    const isSelected = selectedRows.value?.some(row => {
        const rawRow = toRaw(row);
        return rawRow.id === data.id;
    });

    if (isSelected) {
        return { 'view-selected-row': true };
    }

    return setRowAttrib(props.tableProps.rowConditions, data, "class");
};

//------------------------------------------------------------------------------

const resetViewSettings = () => {
    sessionStorage.removeItem(stateKey.value);
    tableKey.value++;
    for (let key in filters.value) {
        filters.value[key].value = null;
        filters.value[key].matchMode = null;
    }
    sessionStorage.removeItem(appName + "-" + props.routes.index + "-hidden-columns");
    selectedColumns.value = props.columns;
    pickColumns.value = [selectedColumns.value, []];
    getLazyData({ page: 1, search: {} });
};

//------------------------------------------------------------------------------

const setColumnOrderVisibility = () => {
    const hiddenColumns = [];
    const selectedToVisibleColumns = pickColumns.value[0];
    const selectedToHideColumns = pickColumns.value[1];
    const savedTableSetting = JSON.parse(sessionStorage.getItem(appName + "-" + props.routes.index)) ?? null;

    if (selectedToVisibleColumns.length === 0 && selectedToHideColumns.length > 0) {
        const columnToKeepVisible = selectedToHideColumns.pop();
        selectedToVisibleColumns.push(columnToKeepVisible);
    }

    selectedToHideColumns.forEach((item) => {
        hiddenColumns.push({ name: item.name, field: item.field });
    });
    sessionStorage.setItem(appName + "-" + props.routes.index + "-hidden-columns", JSON.stringify(hiddenColumns));

    savedTableSetting.columnWidths = null

    if (savedTableSetting) {
        savedTableSetting.columnOrder = selectedToVisibleColumns.map((item) => item.field);
        if (props.tableProps.selectColumnPlace === 'start' || props.tableProps.selectColumnPlace === 'both') {
            savedTableSetting.columnOrder.unshift('_selection_start');
        }
        sessionStorage.setItem(appName + "-" + props.routes.index, JSON.stringify(savedTableSetting));
    }

    selectedColumns.value = selectedToVisibleColumns ?? [];

    tableKey.value++;
};

//------------------------------------------------------------------------------

const columnNameInConfigColumnBox = (item) => {
    const columnName = item.name.replace(/<[^>]*>/g, "")
    if (columnName == '') {
        return item.tooltip.replace(/<[^>]*>/g, "")
    }
    return columnName;
};

//------------------------------------------------------------------------------

const hasButtonPermission = (item, column) => {
    if (column.data[item.id] === false) {
        return false
    }
    return true
}

//------------------------------------------------------------------------------

const makeButtonAction = async (arrButton, data) => {
    let selectedFields = data
    if (arrButton?.fields) {
        if (!Array.isArray(arrButton.fields)) {
            arrButton.fields = arrButton.fields.split(',')
        }
        selectedFields = { 'id': data.id, 'dataOrigin': 'link' }
        arrButton.fields.forEach((field) => {
            selectedFields[field] = data[field]
        })
    }

    if (arrButton.event) {
        let func = arrButton.event;
        if (rowButtonFunctions.hasOwnProperty(func)) {
            rowButtonFunctions[func](selectedFields);
        } else {
            rowButtonFunctions.rowButtonAction(arrButton, selectedFields);
        }
    } else {
        // sessionStorage.setItem(appName + "-" + arrButton.route + '-custom-link-data', JSON.stringify(selectedFields));
        if (arrButton.plainData === true) {
            await fetch(route(arrButton.route, { ...selectedFields }), {
                method: arrButton.method ?? "post",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-TOKEN": document.querySelector('meta[name="csrf-token"]')?.getAttribute("content"),
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    rowButtonFunctions.rowButtonAction(arrButton, data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            router.visit(route(arrButton.route), {
                method: arrButton.method ?? "post",
                preserveState: false,
                preserveScroll: true,
                data: { ...selectedFields },
                onSuccess: (backendData) => { },
                onError: (error) => {
                    console.error("Error:", error);
                },
            });
        }
    }
};

//------------------------------------------------------------------------------

const openModal = () => {
    isModalOpen.value = true;
};

//------------------------------------------------------------------------------

const closeModal = () => {
    isModalOpen.value = false;
};

//------------------------------------------------------------------------------

const clearSelection = () => {
    selectedRows.value = [];
};

//------------------------------------------------------------------------------

const clearSearch = () => {
    clearSelection();
    for (const field in filters.value) {
        filters.value[field].value = null;
        const button = document.querySelector('#' + field + " button.p-column-filter-menu-button-active")
        if (button) {
            button.style.backgroundColor = "transparent";
        }
    }
    getLazyData({ page: 1, search: {} });
};

//------------------------------------------------------------------------------

const refreshData = () => {
    clearSelection();
    onBeforeMountView()
    onMountedView()
    getLazyData();
};

//------------------------------------------------------------------------------

const exportData = async () => {
    isLoading.value = true

    if (!selectedRows.value) {
        selectedRows.value = []
    }

    const response = await axios.post(route(props.routes.export),
        { ...lazyParams.value, selectedRows: selectedRows.value.map((item) => item?.id ?? item) },
        {
            responseType: 'blob',
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            },
        }
    );
    isLoading.value = false
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    let fileName = 'eksport_';
    if (props.tableProps?.header?.length > 0) {
        const headerText = props.tableProps.header.replace(/<[^>]*>/g, "");
        fileName += headerText.toLowerCase() + '_' + currentDate() + '_' + currentTime('-') + '.xlsx';
    } else {
        fileName += currentDate() + '_' + currentTime('-') + '.xlsx';
    }

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

//------------------------------------------------------------------------------

const importModalControl = {
    open: () => isImportModalOpen.value = true,
    close: () => isImportModalOpen.value = false,
}

//------------------------------------------------------------------------------

const actionImportButton = (formProps) => {
    importModalControl.open()
}

//------------------------------------------------------------------------------

const renderColumnValue = (field, value, rowData = null, column = null) => {
    if (field.includes('.')) {
        value = field.split('.').reduce((acc, part) => {
            if (acc[part]) {
                return acc[part];
            }
            return '';
        }, rowData);
    }

    if (props.renderColumnValue) {
        value = props.renderColumnValue(field, value, rowData, column);
    }

    return value;
};

//------------------------------------------------------------------------------

const linkToForeignData = async (event, foreign, foreignComp, foreignProps, data) => {
    event.preventDefault();

    foreignModalProps.value = foreignProps;
    foreignComponentProps.value = {
        formProps: {
            formName: foreignProps.formName,
            tabsDeny: foreignProps.tabsDeny,
            editMode: false,
            addMode: false,
            data: data,
            routes: {
                record: foreign,
            },
            closeModal: () => {
                isForeignDataOpen.value = false;
            },
            getLazyData: getLazyData,
        },
        buttonsVisibility: { deny: ['edit'] },
    };

    const component = await loadDynamicComponent(foreignComp);

    if (!component) {
        console.error('Sprawdź czy nazwa komponentu w pliku z definicją kolumn jest obsługiwana w pliku dynamic.js')
        return
    }

    //foreignComponent.value = await loadComponent(column.foreign_component);
    //foreignComponent.value = component.default;

    foreignComponent.value = component;
    isForeignDataOpen.value = true;
};

//------------------------------------------------------------------------------

// const linkToForeignData2 = async (event, column, data) => {
//     event.preventDefault();

//     foreignModalProps.value = column.foreign_props;
//     foreignComponentProps.value = {
//         formProps: {
//             formName: column.foreign_props.formName,
//             tabsDeny: column.foreign_props.tabsDeny,
//             editMode: false,
//             addMode: false,
//             data: data,
//             routes: {
//                 record: column.foreign,
//             },
//             closeModal: () => {
//                 isForeignDataOpen.value = false;
//             },
//             getLazyData: getLazyData,
//         },
//         buttons: { deny: ['edit'] },
//     };

//     const component = await loadDynamicComponent(column.foreign_component);

//     if (!component) {
//         console.error('Sprawdź czy nazwa komponentu w pliku z definicją kolumn jest obsługiwana w pliku dynamic.js')
//         return
//     }

//     //foreignComponent.value = await loadComponent(column.foreign_component);
//     //foreignComponent.value = component.default;

//     foreignComponent.value = component;
//     isForeignDataOpen.value = true;
// };

//------------------------------------------------------------------------------

const rowButtonFunctions = {
    showForm: (data) => {
        editMode.value = false;
        addMode.value = false;
        rowButtonFunctions.commonSettings(data);
    },
    editForm: (data) => {
        editMode.value = true;
        addMode.value = false;
        rowButtonFunctions.commonSettings(data);
    },
    deleteForm: (data) => {
        recordsToDelete.value = [data.id];
        isConfirmationOpen.value = true;
    },
    commonSettings: (data) => {
        formData.value = Object.assign({}, data);
        searchMode.value = false;
        closeOnEscape.value = (editMode.value == true || addMode.value == true) ? (props?.modalProps?.closeOnEscape ?? false) : true;
        formSize.value = props?.modalProps?.formSize ?? "50%";
        formHeader.value = props?.modalProps?.formHeader ?? null;
        formFooter.value = props?.modalProps?.formFooter ?? null;
        formHeaderStyle.value = props?.modalProps?.formHeaderStyle ?? null;
        formHeaderClass.value = props?.modalProps?.formHeaderClass ?? null;
        formFooterStyle.value = props?.modalProps?.formFooterStyle ?? null;
        formFooterClass.value = props?.modalProps?.formFooterClass ?? null;
        formBodyStyle.value = props?.modalProps?.formBodyStyle ?? null;
        formBodyClass.value = props?.modalProps?.formBodyClass ?? null;
        openModal();
    },
    rowButtonAction: (arrButton, data) => {
        if (props?.rowButtonAction) {
            return props.rowButtonAction(arrButton, data, props?.rowButtonFunctions);
        }

        return data;
    },
};

//------------------------------------------------------------------------------

const headerButtonFunctions = {
    addForm: (data) => {
        editMode.value = false;
        addMode.value = true;
        formData.value = {};
        rowButtonFunctions.commonSettings(formData.value);
    },

    searchForm: () => {
        searchData.value = props.tableProps.searchFields;
        editMode.value = true;
        searchMode.value = true;
        addMode.value = false;
        closeOnEscape.value = true;
        formSize.value = props?.modalProps?.searchSize ?? props?.modalProps?.formSize ?? "50%";
        formHeader.value = props?.modalProps?.searchHeader ?? null;
        formFooter.value = props?.modalProps?.searchFooter ?? null;
        formHeaderStyle.value = props?.modalProps?.searchHeaderStyle ?? props?.modalProps?.formHeaderStyle ?? null;
        formHeaderClass.value = props?.modalProps?.searchHeaderClass ?? props?.modalProps?.formHeaderClass ?? null;
        formFooterStyle.value = props?.modalProps?.searchFooterStyle ?? props?.modalProps?.formFooterStyle ?? null;
        formFooterClass.value = props?.modalProps?.searchFooterClass ?? props?.modalProps?.formFooterClass ?? null;
        formBodyStyle.value = props?.modalProps?.searchBodyStyle ?? props?.modalProps?.formBodyStyle ?? null;
        formBodyClass.value = props?.modalProps?.searchBodyClass ?? props?.modalProps?.formBodyClass ?? null;
        openModal();
    },
};

//------------------------------------------------------------------------------

const collectRecordsToDelete = () => {
    recordsToDelete.value = selectedRows.value.map((item) => item.id);
    isConfirmationOpen.value = true;
};

//------------------------------------------------------------------------------

const deleteSelectedRecords = (arrIds) => {
    isConfirmationOpen.value = false;
    router.post(
        route(props.routes.delete),
        { selectedToDelete: arrIds },
        {
            preserveState: true,
            preserveScroll: true,
            onError: (errors) => {
                console.log(errors);
            },
            onFinish: () => {
                recordsToDelete.value = [];
                selectedRows.value = [];
                getLazyData();
            },
        }
    );
};

//------------------------------------------------------------------------------

const searchItems = async (event, select) => {
    await axios.post(route("helper-select", { select: [select], filter: '%', props: {} }))
        .then((response) => {
            selectOptions.value[select] = response.data[select];
        });
}

//------------------------------------------------------------------------------

const onRowEditSave = async (event) => {
    let { newData, index } = event;
    let data = { ...newData, '_inline': true }
    if (data.id) {
        document.querySelectorAll('.row-error-message').forEach((item) => {
            item.remove();
        });
        document.querySelectorAll('.row-error-object').forEach((item) => {
            item.classList.remove('row-error-object');
        });

        router.post(route(props.routes.update, { id: data.id }), data, {
            preserveState: true,
            preserveScroll: true,
            onSuccess: (data) => {
                getLazyData();
            },
            onError: (error) => {
                editingRows.value = [...editingRows.value, event.data]
                nextTick().then(() => {
                    for (let key in error) {
                        let selector = document.querySelector('#' + key + '-' + data.id)
                        if (selector) {
                            let errorDiv = document.createElement('div');
                            selector.classList.add('row-error-object');
                            errorDiv.classList.add('row-error-message');
                            errorDiv.appendChild(document.createTextNode(error[key]));
                            selector.after(errorDiv);
                        }
                    }
                })
            },
        });
    }
};

//------------------------------------------------------------------------------

const onRowEditInit = (event) => {
    selectedColumns.value.forEach((column) => {
        if (column.editor) {
            if (column.editor_type == 'dropdown' || column.editor_type == 'multiselect') {
                searchItems(event, column.editor_options)
            }
        }
    })
};

//------------------------------------------------------------------------------

const onEditorLoad = (() => {
    const toolbar = editorRef.value.$el
    if (toolbar) {
        tmpRef.value = true;
    }
});

//------------------------------------------------------------------------------

const configureEditorToolbar = (column) => {
    switch (column?.editor_toolbar_size) {
        case 'small':
            return {
                toolbar: [
                    ['bold', 'italic', 'underline', { 'color': [] }],
                    ['clean',],
                ]
            }
        case 'medium':
            return {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike', { 'color': [] }],
                    [{ 'align': [] }],
                    ['clean',],
                ]
            }
        case 'large':
            return {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike', { 'color': [] }],
                    [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                    ['clean',],
                ]
            }
        default:
            return {
                toolbar: [
                    ['bold', 'italic', 'underline'],
                ]
            }
    }
}

//------------------------------------------------------------------------------

const interactService = () => {
    interact('.form-modal')
        .resizable({
            edges: { left: true, right: true },
            listeners: {
                move(event) {
                    let { x, y } = event.target.dataset;

                    x = (parseFloat(x) || 0) + event.deltaRect.left;
                    y = (parseFloat(y) || 0) + event.deltaRect.top;

                    Object.assign(event.target.style, {
                        width: `${event.rect.width}px`,
                        transformOrigin: 'center',
                    });
                    Object.assign(event.target.dataset, { x, y });
                }
            }
        });
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped>
:deep(.p-dialog-content) {
    overflow-x: hidden;
}

:deep(.p-paginator-bottom) {
    position: relative;
    z-index: 3
}

:deep(.p-row-editor-save) {
    margin-right: 1px !important;
    width: 15px !important;
}

:deep(.p-row-editor-cancel) {
    margin-left: 1px !important;
    width: 15px !important;
}
</style>
