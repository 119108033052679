<template>

    <Head :title="__('Reset Password')" />
    <div class="auth-container">
        <v-form name="formReset"
                class="auth-form"
                @keyup.enter="update">
            <div class="auth-top">
                <v-col class="auth-logo">
                    <Image :src="systemLogo" />
                </v-col>
            </div>

            <div class="auth-main">
                <v-col class="auth-section">
                    <ul v-if="props.rules"
                        class="password-rules">
                        <li v-for="(rule, index) in props.rules"
                            :key="index">
                            {{ rule }}
                        </li>
                    </ul>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="email">{{ __('E-Mail Address') }}</label>
                    <InputText type="text"
                               id="email"
                               size="small"
                               style="display:flex;width:100%"
                               @input="inputNewData"
                               :class="{ 'invalid-auth-input': form.errors.email }"
                               v-model="form.email" />
                    <div v-if="form.errors.email"
                         class="invalid-auth-form">{{ __(form.errors.email) }}</div>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="password">{{ __('Password') }}</label>
                    <inputText type="password"
                               id="password"
                               size="small"
                               v-model="form.password"
                               :class="{ 'invalid-auth-input': form.errors.password }"
                               @input="form.errors.password = null" />
                    <div v-if="form.errors.password"
                         class="invalid-auth-form">{{ __(form.errors.password) }}</div>
                </v-col>

                <v-col class="auth-section">
                    <label class="auth-label"
                           for="password">{{ __('Confirm password') }}</label>
                    <inputText type="password"
                               id="password_confirmation"
                               size="small"
                               v-model="form.password_confirmation" />
                </v-col>

                <v-col class="auth-section">
                    <v-row class="justify-content-between">
                        <Button @click="update"
                                class="auth-button"
                                style="width:150px"
                                :label="__('Reset password')"
                                icon="pi pi-sign-in" />

                        <div class="auth-link-outer">
                            <i class="pi pi-star"
                               style="font-size: 1rem; padding:10px;"></i>
                            <Link class="auth-link-inner"
                                  :href="route('app-login')"
                                  v-if="route().has('app-login')">{{ __('Login') }}</Link>
                        </div>
                    </v-row>
                </v-col>
                <input type="hidden"
                       id="token"
                       name="token"
                       v-model="form.token" />
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'
import { Link } from '@inertiajs/vue3'

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import Layout from '@layouts/LayoutNoMenuNoFooter.vue'
import Image from '@helper/Image.vue'
import { __ } from '@helper/utils.js'
import { systemLogo, startSplash } from '@helper/constants.js'

defineOptions({ layout: Layout })

const props = defineProps({
    token: String,
    email: String,
    rules: Object,
})

// -----------------------------------------------------

const success = ref(false)
const form = useForm({ email: props.email, password: '', password_confirmation: '', token: props.token })

// -----------------------------------------------------

const inputNewData = () => {
    form.errors.email = null
    success.value = false
}

// -----------------------------------------------------

const update = () => {
    form.post(route('password.update'), {
        onSuccess: () => {
            success.value = true
            form.email = ''
        },
        onError: (err) => {
            console.error(err)
        },
    })
}

// -----------------------------------------------------
</script>

<style lang="scss"
       scoped></style>
