<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="product_name"
                                   label="Product name"
                                   validation="required"
                                   size="9"
                                   :isFocus="true"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.product_name" />

                    <FormDropdown fieldName="carton_id"
                                  label="Courier nomenclature"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.cartons"
                                  :filter="(event) => onFilter(event, 'cartons')"
                                  v-model:field="crud.carton_id" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="vendor_id"
                                  label="Vendor"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.vendors"
                                  :filter="(event) => onFilter(event, 'vendors')"
                                  v-model:field="crud.vendor_id" />

                    <FormDropdown fieldName="productgroup_id"
                                  label="Product group"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.productgroups"
                                  :filter="(event) => onFilter(event, 'productgroups')"
                                  v-model:field="crud.productgroup_id" />

                    <FormDropdown fieldName="unit_id"
                                  label="Unit"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.units"
                                  :filter="(event) => onFilter(event, 'units')"
                                  v-model:field="crud.unit_id" />

                    <FormDropdown fieldName="warehouse_id"
                                  label="Warehouse"
                                  size="3"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.warehouses"
                                  :filter="(event) => onFilter(event, 'warehouses')"
                                  v-model:field="crud.warehouse_id" />
                </div>
                <div class="grid">
                    <FormInputMask fieldName="ean_code"
                                   label="EAN code"
                                   size="2"
                                   validation="required"
                                   mask="9999999999999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.ean_code" />

                    <FormInputMask fieldName="ean_additional_code"
                                   label="EAN additional code"
                                   size="2"
                                   mask="9999999999999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.ean_additional_code" />

                    <FormDropdown fieldName="dutycode_id"
                                  label="Duty code"
                                  size="4"
                                  showClear
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.dutycodes"
                                  :filter="(event) => onFilter(event, 'dutycodes')"
                                  v-model:field="crud.dutycode_id" />

                    <FormInputText fieldName="product_identity"
                                   label="Product ID"
                                   size="2"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.product_identity" />

                    <FormInputText fieldName="variant_identity"
                                   label="Variant ID"
                                   size="2"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.variant_identity" />
                </div>
                <div class="grid">
                    <FormInputText fieldName="vendor_product_name"
                                   label="Vendor product name"
                                   size="10"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.vendor_product_name" />

                    <FormInputMask fieldName="ean_vendor_code"
                                   label="EAN vendor code"
                                   size="2"
                                   mask="9999999999999"
                                   :isFormDisabled="isFormDisabled"
                                   v-model:field="crud.ean_vendor_code" />
                </div>
                <div class="grid">
                    <FormEditor fieldName="description"
                                label="Description"
                                :editorStyle="{ height: '120px' }"
                                :isFormDisabled="isFormDisabled"
                                v-model:field="crud.description" />

                </div>
            </TabPanel>
            <TabPanel :header="__('Parameters/Quotes')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormRadioButton fieldName="price_calculation_type"
                                     label="Method of input price"
                                     size="2"
                                     default="M"
                                     :data="calcTypes"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.price_calculation_type" />

                    <FormInputNumber v-if="crud?.price_calculation_type == 'C'"
                                     fieldName="net_unit_price"
                                     label="Net unit price"
                                     size="3"
                                     suffix=" PLN"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.net_unit_price" />
                    <FormInputNumber v-if="crud?.price_calculation_type == 'C'"
                                     fieldName="quantity"
                                     label="Quantity"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="0"
                                     :maxFractionDigits="0"
                                     v-model:field="crud.quantity" />

                    <FormInputNumber fieldName="net_buy_price"
                                     label="Net buy price"
                                     size="3"
                                     suffix=" PLN"
                                     :isFormDisabled="isFormDisabled || (crud?.price_calculation_type == 'C')"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.net_buy_price" />
                </div>
                <div class="grid">
                    <FormInputNumber fieldName="length"
                                     label="Length"
                                     size="2"
                                     suffix=" cm"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.length" />

                    <FormInputNumber fieldName="width"
                                     label="Width"
                                     size="2"
                                     suffix=" cm"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.width" />

                    <FormInputNumber fieldName="height"
                                     label="Height"
                                     size="2"
                                     suffix=" cm"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.height" />

                    <FormInputNumber fieldName="real_weight"
                                     label="Real weight"
                                     size="3"
                                     suffix=" kg"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.real_weight" />

                    <FormInputSwitch fieldName="is_custom"
                                     label="Is custom product?"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     v-model:field="crud.is_custom" />

                </div>
                <div class="grid">
                    <FormInputNumber fieldName="import_costs"
                                     label="Net import costs"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.import_costs" />

                    <FormInputNumber fieldName="additional_costs"
                                     label="Net additional costs"
                                     size="3"
                                     :isFormDisabled="isFormDisabled"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.additional_costs" />
                    <FormTextarea fieldName="additional_costs_remarks"
                                  label="Additional costs remarks"
                                  size="3"
                                  :rows="2"
                                  :isFormDisabled="isFormDisabled"
                                  v-model:field="crud.additional_costs_remarks" />

                    <FormDropdown fieldName="currency_id"
                                  label="Currency"
                                  size="3"
                                  showClear
                                  validation="required"
                                  :filtering="false"
                                  :isFormDisabled="isFormDisabled"
                                  :options="select?.currencies"
                                  v-model:field="crud.currency_id" />
                </div>
            </TabPanel>

            <TabPanel v-if="!tabsDeny('changelog')"
                      :header="__('Changelog')"
                      headerClass="form-tab-header">
                <ChangeLog :log="crud?.log" />
            </TabPanel>
        </TabView>

        <div class="grid">
            <FormButtons :formProps="props.formProps"
                         :sourceForm="crud"
                         :validation="validation"
                         :beforeSave="beforeSave"
                         :buttonsVisibility="buttonsVisibility"
                         v-model:isFormDisabled="isFormDisabled">
            </FormButtons>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted, watchEffect } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputSwitch from '@crud/FormInputSwitch.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormInputMask from '@crud/FormInputMask.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import FormEditor from '@crud/FormEditor.vue'
import FormTextarea from '@crud/FormTextarea.vue'
import FormButtons from '@crud/FormButtons.vue'
import FormRadioButton from '@crud/FormRadioButton.vue'
import ChangeLog from '@crud/ChangeLog.vue'
import { validation, onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import { __, getField } from '@helper/utils.js'


// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
    buttonsVisibility: Object,
})

const isLoading = ref(true);
const isFormDisabled = ref(false)

const options = ['vendors', 'productgroups', 'units', 'currencies', 'warehouses', 'cartons', 'dutycodes']
const select = ref(null)
const crud = ref({})

// -----------------------------------------------------

watchEffect(() => {
    if (crud.value.price_calculation_type == 'C') {
        crud.value.net_buy_price = crud.value.net_unit_price * crud.value.quantity
    }
})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select, null, null, null, { 'currency_id': await getField('currencies', 'id', "symbol='PLN'") })
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading)
}

// -----------------------------------------------------

const tabsDeny = (tabName) => {
    return props.formProps?.tabsDeny?.length == 0 || props.formProps?.tabsDeny?.includes(tabName)
}

// -----------------------------------------------------

const calcTypes = [
    { label: __('manual'), value: 'M' },
    { label: __('calculated'), value: 'C' },
]

// -----------------------------------------------------

const beforeSave = (sourceForm) => {
    if (sourceForm.price_calculation_type == 'M') {
        sourceForm.net_unit_price = 0
        sourceForm.quantity = 0
    }

    return sourceForm;
};

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
