import { router, usePage } from '@inertiajs/vue3'
import { useAppStore } from "@stores/appstate.js";
import { appName as constantAppName, appCaption as constantAppCaption } from './constants.js';
import { usePrimeVue } from "primevue/config";
import axios from 'axios';

// -----------------------------------------------------------------------------------------------------------

export const getAppName = () => {
    const page = usePage()
    return page.props?.appName ?? constantAppName;
}

// -----------------------------------------------------------------------------------------------------------

export const getAppCaption = () => {
    const page = usePage()
    // console.log(page)
    return page.props?.appCaption ?? constantAppCaption;
}

// -----------------------------------------------------------------------------------------------------------

export const getLocale = () => {
    const page = usePage()
    return page.props?.appLocale;
}

// -----------------------------------------------------------------------------------------------------------

export const setLocale = () => {
    const page = usePage();
    const locale = page.props.appLocale;
    sessionStorage.removeItem("locale");
    sessionStorage.setItem("locale", locale);
};

// ----------------------------------------------------------------------------------------------------

export const __ = (msg, replacements = null) => {
    let objTran = null;
    if (!window.objTran) {
        objTran = getSessionValue("translations");
        window.objTran = JSON.parse(objTran);
        objTran = window.objTran;
    } else {
        objTran = window.objTran;
    }

    if (objTran) {
        let tran = objTran[msg] ?? msg;
        if (replacements) {
            for (const [key, value] of Object.entries(replacements)) {
                tran = tran.replace(`:${key}`, value);
            }
        }
        return tran;
    } else {
        return msg;
    }
};

// -----------------------------------------------------------------------------------------------------------

export function isObjectEmpty(obj) {
    if (!obj) {
        return null;
    }
    return Object.keys(obj).length === 0;
}

//----------------------------------------------------------------------

export const today = (withTime = false, withSeconds = false) => {
    const date = new Date();

    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    let hour = String(date.getHours()).padStart(2, "0");
    let minute = String(date.getMinutes()).padStart(2, "0");
    let second = String(date.getSeconds()).padStart(2, "0");

    let today = null;
    if (!withTime) {
        today = year + "-" + month + "-" + day;
    } else {
        if (!withSeconds) {
            today = year + "-" + month + "-" + day + " " + hour + ":" + minute;
        } else {
            today = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        }
    }

    return today;
};

//----------------------------------------------------------------------------

export const msToHours = (duration) => {
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
};

// ----------------------------------------------------------------------------------------------------

export const setSessionValue = (key, value) => {
    let appName = getAppName()
    sessionStorage.setItem(appName[key], JSON.stringify(value));
};

// -----------------------------------------------------------------------------------------------------------

export function getSessionValue(key) {
    let arrKey = key.split(".");
    let appName = getAppName()

    try {
        var sessionData = JSON.parse(sessionStorage.getItem(appName));

        arrKey.forEach((elem) => {
            if (sessionData && sessionData.hasOwnProperty(elem)) {
                sessionData = sessionData[elem];
            } else {
                sessionData = null;
            }
        });
        return sessionData;
    } catch (e) {
        return null;
    }
}

//----------------------------------------------------------------------

export const setStoreValue = (key, value) => {
    const store = useAppStore();
    store.$patch({ [key]: value });
};

// -----------------------------------------------------------------------------------------------------------

export const getStoreValue = (key) => {
    const store = useAppStore();
    return store[key];
};

// -----------------------------------------------------------------------------------------------------------

export const saveAppSession = () => {
    let appName = getAppName();
    let sessionStorageTmp = JSON.parse(sessionStorage.getItem(appName))
    delete sessionStorageTmp?.translations;

    const prefix = appName + '-';
    const suffixesToRemove = ['-custom-link-data']
    let viewStates = {};

    for (let i = 0; i < sessionStorage.length; i++) {
        let key = sessionStorage.key(i);
        if (key.startsWith(prefix) && !suffixesToRemove.some(suffix => key.endsWith(suffix))) {
            viewStates[key] = JSON.parse(sessionStorage.getItem(key));
        }
    }

    let storage = {
        local: { ...localStorage },
        session: { ...sessionStorageTmp },
        views: { ...viewStates },
    }

    router.post(route('save-session'), storage)
}

// -----------------------------------------------------------------------------------------------------------

export const screenMessage = (objMessage = null) => {
    const page = usePage();
    if (objMessage) {
        if (!objMessage.hasOwnProperty("type")) {
            objMessage.type = "info";
        }
        if (!objMessage.hasOwnProperty("title")) {
            objMessage.title = "Information";
        }
        if (!objMessage.hasOwnProperty("message")) {
            objMessage.message = "No message";
        }
        if (!objMessage.hasOwnProperty("position")) {
            objMessage.position = "top-right";
        }
        if (!objMessage.hasOwnProperty("timeout")) {
            objMessage.timeout = 5;
        }
    } //else {
    //objMessage = {
    //  type: "remove-message",
    //};
    //}

    page.props.flash.message = objMessage;
}

// -----------------------------------------------------------------------------------------------------------

export const getField = async (table, field, where) => {
    try {
        const response = await axios.post(route('get-field'), {
            table,
            field,
            where
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// -----------------------------------------------------------------------------------------------------------

export const getRecord = async (table, fields = null, where) => {
    try {
        const response = await axios.post(route('get-record'), {
            table,
            fields,
            where
        }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// -----------------------------------------------------------------------------------------------------------

export const getFromRoute = async (routeName, parameters = {}, method = 'get') => {
    try {
        const response = await axios({
            url: route(routeName, parameters),
            method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });

        const value = await response.data;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const getExchange = async (fromValue, fromSymbol, toSymbol, date = null) => {
    try {
        const response = await axios.get(route('get-exchange', {
            fromValue,
            fromSymbol,
            toSymbol,
            date
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        });
        const value = await response.data.toValue;
        return value;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// -----------------------------------------------------------------------------------------------------------

// export const convertDate = (inputDate) => {
//     let dateObj = new Date(inputDate);
//     let year = dateObj.getFullYear();
//     let month = dateObj.getMonth() + 1;
//     let day = dateObj.getDate();

//     if (month < 10) month = '0' + month;
//     if (day < 10) day = '0' + day;

//     return `${year}-${month}-${day}`;
// }

// -----------------------------------------------------------------------------------------------------------

export const currentDate = (separator = "-") => {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    return `${year}${separator}${month}${separator}${day}`;
}

// -----------------------------------------------------------------------------------------------------------

export const currentTime = (separator = ":") => {
    let date = new Date();
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    if (hour < 10) hour = '0' + hour;
    if (minute < 10) minute = '0' + minute;
    if (second < 10) second = '0' + second;

    return `${hour}${separator}${minute}${separator}${second}`;

}

// -----------------------------------------------------------------------------------------------------------

export function firstDayOfMonth(date = null) {
    if (!date) {
        date = new Date();
    }

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return firstDay;
}

// -----------------------------------------------------------------------------------------------------------

export function lastDayOfMonth(date) {
    if (!date) {
        date = new Date();
    }
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDay;
}

// -----------------------------------------------------------------------------------------------------------

export function firstDayOfPrevMonth() {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    return firstDay;
}

// -----------------------------------------------------------------------------------------------------------

export function lastDayOfPrevMonth() {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    return lastDay;
}

// -----------------------------------------------------------------------------------------------------------

export const can = (value) => {
    let permissions = getStoreValue('permissions')
    var _return = false;

    if (!Array.isArray(permissions)) {
        return false;
    }

    value = value.toLowerCase().replace(/[_\s]/g, '-');
    permissions = permissions.map(permission => permission.toLowerCase().replace(/[_\s]/g, '-'));

    if (value.includes('|')) {
        value.split('|').forEach(function (item) {
            if (permissions.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes('&')) {
        _return = true;
        value.split('&').forEach(function (item) {
            if (!permissions.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = permissions.includes(value.trim());
    }
    return _return;
}

// -----------------------------------------------------------------------------------------------------------

export const is = (value) => {
    let roles = getStoreValue('roles')
    var _return = false;

    if (!Array.isArray(roles)) {
        return false;
    }

    value = value.toLowerCase().replace(/[_\s]/g, '-');
    roles = roles.map(role => role.toLowerCase().replace(/[_\s]/g, '-'));

    if (value.includes('|')) {
        value.split('|').forEach(function (item) {
            if (roles.includes(item.trim())) {
                _return = true;
            }
        });
    } else if (value.includes('&')) {
        _return = true;
        value.split('&').forEach(function (item) {
            if (!roles.includes(item.trim())) {
                _return = false;
            }
        });
    } else {
        _return = roles.includes(value.trim());
    }
    return _return;
}
// -----------------------------------------------------------------------------------------------------------

export const hasPermission = (arrPath) => {
    let path = null
    if (Array.isArray(arrPath) && arrPath.length > 0) {
        path = arrPath.join('.')
    } else {
        path = arrPath
    }

    let superAdminRole = getStoreValue('superAdminRole')
    return is(superAdminRole) || (path ? can(path) : false)
}

// -----------------------------------------------------------------------------------------------------------

export const hasRole = (arrPath) => {
    let path = null
    if (Array.isArray(arrPath) && arrPath.length > 0) {
        path = arrPath.join('.')
    } else {
        path = arrPath
    }

    return path ? is(path) : false
}

// -----------------------------------------------------------------------------------------------------------

export const checkRoute = (routeName, parameters = null, label = null) => {
    if (route().has(routeName)) {
        return route(routeName, parameters)
    } else {
        return route('route-not-exist', { 'label': label })
    }
}

// -----------------------------------------------------------------------------------------------------------

export const getConfigOptions = async (key = null) => {
    try {
        const response = await axios.get(route('thisapp-config'));
        const value = await response.data;
        if (key) {
            return value[key];
        } else {
            return value;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const numberFormat = (value, decimals = 2, decPoint = '.', thousandsSep = ' ') => {
    if (value === null || value === undefined) {
        return null;
    }

    return parseFloat(value)?.toFixed(decimals)?.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep)?.replace('.', decPoint);
}

// -----------------------------------------------------------------------------------------------------------

// export const loadComponent = async (componentPath) => {
//     const component = await import(/* @vite-ignore */'./' + componentPath + '.vue');
//     return component.default;
// };

// -----------------------------------------------------------------------------------------------------------

export const printReport = (response, isLoading = null, removeFile = true) => {
    if (isLoading) {
        isLoading.value = true;
    }
    let attachment = decodeURIComponent(response.attachment)

    if (!attachment || attachment == 'false' || (Array.isArray(attachment) && attachment.length === 0)) {
        return false;
    }

    let arrAttachment = attachment.split(',')

    arrAttachment.forEach(attachment => {
        let fileName = attachment.split('/').pop()
        if (fileName.length === 0 || fileName == 'false') {
            return;
        }
        let a = document.createElement('a');

        a.href = attachment;
        a.download = fileName;
        document.body.append(a);
        a.click();
        a.remove();

        if (removeFile) {
            setTimeout(async () => {
                await fetch(route('remove-printout', fileName))
            }, 1000);
        }
    })

    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export function translatePrimeVueMsgs() {
    const primevue = usePrimeVue();

    primevue.config.locale = {
        startsWith: __("Starts with"),
        contains: __("Contains"),
        notContains: __("Not contains"),
        endsWith: __("Ends with"),
        equals: __("Equals"),
        notEquals: __("Not equals"),
        noFilter: __("No Filter"),
        lt: __("Less than"),
        lte: __("Less than or equal to"),
        gt: __("Greater than"),
        gte: __("Greater than or equal to"),
        dateIs: __("Date is"),
        dateIsNot: __("Date is not"),
        dateBefore: __("Date is before"),
        dateAfter: __("Date is after"),
        clear: __("Clear"),
        apply: __("Apply"),
        matchAll: __("Match All"),
        matchAny: __("Match Any"),
        addRule: __("Add Rule"),
        removeRule: __("Remove Rule"),
        accept: __("Yes"),
        reject: __("No"),
        choose: __("Choose"),
        upload: __("Upload"),
        cancel: __("Cancel"),
        completed: __("Completed"),
        pending: __("Pending"),
        fileSizeTypes: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        dayNames: [__('Sunday'), __('Monday'), __('Tuesday'), __('Wednesday'), __('Thursday'), __('Friday'), __('Saturday')],
        dayNamesShort: [__('Sun'), __('Mon'), __('Tue'), __('Wed'), __('Thu'), __('Fri'), __('Sat')],
        dayNamesMin: [__('Su'), __('Mo'), __('Tu'), __('We'), __('Th'), __('Fr'), __('Sa')],
        monthNames: [__('January'), __('February'), __('March'), __('April'), __('May'), __('June'), __('July'), __('August'), __('September'), __('October'), __('November'), __('December')],
        monthNamesShort: [__('Jan'), __('Feb'), __('Mar'), __('Apr'), __('May'), __('Jun'), __('Jul'), __('Aug'), __('Sep'), __('Oct'), __('Nov'), __('Dec')],
        chooseYear: __("Choose Year"),
        chooseMonth: __("Choose Month"),
        chooseDate: __("Choose Date"),
        prevDecade: __("Previous Decade"),
        nextDecade: __("Next Decade"),
        prevYear: __("Previous Year"),
        nextYear: __("Next Year"),
        prevMonth: __("Previous Month"),
        nextMonth: __("Next Month"),
        prevHour: __("Previous Hour"),
        nextHour: __("Next Hour"),
        prevMinute: __("Previous Minute"),
        prevSecond: __("Previous Second"),
        nextSecond: __("Next Second"),
        am: __("am"),
        pm: __("pm"),
        today: __("Today"),
        weekHeader: __("Wk"),
        firstDayOfWeek: 0,
        showMonthAfterYear: false,
        dateFormat: "rrrr/mm/dd",
        weak: __("Weak"),
        medium: __("Medium"),
        strong: __("Strong"),
        passwordPrompt: __("Enter a password"),
        emptyFilterMessage: __("No results found"),
        searchMessage: __("results are available"),
        selectionMessage: __("items selected"),
        emptySelectionMessage: __("No selected item"),
        emptySearchMessage: __("No results found"),
        emptyMessage: __("No available options"),
        noFilter: __("No filter"),
    };
}

// ----------------------------------------------------------------------------------------------------
