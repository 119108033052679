import { ref, onMounted, nextTick } from "vue";
import axios from 'axios'
import { __ } from "./utils";

// -----------------------------------------------------------------------------------------------------------

export const matchModes = function (filterType) {
    switch (filterType) {
        case "autocomplete":
            return [
                { label: __('Column contain'), value: "autocomplete-contain" },
                { label: __('Column not contain'), value: "autocomplete-not-contain" },
            ];
        case "dropdown":
            return [
                { label: __('Equals'), value: "text-equal-whole" },
                { label: __('Not equals'), value: "text-not-equal-whole" },
            ];
        case "range":
            return [
                { label: __('Between'), value: "range-in" },
                { label: __('Out of range'), value: "range-out" },
            ];
        case "date":
            return [
                { label: __('Equals'), value: "date-equal" },
                { label: __('Not equals'), value: "date-different" },
                { label: __('Earlier than'), value: "date-lesser-than" },
                { label: __('Later than'), value: "date-greater-than" },
            ];
        case "text":
            return [
                { label: __('Equals'), value: "text-equal-whole" },
                { label: __('Not equals'), value: "text-not-equal-whole" },
                { label: __('Containing'), value: "text-contain" },
                { label: __('Not containing'), value: "text-not-contain" },
                { label: __('Matched begin'), value: "text-equal-begin" },
                { label: __('Matched end'), value: "text-equal-end" },
            ];
        case "value":
            return [
                { label: __('Equals'), value: "value-equal" },
                { label: __('Not equals'), value: "value-not-equal" },
                { label: __('Greater than'), value: "value-greater" },
                { label: __('Less than'), value: "value-lesser" },
                { label: __('Greater or equal'), value: "value-greater-equal" },
                { label: __('Less or equal'), value: "value-lesser-equal" },
            ];
        case "checkbox":
            return [
                { label: __('Yes'), value: "checkbox-yes" },
                { label: __('No'), value: "checkbox-no" },
            ];
        default:
            return [
                { label: __('Equals'), value: "text-equal-whole" },
                { label: __('Not equals'), value: "text-not-equal-whole" },
                { label: __('Containing'), value: "text-contain" },
                { label: __('Not containing'), value: "text-not-contain" },
                { label: __('Matched begin'), value: "text-equal-begin" },
                { label: __('Matched end'), value: "text-equal-end" },
            ];
    }
};

// -----------------------------------------------------------------------------------------------------------

export const setCellStyle = (props, field, value, innerId, rowData) => {
    let objColumn =
        props.columns.filter((item) => item?.field == field)[0] ?? null;
    if (
        objColumn &&
        objColumn?.cellStyle &&
        Array.isArray(objColumn.cellStyle)
    ) {
        nextTick(function () {
            let innerElement = document.getElementById(innerId);
            let tdElement = innerElement?.closest("td");

            if (tdElement) {
                if (objColumn?.frozen) {
                    //tdElement.style.cssText += 'background-color: #f1f1f1;';
                }
                objColumn?.cellStyle.forEach((item) => {
                    const conditionFn = new Function('value', 'rowData', `return ${item.condition}`);
                    if (conditionFn(value, rowData)) {
                        tdElement.style.cssText = item?.style?.replace('^value^', value)?.replace(/\^([^\^]+)\^/g, function (match, p1) {
                            //do stylu można wstawić wartość z bieżącego pola: ^value^ lub wartość z innego pola: ^nazwa_pola^
                            return rowData[p1];
                        });
                        tdElement.classList.add(item?.class);
                    }
                });
            }
        });
    }
};

// -----------------------------------------------------------------------------------------------------------

export const setRowAttribStyle = (rowConditions, data) => {
    let rowStyle = null;
    if (Array.isArray(rowConditions)) {
        rowConditions.forEach((item) => {
            const conditionFn = new Function('data', `return ${item.condition}`);
            if (conditionFn(data)) {
                rowStyle = item?.style;
                return;
            }
        });
    }
    //return "'text-decoration':'underline'; 'color': 'red'"
    return rowStyle;
};

// -----------------------------------------------------------------------------------------------------------

export const setRowAttribClass = (rowConditions, data) => {
    let rowClass = null;
    if (Array.isArray(rowConditions)) {
        rowConditions.forEach((item) => {
            const conditionFn = new Function('data', `return ${item.condition}`);
            if (conditionFn(data)) {
                rowClass = item?.class;
                return;
            }
        });
    }
    return rowClass;
};

// -----------------------------------------------------------------------------------------------------------

export const setRowAttrib = (props, data, attribute) => {
    switch (attribute) {
        case "style":
            return setRowAttribStyle(props, data);
        case "class":
            return setRowAttribClass(props, data);
    }
};

// -----------------------------------------------------------------------------------------------------------

export const setFormState = (isFormDisabled, editMode, addMode) => {
    isFormDisabled.value = true
    if (editMode.value || addMode.value) {
        isFormDisabled.value = false
    }
}
// -----------------------------------------------------------------------------------------------------------


export const rowButtonAction = (arrButton, data, rowButtonFunctions) => {
    const func = arrButton?.event;
    if (rowButtonFunctions.hasOwnProperty(func)) {
        rowButtonFunctions[func](data)
    } else {
        console.warning('Brak funkcji o nazwie: ' + func)
    }

}

//------------------------------------------------------------------------------

export const yesNoOptions = [{ label: __("Yes"), value: 1 }, { label: __("No"), value: 0 }];

//------------------------------------------------------------------------------

export const prepareSearch = async (search, props, options = null, select = null) => {
    onMounted(async () => {
        let fields = {}
        search.value = { ...fields, ...props.searchProps.data }

        if (options) {
            select.value = await prepareSelect(options)
        }

    });
};

// -----------------------------------------------------------------------------------------------------------

export const prepareCrud = async (props, initialData = null) => {
    let fields = {}
    fields['files'] = []

    if (props.formProps.addMode) {
        //return { ...fillCrudForm(true, true), ...initialData }
        return { files: [], ...initialData }

    } else {
        try {
            const response = await axios.get(route(props.formProps.routes.record, { id: props.formProps.data?.id }))
            response.data = changeIntToBolean(response.data)
            if (response.data.hasOwnProperty('_error')) {
                console.error('Wystąpił błąd podczas pobierania danych rekordu (funkcja prepareCrud):', response.data._error)
            }
            return { files: [], ...response.data }
        } catch (error) {
            console.error('Wystąpił błąd podczas pobierania danych rekordu (funkcja prepareCrud):', error)
            return {}
        }
    }
}

// -----------------------------------------------------------------------------------------------------------

const changeIntToBolean = (data) => {
    for (const key in data) {
        if ((data[key] === 1 || data[key] === 0) && key.startsWith('is_')) {
            data[key] = Boolean(parseInt(data[key]));
        }
    }
    return data;
}

// -----------------------------------------------------------------------------------------------------------

export const convertDate = (dateToConvert) => {
    if (dateToConvert instanceof Date) {
        let year = dateToConvert.getFullYear();
        let month = dateToConvert.getMonth() + 1;
        let day = dateToConvert.getDate();

        if (month < 10) month = '0' + month;
        if (day < 10) day = '0' + day;

        dateToConvert = `${year}-${month}-${day}`;
    }
    return dateToConvert;
}

// -----------------------------------------------------------------------------------------------------------

export const prepareFiles = (arrFiles) => {
    if (Array.isArray(arrFiles)) {
        const filesByCategory = {};
        arrFiles.forEach(file => {
            const category = file.file_category;
            if (!filesByCategory[category]) {
                filesByCategory[category] = [];
            }

            const blob = base64ToBlob(file.content, file.mime_type);
            filesByCategory[category].push({ 'file': new File([blob], file.file_name, { type: file.mime_type, lastModified: file.last_modified }), 'metadata': { 'fileName': file.file_name, 'filePath': file.file_path } });

            //filesByCategory[category].push({ 'file': new File([file.file_path], file.file_name), 'metadata': { 'fileName': file.file_name, 'filePath': file.file_path } });
        });
        return filesByCategory
    }
    return []
}

// -----------------------------------------------------------------------------------------------------------

function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
}

// -----------------------------------------------------------------------------------------------------------

export const prepareSelect = async (selectKeys, event = null, crud = null, params = null, fieldsSendToBackend = null, icons = null) => {
    if (!Array.isArray(selectKeys)) {
        selectKeys = [selectKeys]
    }

    let filteredCrud = clearCrud(crud?.value, fieldsSendToBackend);
    let options = {}

    try {
        const response = await axios.post(route("helper-select", { select: selectKeys, filter: event?.value, crud: filteredCrud, params: params }));
        let selectData = await response.data;

        for (const select in selectData) {
            if (Array.isArray(selectData[select]) && selectData[select].length > 0 && selectData[select][0].hasOwnProperty('parent_id')) {
                options[select] = buildHierarchy(selectData[select], icons)
            } else {
                options[select] = selectData[select]
            }
        }
    } catch (error) {
        console.log(error)
    }

    return options
}

// -----------------------------------------------------------------------------------------------------------

const clearCrud = (crud = null, fieldsLeavedInCrud = null) => {
    let filteredCrud = null;
    if (crud) {
        filteredCrud = Object.keys(crud)
            .filter(key => key.endsWith('_id') || fieldsLeavedInCrud?.includes(key))
            .reduce((res, key) => ((res[key] = crud[key]), res), {});
    }
    return filteredCrud
}

// -----------------------------------------------------------------------------------------------------------

export const validation = (sourceForm, visit = null) => {
    let elements = document.querySelectorAll('[validation]');
    let isValid = true;

    validMsgHeaderRemove();
    validMsgFieldRemove();

    elements.forEach((element) => {
        let rules = element.getAttribute('validation').split(',')
        rules.forEach(rule => {
            let elementName = element.getAttribute('name')
            if (elementName) {
                let message = validationRule(sourceForm[elementName], rule)
                if (message) {
                    validMsgFieldAdd(element, message);
                    isValid = false;
                }
            }
        })
    });

    if (!isValid) {
        validMsgHeaderAdd();
    }

    return isValid
}

// -----------------------------------------------------------------------------------------------------------

const validationRule = (field, rule) => {
    switch (rule) {
        case 'required':
            return (field == '' || field == null) ? __('Field content is required') : null
        case 'email':
            return field.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/) ? null : __('The email field must be a valid email address')
        case 'numeric':
            return field.match(/^[0-9]+$/) ? null : __('The email field must be a valid numeric value')
        case 'date':
            return field.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/) ? null : __('The email field must be a valid date')
        default:
            return null
    }
}

// -----------------------------------------------------------------------------------------------------------

export const validMsgHeaderRemove = () => {
    document.querySelector('.error-message-header')?.remove();
}

// -----------------------------------------------------------------------------------------------------------

export const validMsgFieldRemove = () => {
    const errorMessages = document.querySelectorAll('.error-message-field');
    errorMessages.forEach(elem => {
        elem?.remove();
    });
    document.querySelector('.error-message-header')?.remove();
}

// -----------------------------------------------------------------------------------------------------------

export const validMsgHeaderAdd = () => {
    document.querySelector(".p-dialog-header span")?.insertAdjacentHTML(
        "beforeend",
        "<span class='error-message-header'>" + __('Correct form data!') + "</span>");

}

// -----------------------------------------------------------------------------------------------------------

export const validMsgFieldAdd = (element, message) => {
    element?.insertAdjacentHTML(
        "afterend",
        "<div class='error-message-field'>" + message + "</div>");
}

// -----------------------------------------------------------------------------------------------------------

export const onFilterService = async (event, option, select, isLoading = null, crud = null, params = null, fieldsSendToBackend = null) => {
    if (isLoading) {
        isLoading.value = true;
    }

    const data = await prepareSelect(option, event, crud, params, fieldsSendToBackend)
    select.value[option] = data[option]


    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const onChangeService = async (dependences, select, crud, isLoading = null, fieldsSendToBackend = null, setFirstElement = false) => {
    if (isLoading) {
        isLoading.value = true;
    }

    if (crud) {
        for (const elem of dependences) {
            let currentSelect = Object.keys(elem.select)[0]
            let currentId = elem.select[currentSelect]
            crud.value[currentId] = null
        }
    }

    await Promise.all(dependences.map(async (elem) => {
        let currentSelect = Object.keys(elem.select)[0]
        let currentId = elem.select[currentSelect]

        const data = await prepareSelect(currentSelect, null, crud, elem.conditions.replace(/#([^#]+)#/g, function (_, key) {
            return elem?.variables[key] ?? null;
        }), fieldsSendToBackend)

        select.value[currentSelect] = data[currentSelect]
    }))

    if (setFirstElement) {
        for (const elem of dependences) {
            let currentSelect = Object.keys(elem.select)[0]
            let currentId = elem.select[currentSelect]
            if (crud && select.value[currentSelect].length > 0) {
                crud.value[currentId] = select.value[currentSelect][0].value
            }
        }
    }

    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const onErrorService = (errors) => {
    validMsgHeaderRemove()
    validMsgFieldRemove()
    validMsgHeaderAdd()

    for (const [key, value] of Object.entries(errors)) {
        try {
            const objValidator = JSON.parse(value);

            const element = document.querySelector("form [name='" + objValidator.id + "']");
            validMsgFieldAdd(element, objValidator.msg)
        } catch (e) {
            const element = document.querySelector("form [name='" + key + "']");
            validMsgFieldAdd(element, value)
        }
    }
}

// -----------------------------------------------------------------------------------------------------------

export const initFormObj = async (objectParameters) => {
    let { props, crud, isLoading, options, select, buttonsVisibility, changeCallback, fieldsSendToBackend, initialData, icons, $differentId } = objectParameters
    return await initForm(props, crud, isLoading, options, select, buttonsVisibility, changeCallback, fieldsSendToBackend, initialData, icons, $differentId)
}

// -----------------------------------------------------------------------------------------------------------

export const initForm = async (props, crud, isLoading = null, options = null, select = null, buttonsVisibility = null, changeCallback = null, fieldsSendToBackend = null, initialData = null, icons = null) => {
    if (isLoading) {
        isLoading.value = true;
    }

    crud.value = await prepareCrud(props, initialData)

    if (crud.value.hasOwnProperty('buttonsVisibility') && buttonsVisibility) {
        buttonsVisibility.value = crud.value.buttonsVisibility
    }

    if (options) {
        select.value = await prepareSelect(options, null, crud, null, fieldsSendToBackend, icons)
    }

    if (changeCallback) {
        await changeCallback()
    }

    if (isLoading) {
        isLoading.value = false;
    }
}

// -----------------------------------------------------------------------------------------------------------

export const buildHierarchy = (data, icons = null) => {
    const hierarchy = [];

    // Create a map of id to node
    const nodeMap = new Map();
    data.forEach((item) => {
        let level = null;
        if (icons) {
            level = getLevel(item, data);
        }

        nodeMap.set(item.value, {
            key: item.value,
            label: item.label,
            data: item.value,
            icon: icons ? icons.find((icon) => icon?.level === level)?.icon : item?.icons ?? null,
            children: [],
        });
    });

    // Build the hierarchy
    data.forEach((item) => {
        const node = nodeMap.get(item.value);
        const parent = nodeMap.get(item.parent_id);
        if (parent) {
            parent.children.push(node);
        } else {
            hierarchy.push(node);
        }
    });

    return hierarchy;
}

// -----------------------------------------------------------------------------------------------------------

const getLevel = (item, data) => {
    let level = 1;
    let parent = data.find((element) => element.value === item.parent_id);
    while (parent) {
        level++;
        parent = data.find((element) => element.value === parent.parent_id);
    }
    return level;
}

// -----------------------------------------------------------------------------------------------------------

