<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="pricelist_name"
                                   label="Pricelist name"
                                   size="4"
                                   :isFocus="true"
                                   v-model:field="search.pricelist_name" />

                    <FormMultiselect fieldName="country_id"
                                     label="Country"
                                     size="4"
                                     showClear
                                     :options="select?.countries"
                                     :filter="(event) => onFilter(event, 'countries')"
                                     v-model:field="search.country_id" />

                    <FormMultiselect fieldName="sellchannel_id"
                                     label="Sell channel"
                                     size="4"
                                     showClear
                                     :options="select?.sellchannel"
                                     :filter="(event) => onFilter(event, 'sellchannel')"
                                     v-model:field="search.sellchannel_id" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ['countries', 'sellchannels']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
