<template>
    <v-form>
        <div class="grid">
            <div class="col-12"
                 style="padding-left:25px;font-size:13px;color:burlywood">
                <span v-html="__('After clicking the :button button, the gross sales price in Baselinker will be replaced with the gross sales price in the sales currency, coming from the local system',
                    { 'button': updatePriceButton })
                    "></span>
            </div>
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button @click.stop="updatePrices"
                    style="background-color:brown;margin:0 7px 0 25px"
                    severity="info">
                <i class="fa-solid fa-coins"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Update prices') }}
            </Button>

            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref } from 'vue'
import { usePage } from "@inertiajs/vue3";
import Button from 'primevue/button';
import { __, getFromRoute, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})
const page = usePage()

const isUpdatePriceModalOpen = defineModel('isUpdatePriceModalOpen')
const updatePriceButton = `<span style='font-weight:bold;'>"${__('Update prices')}"</span>`
const grossPrice = ref(0)

// -----------------------------------------------------

const cancel = () => {
    isUpdatePriceModalOpen.value = false
}
// -----------------------------------------------------

const updatePrices = async () => {
    await getFromRoute('selected-priceupdate-update', {
        'selectedRows': props.formProps.selectedRows.map(row => {
            return { 'id': row.id, 'product_id': row.product_id, 'pricelist_id': row.pricelist_id, 'gross_sell_price_curr_decimal': row.gross_sell_price_curr_decimal }
        })
    }, 'post');
    const message = page?.props?.flash?.message ?? null
    if (message) {
        screenMessage(message)
    }


    props.formProps.clearSelection()
    props.formProps.getLazyData()
    isUpdatePriceModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
