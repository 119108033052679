<template>

    <Head :title="__('Login')" />

    <div class="auth-container">
        <div class="auth-splash auth-left">
            <v-form name="formLogin2Fa"
                    class="auth-form"
                    @keyup.enter="login">
                <div class="auth-top">
                    <v-col class="align-items-center">
                        <Image :src="qrCodeImage"
                               width="300" />
                    </v-col>
                </div>

                <div class="auth-main">
                    <v-col>
                        <label class="auth-label"
                               for="generatedCode">{{ __('Enter security code') }}</label>
                        <InputText type="text"
                                   id="generatedCode"
                                   name="generatedCode"
                                   size="small"
                                   style="display:flex;width:100%"
                                   v-model="form.generatedCode" />
                    </v-col>

                    <v-col class="auth-section">
                        <div class="form-group mt-4 text-left instruction">
                            <span>Jeśli powyżej pojawił się kod QR:</span>
                            <ol style="padding-left:30px;padding-bottom:20px;">
                                <li>Ze sklepu Play lub AppStore pobierz na telefon aplikację Google Authenticator
                                    <Image :src="gaIcon"
                                           style="display:inline-block !important;width:30px;" />
                                </li>
                                <li>Otwórz aplikację i kliknij przycisk "Dodaj kod"</li>
                                <li>Zeskanuj kod QR z ekranu</li>
                                <li>Wpisz wygenerowany numer do pola powyżej</li>
                                <li>Kliknij "Login"</li>
                            </ol>
                            <span>Jeśli powyżej nie pojawił się kod QR:</span>
                            <ol style="padding-left:30px;">
                                <li>Otwórz na telefonie aplikację Google Authenticator
                                    <Image :src="gaIcon"
                                           style="display:inline-block !important;width:30px;" />
                                </li>
                                <li>Wpisz wygenerowany numer do pola powyżej</li>
                                <li>Kliknij "Login"</li>
                            </ol>
                        </div>

                    </v-col>
                    <v-row>
                        <Button @click="login"
                                class="auth-button"
                                label="Login"
                                icon="pi pi-sign-in" />
                    </v-row>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3'
import { Head } from '@inertiajs/vue3'

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import Layout from '@layouts/LayoutNoMenuNoFooter.vue'
import Image from '@helper/Image.vue'
import { __ } from '@helper/utils.js'
import { startSplash } from '@helper/constants.js'

defineOptions({ layout: Layout })

const props = defineProps({
    qrCodeImage: String,
    email: String,
    password: String,
    remember: Boolean,
    removeSession: Boolean,
    ga2FaToken: String,
    gaIcon: String
})

// -----------------------------------------------------

const form = useForm({ generatedCode: '', 'email': props.email, 'password': props.password, 'remember': props.remember, 'removeSession': props.removeSession, 'ga2FaToken': props.ga2FaToken })

const login = () => {
    form.post(route('ga2fa-verify'), {
        onSuccess: () => {
        },
        onError: (errors) => { console.error(errors) },
    })
}
</script>

<style lang="scss"
       scoped>
    .ga2FaCode {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        color: black;
    }

    .instruction {
        font-size: 12px;
        color: black;
    }

    .instruction span {
        font-size: 14px;
        color: #0B5A9C;
    }
</style>
