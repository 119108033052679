<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <TreeSelect class="w-full"
                    :display="display"
                    :class="{ 'required-field': hasRequiredAttr }"
                    :disabled="isFormDisabled"
                    :name="fieldName"
                    :validation="validation"
                    :selectionMode="selectionMode"
                    :options="options"
                    @filter="filter"
                    v-focus="isFocus"
                    v-model="field" />
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import TreeSelect from 'primevue/treeselect';
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    options: {
        type: Array,
        default: [],
        required: true
    },
    filter: {
        type: Function,
        required: false,
    },
    selectionMode: {
        type: String,
        default: 'single'
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');
const display = ref(props.selectionMode === 'multiple' ? 'chip' : 'comma');
const transformed = ref(false);

// ------------------------------------------------------------------------------

watch(() => field.value, (newValue, oldValue) => {
    if (!transformed.value) {
        if (typeof field.value === 'number' && field.value !== null) {
            field.value = { [field.value]: true };
        }
        else if (typeof field.value === 'object' && field.value !== null) {
            let tmp = {}
            for (let elem in field.value) {
                if (typeof field.value[elem] !== "boolean") {
                    tmp[field.value[elem]] = true;
                } else {
                    tmp = field.value;
                }
            }
            field.value = { ...tmp };
        }
    }
    transformed.value = true
})

// ------------------------------------------------------------------------------

if (props.inRepeater) {
    outerClass.value = null;
}


</script>

<style lang="scss"
       scoped></style>
