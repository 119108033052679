<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Multiselect optionLabel="label"
                     optionValue="value"
                     :filter="isFilter"
                     display="chip"
                     class="w-full"
                     :showToggleAll="showClear"
                     :selectionLimit="limit"
                     :loading="isLoading"
                     :disabled="isFormDisabled"
                     :virtualScrollerOptions="{ itemSize: 38 }"
                     :name="fieldName"
                     :validation="validation"
                     :options="options"
                     :class="{ 'required-field': hasRequiredAttr }"
                     @filter="filter"
                     v-model="field" />
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import Multiselect from 'primevue/multiselect'
import { __ } from '@pages/Helper/utils.js'

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    isLoading: {
        type: Boolean,
        default: false
    },
    options: {
        type: Array,
        default: [],
        required: true
    },
    filter: {
        type: Function,
        required: false,
    },
    limit: {
        type: Number,
        default: null
    },
    showClear: {
        type: Boolean,
        default: true
    },
    isFilter: {
        type: Boolean,
        default: true
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss"
       scoped>
    :deep(.stacked-items .p-multiselect-items .p-multiselect-item) {
        display: block;
    }

    :deep(.dynamic-height .p-multiselect-panel .p-multiselect-items) {
        height: auto;
        max-height: 200px;
        /* Maksymalna wysokość, którą chcesz ustawić */
        overflow-y: auto;
    }

    :deep(.p-multiselect) {
        display: flex;
    }

    :deep(.p-multiselect-label) {
        white-space: wrap;
    }

    :deep(.p-multiselect-token) {
        margin-bottom: 1px;
        margin-top: 1px;
        border-radius: 5px;
    }
</style>
