<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form>
        <div class="grid">
            <FormCheckbox fieldName="is_remove_range"
                          label="Data from range should be removed?"
                          helperText="If data remains can lead to creating duplicates"
                          style="padding-top:0;margin-top:15px !important"
                          v-model:field="is_remove_range" />
        </div>
        <div class="grid">
            <FormFileUploader fieldName="files"
                              :label="__('Data file')"
                              labelStyle="font-weight:bold"
                              maxFiles="1"
                              category="import"
                              style="margin-top:10px"
                              :allowedExtensions="['xlsx']"
                              v-model:field="fileToImport" />
        </div>

        <div class="grid">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="makeImport">
                <i class="fa-solid fa-file-arrow-up"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Make import') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancelImport">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import FormFileUploader from '@pages/Crud/FormFileUploader.vue'
import FormCheckbox from '@pages/Crud/FormCheckbox.vue';
import Button from 'primevue/button';
import { __ } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isLoading = ref(false);
const fileToImport = ref([])
const isCustomImportModalOpen = defineModel('isCustomImportModalOpen')
const is_remove_range = ref(true)

// -----------------------------------------------------

const cancelImport = () => {
    isCustomImportModalOpen.value = false
}
// -----------------------------------------------------

const makeImport = () => {
    isLoading.value = true

    useForm({ fileToImport: fileToImport.value.import, is_remove_range: is_remove_range.value }).post(route('soldposition-import'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,

        onError: (errors) => {
            console.log(errors)
        },
        onFinish: () => {
            props.formProps.getLazyData()
            isLoading.value = false
            isCustomImportModalOpen.value = false
        }
    })
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
