<template>
    <div :class="['flex', 'flex-column', 'gap-' + gap, className]"
         style="width:100%;padding:5px 0 "
         :style="['width:' + width, style]">
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    gap: { type: [Number, String], default: 0 },
    style: Object,
    width: { type: String, default: '100%' },
    class: { type: String, default: null },
})

const className = ref(props.class);

</script>

<style lang="scss"
       scoped></style>
