<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Editor class="w-full"
                ref="editorRef"
                :class="{ 'required-field': hasRequiredAttr }"
                :style="editorStyle"
                :readonly="isFormDisabled"
                :name="fieldName"
                :validation="validation"
                :modules="editorToolbar[toolbar]"
                @load="onEditorLoad"
                v-focus="isFocus"
                v-model="field">
            <template #toolbar>
                <span class="ql-formats">
                </span>
            </template>
        </Editor>
    </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from 'vue';
import Editor from 'primevue/editor';
import { __ } from "@helper/utils";

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    editorStyle: {
        type: Object,
        default: { height: "120px" },
    },
    toolbar: {
        type: String,
        default: 'small',
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
})

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

const editorRef = ref(null);
const toolbarSmall = ref({
    toolbar: [
        ['bold', 'italic', 'underline', 'strike', { 'color': [] }],
        [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        ['clean',],
    ]
});
const toolbarMedium = ref({
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        ['clean',],
    ]
});
const toolbarLarge = ref({
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }, { 'font': [] }],
        [{ 'align': [] }, { 'indent': '+1' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['clean',],
    ]
});

const editorToolbar = ref({
    small: toolbarSmall.value,
    medium: toolbarMedium.value,
    large: toolbarLarge.value,
});

const tooltipsSmall = [__('Bold'), __('Italic'), __('Underline'), __('Strikethrough'), __('Font color'), __('Text alignment'), __('List ordered'), __('List bullet'), __('List check'), __('Clean')]
const tooltipsMedium = [__('Bold'), __('Italic'), __('Underline'), __('Strikethrough'), __('Subscript'), __('Superscript'), __('Link'), __('Image'), __('Font color'), __('Background color'), __('Text alignment'), __('List ordered'), __('List bullet'), __('List check'), __('Clean')]
const tooltipsLarge = [__('Bold'), __('Italic'), __('Underline'), __('Strikethrough'), __('Subscript'), __('Superscript'), __('Link'), __('Image'), __('Font color'), __('Background color'), __('Font type'), __('Text alignment'), __('Text indent'), __('List ordered'), __('List bullet'), __('List check'), __('Font size'), __('Header size'), __('Clean')]
const tooltips = { small: tooltipsSmall, medium: tooltipsMedium, large: tooltipsLarge };

const tmpRef = ref(false);

//------------------------------------------------------------------------------------------------------------------------

watch(() => tmpRef, (newValue, oldValue) => {
    if (newValue.value) {
        const toolbar = editorRef.value?.$el?.querySelector('.ql-toolbar')
        const buttons = toolbar.querySelectorAll('button, select');
        buttons.forEach((button, index) => {
            button.setAttribute('title', tooltips[props.toolbar][index]);
            if (button.tagName === 'SELECT') {
                button.previousElementSibling?.setAttribute('title', tooltips[props.toolbar][index]);
            }
        });
    }
}, { deep: true, immediate: false })

//------------------------------------------------------------------------------------------------------------------------

const onEditorLoad = (() => {
    const toolbar = editorRef.value.$el
    if (toolbar) {
        tmpRef.value = true;
    }

});
</script>

<style lang="scss"
       scoped></style>
