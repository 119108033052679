<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="symbol"
                                   label="Courier nomenclature"
                                   size="2"
                                   :isFocus="true"
                                   v-model:field="search.symbol" />

                    <FormMultiselect fieldName="country_id"
                                     label="Country"
                                     size="4"
                                     showClear
                                     :options="select?.countries"
                                     :filter="(event) => onFilter(event, 'countries')"
                                     v-model:field="search.country_id" />

                    <FormMultiselect fieldName="courier_id"
                                     label="Courier"
                                     size="6"
                                     showClear
                                     :options="select?.couriers"
                                     :filter="(event) => onFilter(event, 'couriers')"
                                     v-model:field="search.courier_id" />
                </div>
                <div class="grid">
                    <FormInputNumber fieldName="weight_to_from"
                                     label="Weight to from"
                                     size="3"
                                     suffix=" kg"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.weight_to_from" />

                    <FormInputNumber fieldName="weight_to_to"
                                     label="Weight to to"
                                     size="3"
                                     suffix=" kg"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.weight_to_to" />

                    <FormInputNumber fieldName="height_to_from"
                                     label="Height to from"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.height_to_from" />

                    <FormInputNumber fieldName="height_to_to"
                                     label="Height to to"
                                     size="3"
                                     suffix=" cm"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.height_to_to" />

                </div>
                <div class="grid">
                    <FormInputNumber fieldName="parcel_charge_from"
                                     label="Net charge from"
                                     size="4"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.parcel_charge_from" />

                    <FormInputNumber fieldName="parcel_charge_to"
                                     label="Net charge to"
                                     size="4"
                                     :allowEmpty="true"
                                     :default="null"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="search.parcel_charge_to" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputText from '@crud/FormInputText.vue'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormMultiselect from '@crud/FormMultiselect.vue'
import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ['couriers', 'countries']
const select = ref(null)

let search = ref({})
prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
