<template>
    <Button @click="searchData"
            :disabled="search.processing"
            severity="success"
            style="margin:0 5px">
        {{ __('Find') }}
    </Button>
    <Button @click="cancelSearch"
            :disabled="search.processing"
            severity="secondary"
            style="margin:0 5px">
        {{ __('Cancel') }}
    </Button>
    <Button v-if="searchProps.data"
            @click="clearSearch"
            :disabled="search.processing"
            severity="warning"
            style="margin:0 5px">
        {{ __('Clear') }}
    </Button>
</template>

<script setup>
import { watch } from 'vue'
import Button from 'primevue/button';
import { useForm } from '@inertiajs/vue3';
import { __ } from '@pages/Helper/utils.js'
import { convertDate } from '@pages/Helper/crud_utils.js'

//-----------------------------------------------------------------------------

const props = defineProps({
    searchProps: Object,
    search: Object,
})

const autoSearch = defineModel('autoSearch')

//-----------------------------------------------------------------------------

watch(() => autoSearch.value, (newValue, oldValue) => {
    if (newValue) {
        searchData()
    }
}, { deep: true, immediate: true })

// -----------------------------------------------------------------------------------------------------------

const searchData = () => {
    const sourceSearch = props.search
    for (let field in sourceSearch) {
        if (sourceSearch[field] instanceof Date) {
            sourceSearch[field] = convertDate(sourceSearch[field])
        }

        if (field === 'parent_id' && typeof sourceSearch[field] === 'object') {
            let tmp = []
            for (let elem in sourceSearch[field]) {
                tmp.push(parseInt(elem))
            }
            sourceSearch[field] = tmp
        }

    }

    props.searchProps.clearSelection()
    useForm(props.searchProps).getLazyData({ page: 1, 'search': sourceSearch });
    props.searchProps.closeModal()
}

//-----------------------------------------------------------------------------

const clearSearch = () => {
    props.searchProps.clearSelection()
    useForm(props.searchProps).getLazyData({ page: 1, 'search': {} });
    props.searchProps.closeModal()
}

//-----------------------------------------------------------------------------

const cancelSearch = () => {
    props.searchProps.closeModal()
}

//-----------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
