<template>
    <div :class="[outerClass, colSize]">
        <label v-if="label"
               class="block"
               :class="{ 'required-label': hasRequiredAttr, [labelClass]: true }"
               :style="labelStyle">{{
        __(label)
    }}</label>
        <Password toggleMask
                  style="width: 100%"
                  inputClass="w-full"
                  feedback
                  :weakLabel="__('Weak')"
                  :mediumLabel="__('Medium')"
                  :strongLabel="__('Strong')"
                  :promptLabel="__('Enter password')"
                  :disabled="isFormDisabled"
                  :name="fieldName"
                  :validation="validation"
                  :class="{ 'required-field': hasRequiredAttr }"
                  v-focus="isFocus"
                  v-model="field" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Password from 'primevue/password';
import { __ } from "@helper/utils";

const props = defineProps({
    fieldName: {
        type: String,
        required: true,
    },
    validation: {
        type: [Array, String],
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    size: {
        type: String,
        default: null
    },
    isFocus: {
        type: Boolean,
        default: false
    },
    isFormDisabled: {
        type: Boolean,
        default: false
    },
    labelClass: {
        type: String,
        default: null
    },
    labelStyle: {
        type: [Object, String],
        default: null
    },
    inRepeater: {
        type: Boolean,
        default: false
    },
});

const field = defineModel('field');
const validation = ref(Array.isArray(props.validation) ? props.validation : [props.validation]);
const colSize = props?.size ? 'col-' + props.size : 'col';
const hasRequiredAttr = validation.value.includes('required');
const outerClass = ref('field');

if (props.inRepeater) {
    outerClass.value = null;
}

</script>

<style lang="scss"
       scoped></style>
