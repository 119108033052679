<template>
    <span>
        <v-view :tableData="tableData"
                :tableProps="tableProps"
                :modalProps="modalProps"
                :columns="columns"
                :routes="routes"
                :initialSort="initialSort"
                :rowButtonAction="rowButtonAction"
                :renderColumnValue="renderColumnValue"
                v-model:selectedRows="selectedRows">

            <template #buttons="formProps">
                <div>
                    <Button @click="checkIfSelectedRecordsExists(formProps, 'set-charge')"
                            style="background-color:cadetblue;margin:0 7px 0 25px"
                            severity="info">
                        <i class="fa-solid fa-coins"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Set charge') }}
                    </Button>

                    <Button @click="checkIfSelectedRecordsExists(formProps, 'set-marketing-cost')"
                            style="background-color:steelblue;margin:0 7px 0 0"
                            severity="info">
                        <i class="fa-solid fa-comments-dollar"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Set marketing costs') }}
                    </Button>

                    <Button @click="checkIfOnlyOnePricelist(formProps, 'set-courier-parcel')"
                            style="background-color:royalblue;margin:0 7px 0 0"
                            severity="info">
                        <i class="fa-solid fa-truck"
                           style="margin-right:5px;font-size:18px"></i>
                        {{ __('Set shipment nomenclature') }}
                    </Button>

                </div>
            </template>

            <template #crud="formProps">
                <ProductpriceForm :formProps="formProps">
                </ProductpriceForm>
            </template>
            <template #search="searchProps">
                <ProductpriceSearch :searchProps="searchProps">
                </ProductpriceSearch>
            </template>

        </v-view>
        <div class='legend-title'>{{ __('Legend') }}:</div>
        <div class='legend-position'><sup>* </sup><span style="color:red;">{{ __('red color') }}</span>: {{ __('custom dimensions') }}</div>

        <Dialog modal
                :visible="isSetChargeModalOpen"
                v-model:visible="isSetChargeModalOpen"
                :header="__('Set charge')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:cadetblue">{{ __('Charge source will be set for selected products') }}</p>

            <SetChargeForm :formProps="productpriceFormProps"
                           v-model:isSetChargeModalOpen="isSetChargeModalOpen">
            </SetChargeForm>
        </Dialog>

        <Dialog modal
                :visible="isSetMarketingCostModalOpen"
                v-model:visible="isSetMarketingCostModalOpen"
                :header="__('Set marketing costs')"
                :style="{ width: '500px' }">
            <p style="font-size:16px; font-weight:bold;color:steelblue">{{ __('Marketing cost will be set for selected products') }}</p>

            <SetMarketingCostForm :formProps="productpriceFormProps"
                                  v-model:isSetMarketingCostModalOpen="isSetMarketingCostModalOpen">
            </SetMarketingCostForm>
        </Dialog>

        <Dialog modal
                :visible="isSetCourierModalOpen"
                v-model:visible="isSetCourierModalOpen"
                :header="__('Update courier and nomenclature')"
                :style="{ width: '800px' }">
            <p style="font-size:16px; font-weight:bold;color:royalblue">{{ __('Selected courier and shipment nomenclature will be set for choosed products') }}</p>

            <SetCourierForm :formProps="productpriceFormProps"
                            v-model:isSetCourierModalOpen="isSetCourierModalOpen">
            </SetCourierForm>
        </Dialog>

    </span>
</template>

<script setup>
import ProductpriceForm from './ProductpriceForm.vue'
import ProductpriceSearch from './ProductpriceSearch.vue'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { ref, onMounted, nextTick } from "vue";
import { rowButtonAction } from '@pages/Helper/crud_utils.js'
import SetChargeForm from './SetChargeForm.vue'
import SetMarketingCostForm from './SetMarketingCostForm.vue'
import SetCourierForm from './SetCourierForm.vue'
import { __, screenMessage, getFromRoute } from '@pages/Helper/utils.js'

const selectedRows = ref([])

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    routes: Object,
    initialSort: Object,
    charge_sources: Object,
    forceNew: Boolean || false,
})

const isSetChargeModalOpen = ref(false)
const isSetMarketingCostModalOpen = ref(false)
const isSetCourierModalOpen = ref(false)
const productpriceFormProps = ref({})

//------------------------------------------------------------------------------

onMounted(async () => {
    if (props.forceNew) {
        const addButton = document.querySelector('#add-button')
        if (addButton) {
            addButton.click()
        }
    }
})

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    switch (field) {
        case "charge_source":
            return props.charge_sources.find(item => item.value === value)?.label;
        default:
            return value;
    }
};

//------------------------------------------------------------------------------

const productpriceModalControl = {
    open: (source) => {
        switch (source) {
            case 'set-charge':
                isSetChargeModalOpen.value = true
                break;
            case 'set-marketing-cost':
                isSetMarketingCostModalOpen.value = true
                break;
            case 'set-courier-parcel':
                isSetCourierModalOpen.value = true
                break;
        }
    },
    //close: () => isSetChargeModalOpen.value = false,
}

//------------------------------------------------------------------------------

const checkIfSelectedRecordsExists = (formProps, source, openModal = true) => {
    if (!Array.isArray(formProps.selectedRows) || formProps.selectedRows.length === 0) {
        const message = {
            message: "No product has been selected",
            title: "Product selection error",
            type: "error",
            position: "center",
            timeout: 5,
        };
        screenMessage(message);
        return false;
    }

    if (openModal) {
        productpriceFormProps.value = formProps
        productpriceModalControl.open(source)
    }
    return true
}

//------------------------------------------------------------------------------

const checkIfOnlyOnePricelist = (formProps, source) => {
    if (checkIfSelectedRecordsExists(formProps, source, false)) {
        let pricelistSet = new Set()
        formProps.selectedRows.forEach(function (row) {
            pricelistSet.add(row.pricelist_id)
        })

        if (pricelistSet.size > 1) {
            const message = {
                message: "You can global set shipment nomenclature only for products from the same pricelist",
                title: "Product selection error",
                type: "error",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
            return false;
        }

        productpriceFormProps.value = formProps
        productpriceModalControl.open(source)
    }
}

//------------------------------------------------------------------------------

</script>

<style lang="scss" scoped></style>
