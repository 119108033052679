<template>
    <v-form :name="props.searchProps.formName"
            @keyup.enter="autoSearch = true">
        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Search condition')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormInputText fieldName="last_name"
                                   label="Last name"
                                   size="4"
                                   :isFocus="true"
                                   v-model:field="search.last_name" />

                    <FormInputText fieldName="first_name"
                                   label="First name"
                                   size="4"
                                   v-model:field="search.first_name" />

                    <FormInputText fieldName="email"
                                   label="Email"
                                   size="4"
                                   v-model:field="search.email" />

                    <FormMultiselect fieldName="position_id"
                                     label="Position"
                                     size="6"
                                     showClear
                                     :options="select?.positions"
                                     v-model:field="search.position_id" />

                    <FormMultiselect fieldName="dept_id"
                                     label="Dział"
                                     size="6"
                                     showClear
                                     :options="select?.depts"
                                     :filter="(event) => onFilter(event, 'depts')"
                                     v-model:field="search.dept_id" />
                </div>
            </TabPanel>
        </TabView>


        <div class="grid">
            <SearchButtons :searchProps="searchProps"
                           :search="search"
                           v-model:autoSearch="autoSearch" />
        </div>
    </v-form>
</template>

<script setup>
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import FormInputText from "@crud/FormInputText.vue";
import FormMultiselect from "@crud/FormMultiselect.vue";

import SearchButtons from '@crud/SearchButtons.vue'
import { prepareSearch } from '@pages/Helper/crud_utils.js'
import { __ } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    searchProps: Object,
})
const autoSearch = ref(false)

const options = ["positions", "depts", "page_length"];
const select = ref(null);

let search = ref({})

prepareSearch(search, props, options, select)

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
