<template>
    <VLayout class="rounded rounded-md flex-column">
        <Menubar ref='menubar'
                 class="px-2 mb-2 menu-horizontal-outer"
                 :model="menuItems">
            <template #start>
                <v-col>
                    <Image :src="menuLogo" />
                </v-col>
            </template>

            <template #item="{ item, props, hasSubmenu, root }">
                <span v-if="Object.values(unprotectedMenuItems).includes(item?.fullPath?.toLowerCase().replace(/[_\s]/g, '-')) || hasPermission(item?.fullPath)">
                    <Link v-ripple
                          v-if="item.route"
                          :href="checkRoute(item.route, null, item.label)"
                          :id="item.id"
                          :style="item.itemstyle"
                          class="flex flex-row flex-nowrap menu-horizontal-inner"
                          v-bind="props.action">
                    <div>
                        <span :class="item.icon"
                              :style="item.iconstyle" />
                        <span class="ml-2"
                              :class="item.labelclass"
                              :style="item.labelstyle">{{ __(item.label) }}</span>
                        <div v-if="item.description"
                             class="menu-horizontal-description">
                            <span v-html="item.description"></span>
                        </div>
                    </div>
                    </Link>

                    <div v-ripple
                         v-else
                         class="flex flex-row flex-nowrap menu-horizontal-inner"
                         v-bind="props.action">
                        <div class="align-items-start cursor-pointer w-100">
                            <span :class="item.icon"
                                  :style="item.iconstyle" />
                            <span class="ml-2"
                                  :class="item.labelclass"
                                  :style="item.labelstyle">{{ __(item.label) }}</span>
                            <i v-if="hasSubmenu"
                               :class="['pi pi-angle-down text-primary', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-2': !root }]"
                               :style="item.submenustyle"
                               style="float:right"></i>
                            <div v-if="item.description"
                                 class="menu-horizontal-description"
                                 :id="item.id">
                                <span v-html="item.description"></span>
                            </div>
                        </div>
                    </div>
                </span>
            </template>

            <template #end>
                <div class="flex align-items-center">
                    <div class="flex menu-horizontal-left-part">
                        <div class="menu-horizontal-left-part-begin"></div>
                        <div class="menu-horizontal-left-part-middle"></div>
                        <div class="menu-horizontal-left-part-end">
                            <Button v-if="multiModuleApp"
                                    icon="pi pi-server"
                                    severity="info"
                                    text
                                    size="large"
                                    v-tooltip.top="__('Select module')"
                                    @click="selectModule" />
                        </div>
                    </div>
                    <div class="session-time-header"
                         style="margin-left:20px"></div>
                </div>
            </template>
        </Menubar>

        <VFooter class="bg-grey-lighten-2"
                 app>
            <div class="w-full flex justify-content-between align-items-center">
                <div class="flex flex-nowrap align-items-center">
                    <Image :src="developerLogo"
                           style="width:100px;" />
                    <div style="width:200px !important;">
                        <span class="text-sm text-grey-darkest">Powered by © 4Evolve wersja 1.0</span>
                    </div>
                </div>

                <div class="flex flex-row flex-nowrap align-items-center">

                    <div class="flex flex-nowrap justify-content-start align-items-center"
                         style="width:500px">
                        <Link v-ripple
                              class="href-text"
                              :href="checkRoute('dashboard-help', null, null)">
                        <span class="mx-2 footer-links">Pomoc</span>
                        </Link>

                        <Link v-ripple
                              class="href-text"
                              :href="checkRoute('dashboard-changelog', null, null)">
                        <span class="mx-2 footer-links">Changelog</span>
                        </Link>

                        <Link v-ripple
                              class="href-text"
                              :href="checkRoute('dashboard-privacy', null, null)">
                        <span class="mx-2 footer-links">Polityka prywatności</span>
                        </Link>
                    </div>

                    <div id='user-name-area'
                         style="text-wrap:nowrap">
                        {{ userName }}
                    </div>
                    <div>
                        <Button icon="pi pi-save"
                                severity="info"
                                text
                                size="large"
                                v-tooltip.top="__('Save session')"
                                @click="saveSession" />
                    </div>
                </div>
            </div>
        </VFooter>

    </VLayout>

    <div class="layout-box">
        <slot></slot>
    </div>
    <flash-message />
</template>

<script setup>
import Menubar from 'primevue/menubar';
import Button from 'primevue/button';
import { Link, router } from '@inertiajs/vue3'
import { ref, onMounted, onBeforeMount } from "vue";
import Image from '@helper/Image.vue'
import { initFunctions } from "../../init.js";
import { __, saveAppSession, hasPermission, checkRoute, getStoreValue } from '@pages/Helper/utils.js';
import { menuLogo, developerLogo } from '@helper/constants.js'
import axios from 'axios'

const menuItems = ref(null);
const unprotectedMenuItems = ref({});
const userName = ref(null);
const multiModuleApp = ref(false);

//------------------------------------------------------------------------------

onBeforeMount(async () => {
    const response = await axios.post(route("menu-content"));
    const backendData = await response.data;
    menuItems.value = backendData.menu;
    if (menuItems.value.length == 0) {
        console.error('Wybrany moduł (' + backendData.moduleSymbol + ') nie posiada pliku json z zawartością menu. Nastąpi przekierowanie do strony wyboru modułu')
        router.get(route('select-module'))
    }

    unprotectedMenuItems.value = backendData.unprotected;
    userName.value = backendData.userName;
    multiModuleApp.value = backendData.multiModuleApp;

    clearInterval(window.displaySessionInterval);
})

//------------------------------------------------------------------------------

onMounted(async () => {
    window.endOfSession = false
    window.showDisplaySessionTime = false;
    window.lastActivityTime = null;
    await initFunctions();
})

//------------------------------------------------------------------------------

const saveSession = () => {
    saveAppSession();
}

//------------------------------------------------------------------------------

const selectModule = () => {
    router.get(route('select-module', { module: getStoreValue('currentModule') }))
}

</script>

<style scoped>
.v-layout {
    position: sticky;
    position: -webkit-sticky;
    z-index: 2;
    top: 0;
}

:deep(.p-menubar-start) {
    padding: 0 40px 0 20px;
    display: flex;
}

:deep(.p-menubar-end) {
    position: absolute;
    padding: 0 40px 0 20px;
    right: 0;
}

:deep(.p-menuitem-link) {
    align-items: flex-start;
}

:deep(.p-submenu-list) {
    padding: 0px;
    box-shadow: 0px 9px 15px -1px rgba(66, 68, 90, 1);
    -webkit-box-shadow: 0px 9px 15px -1px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 9px 15px -1px rgba(66, 68, 90, 1);
}
</style>
