<template>
    <v-view :tableData="tableData"
            :tableProps="tableProps"
            :modalProps="modalProps"
            :columns="columns"
            :tooltipColumns="tooltipColumns"
            :routes="routes"
            :initialSort="initialSort"
            :renderColumnValue="renderColumnValue"
            :rowButtonAction="rowButtonAction"
            v-model:selectedRows="selectedRows">
        <template #crud="formProps">
            <UserForm :formProps="formProps"> </UserForm>
        </template>
        <template #search="searchProps">
            <UserSearch :searchProps="searchProps"> </UserSearch>
        </template>
    </v-view>
</template>

<script setup>
import UserForm from "./UserForm.vue";
import UserSearch from "./UserSearch.vue";
import { ref } from "vue";
import { rowButtonAction } from "@pages/Helper/crud_utils.js";

const selectedRows = ref([]);

const props = defineProps({
    tableData: Object,
    tableProps: Object,
    modalProps: Object,
    columns: Object,
    tooltipColumns: Object,
    routes: Object,
    initialSort: Object,
});

//------------------------------------------------------------------------------

const renderColumnValue = (field, value) => {
    switch (field) {
        case "is_locked":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-lock" style="font-size:20px;"></i></span>' : "";
        case "is_active":
            return value == 1 ? '<span class="flex justify-content-center"><i class="fa-solid fa-person-running" style="font-size:20px;"></i></span>' : "";
        default:
            return value;
    }
};

//------------------------------------------------------------------------------

</script>

<style lang="scss"
       scoped></style>
