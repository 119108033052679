<template>
    <div class="flex justify-content-center align-items-center outer-box">
        <div class="flex justify-content-center align-items-center main-page-box">
            Strona główna systemu&nbsp;<span class="system-name">{{ systemName }}</span>
        </div>
    </div>
</template>

<script setup>
import { setStoreValue } from "@pages/Helper/utils.js";

const props = defineProps({
    systemName: String,
    currentModule: String,
})

setStoreValue('currentModule', props.currentModule)
</script>

<style scoped>
.outer-box {
    width: 100%;
    height: calc(100vh - 127px);
}

.main-page-box {
    width: 850px;
    height: 350px;
    font-size: 36px;
    background-color: rgba(255, 255, 255, 1);
    margin: 15px;
    padding: 10px 15px;
    color: #000;
    border-radius: 10px;
    box-shadow: 0px 29px 52px 0px rgba(0, 0, 0, 0.4),
        0px 25px 16px 0px rgba(0, 0, 0, 0.2);
}

.system-name {
    font-size: 36px;
    color: #154A9A
}
</style>
