<template>
    <v-form>
        <div class="grid">
            <FormDropdown fieldName="pricelist_id"
                          label="Sell channel (pricelist)"
                          showClear
                          validation="required"
                          :options="select?.pricelists"
                          :filter="(event) => onFilter(event, 'pricelists')"
                          v-model:field="pricelist_id" />
        </div>
        <div class="grid">
            <FormRadioButton fieldName="charge_source"
                             label="Charge source"
                             :data="select?.charge_sources"
                             v-model:field="charge_source" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="addToPricelist">
                <i class="fa-solid fa-plus"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Add to pricelist') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import FormRadioButton from '@crud/FormRadioButton.vue'
import { validation, onFilterService, prepareSelect } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isPricelistModalOpen = defineModel('isPricelistModalOpen')

// -----------------------------------------------------

const isLoading = ref(true);
const pricelist_id = ref(null)
const charge_source = ref('P')
let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['pricelists', 'charge_sources']);
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, params)
}

// -----------------------------------------------------

const cancel = () => {
    isPricelistModalOpen.value = false
}
// -----------------------------------------------------

const addToPricelist = () => {
    const formData = {
        pricelist_id: pricelist_id.value, 'charge_source': charge_source.value, selectedRows: props.formProps.selectedRows.map(row => {
            return { 'id': row.id }
        })
    }
    if (!validation(formData)) {
        return false
    }
    useForm(formData).post(route('pricelist-addfromproducts'), {
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onError: (errors) => {
            console.error(errors)
        },
        onSuccess: () => {
            const message = {
                message: "Selected products was added to pricelist successfully",
                title: "Information",
                type: "info",
                position: "center",
                timeout: 5,
            };
            screenMessage(message);
        },
        onFinish: () => {
            props.formProps.clearSelection()
            props.formProps.getLazyData()
        }
    })

    isPricelistModalOpen.value = false
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
