<template>
    <v-form>
        <div class="grid">
            <FormDropdown fieldName="courier_id"
                          label="Courier"
                          showClear
                          validation="required"
                          size="5"
                          :options="select?.couriers"
                          :filter="(event) => onFilter(event, 'couriers')"
                          :change="() => getParcels()"
                          v-model:field="courier_id" />

            <FormDropdown fieldName="parcel_id"
                          label="Courier nomenclature"
                          showClear
                          validation="required"
                          size="7"
                          :filtering="false"
                          :options="select?.parcels"
                          v-model:field="parcel_id" />
        </div>

        <div class="grid"
             style="margin-top:20px">
            <Button severity="success"
                    style="margin:0 8px"
                    @click="setCourierAndParcel">
                <i class="fa-solid fa-hand-holding-dollar"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Update courier and nomenclature') }}
            </Button>
            <Button severity="secondary"
                    style="margin:0 8px"
                    @click="cancel">
                <i class="fa-solid fa-ban"
                   style="margin-right:5px;font-size:18px"></i>
                {{ __('Cancel') }}
            </Button>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useForm } from '@inertiajs/vue3'
import Button from 'primevue/button';
import FormDropdown from '@crud/FormDropdown.vue'
import { prepareSelect, validation, onChangeService } from '@pages/Helper/crud_utils.js'
import { __, screenMessage, getField } from '@pages/Helper/utils.js'

// -----------------------------------------------------
const props = defineProps({
    formProps: Object,
})

const isSetCourierModalOpen = defineModel('isSetCourierModalOpen')

// -----------------------------------------------------

const courier_id = ref(0)
const parcel_id = ref(0)
const pricelist_country_id = ref(props.formProps.selectedRows[0].country_id)
const isLoading = ref(true);

let select = ref(null)

// -----------------------------------------------------

onMounted(async () => {
    select.value = await prepareSelect(['couriers', 'parcels', 'pricelists']);
})

// -----------------------------------------------------

const cancel = () => {
    isSetCourierModalOpen.value = false
}

// -----------------------------------------------------

const getParcels = async () => {
    let dependences = [
        {
            select: { 'parcels': 'parcel_id' },
            conditions: 'parcels.courier_id=#courier_id# and parcels.country_id=#pricelist_country_id#',
            variables: { courier_id: courier_id.value, pricelist_country_id: pricelist_country_id.value }
        },
    ]

    await onChangeService(dependences, select, null, isLoading, null, true)
}

// -----------------------------------------------------

const setCourierAndParcel = () => {
    const formData = {
        'courier_id': courier_id.value, 'parcel_id': parcel_id.value, selectedRows: props.formProps.selectedRows.map(row => {
            return { 'id': row.id }
        })
    }

    if (validation(formData)) {
        useForm(formData).post(route('set-courier-parcel'), {
            preserveState: true,
            preserveScroll: true,
            forceFormData: true,
            onError: (errors) => {
                console.error(errors)
            },
            onSuccess: () => {
                const message = {
                    message: "Courier and nomenclature for selected products was set",
                    title: "Information",
                    type: "info",
                    position: "center",
                    timeout: 5,
                };
                screenMessage(message);
            },
            onFinish: () => {
                props.formProps.clearSelection()
                props.formProps.getLazyData()
            }
        })

        isSetCourierModalOpen.value = false
    }
}
// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
