<template>
    <div style="display:flex;justify-content: center; align-items: center; height: 91vh;">
        <v-form name="formConfig"
                class="shadow-box"
                style="border:1px solid #e0e0e0; padding:10px; width:55%">
            <TabView scrollable
                     style="overflow: visible;"
                     :pt="{ panelContainer: { class: 'form-tab-panel' } }">
                <TabPanel :header="__('Basic data')"
                          headerClass="form-tab-header">
                    <div class="grid">
                        <FormInputNumber fieldName="session_time"
                                         label="Session time"
                                         size="4"
                                         :min="0"
                                         :isFocus="true"
                                         v-model:field="config.session_time" />

                        <FormInputNumber fieldName="lock_time"
                                         label="Lock time"
                                         size="4"
                                         :min="0"
                                         :max="180"
                                         v-model:field="config.lock_time" />

                        <FormInputNumber fieldName="lock_try"
                                         label="Lock try"
                                         size="4"
                                         :min="0"
                                         :max="10"
                                         v-model:field="config.lock_try" />
                    </div>
                    <div class="grid">
                        <FormInputNumber fieldName="password_validity_time"
                                         label="Password validity time"
                                         size="4"
                                         :min="0"
                                         v-model:field="config.password_validity_time" />

                        <FormInputNumber fieldName="password_length"
                                         label="Minimal password length"
                                         size="4"
                                         :min="5"
                                         :max="20"
                                         v-model:field="config.password_length" />

                        <FormInputNumber fieldName="count_used_passwords"
                                         label="Exclude count of used passwords"
                                         size="4"
                                         :min="0"
                                         :max="10"
                                         v-model:field="config.count_used_passwords" />
                    </div>
                    <div class="grid">
                        <FormCheckbox fieldName="is_letter"
                                      label="Is password must contain letter?"
                                      size="4"
                                      v-model:field="config.is_letter" />

                        <FormCheckbox fieldName="is_number"
                                      label="Is password must contain number?"
                                      size="4"
                                      v-model:field="config.is_number" />

                        <FormCheckbox fieldName="is_symbol"
                                      label="Is password must contain symbol?"
                                      size="4"
                                      v-model:field="config.is_symbol" />
                    </div>
                    <div class="grid">
                        <FormCheckbox fieldName="is_mixed"
                                      label="Is password must contain mixed letters?"
                                      size="4"
                                      v-model:field="config.is_mixed" />

                        <FormCheckbox fieldName="is_sequence"
                                      label="Can password contain sequences of chars?"
                                      size="4"
                                      v-model:field="config.is_sequence" />

                        <FormCheckbox fieldName="is_user_data"
                                      label="Can password contain user data?"
                                      size="4"
                                      v-model:field="config.is_user_data" />
                    </div>
                    <div class="grid">
                        <FormCheckbox fieldName="is_bad_passwords"
                                      label="Can password be a popular phrase?"
                                      size="4"
                                      v-model:field="config.is_bad_passwords" />

                        <FormCheckbox fieldName="is_password_policy"
                                      label="Is password policy?"
                                      size="4"
                                      v-model:field="config.is_password_policy" />
                    </div>

                </TabPanel>
            </TabView>
            <div class="grid">
                <Button @click="saveData"
                        :disabled="config.processing"
                        style="margin:0 5px"
                        severity="success">
                    {{ __('Save') }}
                </Button>
                <Button @click="cancelData"
                        :disabled="config.processing"
                        style="margin:0 5px"
                        severity="secondary">
                    {{ __('Cancel') }}
                </Button>
            </div>
        </v-form>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormCheckbox from '@crud/FormCheckbox.vue'
import Button from 'primevue/button';
import { useForm, usePage, router } from '@inertiajs/vue3';
import { onErrorService } from '@pages/Helper/crud_utils.js'
import { __, screenMessage } from '@pages/Helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    data: Object,
})

const config = ref(props.data)
screenMessage();

// -----------------------------------------------------

const saveData = () => {
    useForm({ ...config.value }).post(route('set-config'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => {
            onErrorService(errors)
        },
        onSuccess: () => {
        },
        onFinish: () => {

        }
    })
}

// -----------------------------------------------------

const cancelData = () => {
    router.get(route('system-start'));
}

// -----------------------------------------------------

</script>

<style lang="scss"
       scoped>
    .shadow-box {
        -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
        box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    }
</style>
