<template>
    <Transition v-if="isLoading"
                name="spinner">
        <div id="cover-spin"></div>
    </Transition>

    <v-form :name="props.formProps.formName">
        <ConfirmDialog header="Confirm operation"
                       question="Do you want to change price in Baselinker?"
                       v-model="isConfirmationOpen"
                       :operation="updatePrice" />

        <TabView scrollable
                 style="overflow: visible"
                 :pt="{ panelContainer: { class: 'form-tab-panel' } }">
            <TabPanel :header="__('Basic data')"
                      headerClass="form-tab-header">
                <div class="grid">
                    <FormDropdown fieldName="product_id"
                                  label="Product"
                                  showClear
                                  :isFormDisabled="true"
                                  :options="select?.products"
                                  :filter="(event) => onFilter(event, 'products')"
                                  v-model:field="crud.product_id" />
                </div>
                <div class="grid">
                    <FormDropdown fieldName="vendor_id"
                                  label="Vendor"
                                  size="6"
                                  showClear
                                  :isFormDisabled="true"
                                  :options="select?.vendors"
                                  :filter="(event) => onFilter(event, 'vendors')"
                                  v-model:field="crud.vendor_id" />

                    <FormDropdown fieldName="pricelist_id"
                                  label="Sell channel (pricelist)"
                                  size="6"
                                  showClear
                                  :isFormDisabled="true"
                                  :options="select?.pricelists"
                                  :filter="(event) => onFilter(event, 'pricelists')"
                                  v-model:field="crud.pricelist_id" />
                </div>
                <div class="grid">
                    <FormInputText fieldName="product_identity"
                                   label="Product ID"
                                   size="6"
                                   :isFormDisabled="true"
                                   v-model:field="crud.product_identity" />

                    <FormInputText fieldName="variant_identity"
                                   label="Variant ID"
                                   size="6"
                                   :isFormDisabled="true"
                                   v-model:field="crud.variant_identity" />
                </div>
                <div class="grid">
                    <FormInputNumber fieldName="margin_profit_percent"
                                     label="Net margin profit percent"
                                     size="3"
                                     labelClass="productprice-charges-label"
                                     inputClass="productprice-charges-input"
                                     :suffix="crud.margin_profit_percent_unit"
                                     :isFormDisabled="true"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.margin_profit_percent" />

                    <FormInputNumber fieldName="margin_profit_percent_baselinker"
                                     label="Net margin profit percent in Baselinker"
                                     size="3"
                                     labelClass="productprice-charges-label"
                                     inputClass="productprice-charges-input"
                                     :suffix="crud.margin_profit_percent_baselinker_unit"
                                     :isFormDisabled="true"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.margin_profit_percent_baselinker" />

                    <FormInputNumber fieldName="gross_sell_price_curr"
                                     label="Gross sell in currency"
                                     size="3"
                                     labelClass="productprice-charges-label"
                                     inputClass="productprice-charges-input"
                                     :suffix="crud.gross_sell_price_unit"
                                     :isFormDisabled="true"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.gross_sell_price_curr" />

                    <FormInputNumber fieldName="gross_sell_price_baselinker_curr"
                                     label="Gross sell in currency in Baselinker"
                                     size="3"
                                     labelClass="productprice-prices-label"
                                     inputClass="productprice-charges-input"
                                     :inputStyle="{ color: 'yellow', backgroundColor: 'cornflowerblue' }"
                                     :suffix="crud.gross_sell_price_baselinker_unit"
                                     :isFormDisabled="true"
                                     :minFractionDigits="2"
                                     :maxFractionDigits="2"
                                     v-model:field="crud.gross_sell_price_baselinker_curr" />
                </div>

            </TabPanel>
        </TabView>

        <div class="grid">
            <div class="col-12"
                 style="margin-bottom:20px;margin:10px 0 20px">
                <hr>
            </div>
            <div>
                <Button @click.stop="openConfirmationWindow"
                        style="background-color:brown;margin:0 7px 0 25px"
                        severity="info">
                    <i class="fa-solid fa-coins"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Update price') }}
                </Button>
                <Button @click="cancel()"
                        style="margin:0 7px 0 25px"
                        severity="secondary">
                    <i class="fa-solid fa-right-from-bracket"
                       style="margin-right:5px;font-size:18px"></i>
                    {{ __('Cancel') }}
                </Button>
            </div>
            <div class="col-12"
                 style="padding-left:25px;font-size:13px;color:burlywood">
                <span v-html="__('After clicking the :button button, the gross sales price in Baselinker will be replaced with the gross sales price in the sales currency, coming from the local system',
                    { 'button': updatePriceButton })
                    "></span>
            </div>
        </div>
    </v-form>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { onFilterService, initForm } from '@pages/Helper/crud_utils.js'
import FormInputNumber from '@crud/FormInputNumber.vue'
import FormInputText from '@crud/FormInputText.vue'
import FormDropdown from '@crud/FormDropdown.vue'
import Button from 'primevue/button';
import ConfirmDialog from '@crud/ConfirmDialog.vue'
import { __, getFromRoute } from '@helper/utils.js'

// -----------------------------------------------------

const props = defineProps({
    formProps: Object,
})

const isLoading = ref(true);
const isConfirmationOpen = ref(false)
const updatePriceButton = `<span style='font-weight:bold;'>"${__('Update price')}"</span>`

const options = ['pricelists', 'products', 'vendors']
const select = ref(null)
const crud = ref({})

// -----------------------------------------------------

onMounted(async () => {
    await initForm(props, crud, isLoading, options, select)
})

// -----------------------------------------------------

const onFilter = async (event, option) => {
    await onFilterService(event, option, select, isLoading, null, params)
}

// -----------------------------------------------------

const openConfirmationWindow = () => {
    isConfirmationOpen.value = true
}

// -----------------------------------------------------

const updatePrice = async () => {
    await getFromRoute('priceupdate-update',
        {
            'productprice_id': crud.value.productprice_id,
            'pricelist_id': crud.value.pricelist_id,
            'product_id': crud.value.product_id,
            'gross_sell_price_curr_decimal': crud.value.gross_sell_price_curr_decimal
        }, 'post');
    props.formProps.closeModal()
    props.formProps.getLazyData()
}

// -----------------------------------------------------

const cancel = async () => {
    props.formProps.closeModal()
}

// -----------------------------------------------------

</script>

<style lang="scss" scoped></style>
